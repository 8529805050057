import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArchivedClass from "components/ArchivedClass";
import FormattedMessage from "components/FormattedMessage";
import UpcomingClass from "components/UpcomingClass";
import { useCurrentLanguage } from "containers/LanguageProvider";
import moment from "moment";
import {
  useAppointments,
  usePlans,
  useSubjects,
  useTeachers,
} from "services/mms";

import settings from "../../settings";

import ArchiveHeader from "./ArchiveHeader";
import messages from "./messages";
import UpcomingClassesHeader from "./UpcomingClassesHeader";

const styles = (theme) => ({
  bookingButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bookingLink: {
    marginTop: "20px",
    textDecoration: "none",

    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
      width: "100%",
    },
  },
  emptyPageContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "50px",
    //height: 'calc(100vh - 65px)',

    [theme.breakpoints.down("sm")]: {
      // height: 'calc(100vh - 136px)',
    },
  },
  headline: {
    color: "#303E4E",
    fontSize: "26px",
    marginBottom: "5px",
    textAlign: "center",
  },
  icon: {
    margin: "5px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "5px",
    },
  },
  noAppointmentsIcon: {
    height: "81px",
    width: "81px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
    },
  },
  showMoreButton: {
    alignSelf: "flex-end",
    marginTop: "20px",
    minWidth: "150px",

    [theme.breakpoints.down("xs")]: {
      alignSelf: "normal",
    },
  },
  spinner: {
    alignSelf: "center",
    color: theme.palette.primary.main,
    marginTop: "20px",
  },
  text: {
    color: "#6F7B8A",
    fontSize: "16px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  upcomingClasses: {
    background: "#fafafa",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    width: "1100px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
      padding: "0px",
    },
  },
  upcomingClassesWrapper: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
  },
});

const ClassesPage = ({ classes }) => {
  // preload user specific data
  void usePlans();
  void useSubjects();
  void useTeachers();

  const { data: appointments, isLoading } = useAppointments();
  const upcomingAppointments = appointments?.upcoming;
  const pastAppointments = appointments?.past;

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTimeFilter, setSelectedTimerFilter] = useState("30");
  const [upcomingClassesOffset, setUpcomingClassesOffset] = useState(3);

  useEffect(() => {
    document.title = "Appointments";
  }, []);

  const filteredUpcomingAppointments = useMemo(
    () =>
      upcomingAppointments?.filter(
        (_, idx) => idx <= upcomingClassesOffset - 1
      ),
    [upcomingAppointments, upcomingClassesOffset]
  );

  const filteredPastAppointments = useMemo(() => {
    if (!pastAppointments) return [];

    let filteredAppointments = pastAppointments.filter((appointment) =>
      selectedCategory
        ? appointment.subject.toLowerCase() === selectedCategory.toLowerCase()
        : true
    );

    const substractDate = (time) => moment().subtract(time, "days");
    const pastDate = substractDate(parseInt(selectedTimeFilter, 10));

    filteredAppointments = filteredAppointments.filter((appointment) =>
      selectedTimeFilter !== "all"
        ? moment(appointment.datetime).isSameOrAfter(pastDate)
        : true
    );

    return filteredAppointments;
  }, [pastAppointments, selectedCategory, selectedTimeFilter]);

  const { language } = useCurrentLanguage();
  const dateFormat = `${settings.DATE_FORMAT[language]}`;
  const timeFormat = `${settings.TIME_FORMAT[language]}`;
  const shortDateFormat = `${settings.SHORT_DATE_FORMAT[language]}`;
  const dayFormat = `${settings.DAY_FORMAT[language]}`;

  const showEmptyPage =
    !isLoading &&
    appointments?.all.length === 0 &&
    upcomingAppointments?.length === 0;

  return (
    <div className={classes.root}>
      {showEmptyPage ? (
        <div className={classes.emptyPageContainer}>
          <img
            className={classes.noAppointmentsIcon}
            src={`${process.env.PUBLIC_URL}/images/no-appointment.svg`}
            alt="My Music School"
          />
          <Typography className={classes.headline}>
            <FormattedMessage {...messages.noAppointmentsScheduled} />
          </Typography>
          <Typography className={classes.text}>
            <FormattedMessage {...messages.noAppointmentsAvailable} />
          </Typography>
          <Typography className={classes.text}>
            <FormattedMessage {...messages.bookAppointment} />
          </Typography>
          <Link
            className={classes.bookingLink}
            to="/appointments/book"
            href="/appointments/book"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.bookingButton}
            >
              <FontAwesomeIcon icon={faPlus} className={classes.icon} />
              <FormattedMessage {...messages.newClassBtn} />
            </Button>
          </Link>
        </div>
      ) : (
        <Paper className={classes.upcomingClasses} elevation={0} square>
          {!!filteredUpcomingAppointments ? (
            <React.Fragment>
              <UpcomingClassesHeader />
              <div className={classes.upcomingClassesWrapper}>
                {filteredUpcomingAppointments.map((appointment) => (
                  <UpcomingClass
                    data={appointment}
                    key={appointment.id}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    shortDateFormat={shortDateFormat}
                    dayFormat={dayFormat}
                  />
                ))}
              </div>
              {upcomingAppointments?.length === 0 && (
                <FormattedMessage {...messages.noAppointmentsScheduled} />
              )}
              {upcomingAppointments?.length > 0 &&
                upcomingClassesOffset < upcomingAppointments.length && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.showMoreButton}
                    onClick={() => {
                      setUpcomingClassesOffset(upcomingClassesOffset + 3);
                    }}
                  >
                    <FormattedMessage {...messages.showMore} />
                  </Button>
                )}
              {pastAppointments?.length > 0 && (
                <ArchiveHeader
                  selectedCategory={selectedCategory}
                  selectedTimeFilter={selectedTimeFilter}
                  onChangeCategory={(event) =>
                    setSelectedCategory(event.target.value)
                  }
                  onChangeTimeFilter={(event) =>
                    setSelectedTimerFilter(event.target.value)
                  }
                />
              )}
              {filteredPastAppointments.map((appointment) => (
                <ArchivedClass
                  data={appointment}
                  key={appointment.id}
                  dateFormat={dateFormat}
                  timeFormat={timeFormat}
                />
              ))}
            </React.Fragment>
          ) : (
            <CircularProgress className={classes.spinner} color="primary" />
          )}
        </Paper>
      )}
    </div>
  );
};

export default withStyles(styles)(ClassesPage);
