import { defineMessages } from "react-intl";

export default defineMessages({
  bookCallToAction: {
    defaultMessage:
      "Want to learn more? Click here, to book new <b>subscriptions</b> or <b>packages</b>.",
    id: "app.components.PlanOrderCard.bookCallToAction",
  },
  bookNewTitle: {
    defaultMessage: "New Booking",
    id: "app.components.PlanOrderCard.bookNewTitle",
  },
});
