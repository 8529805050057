import { defineMessages } from "react-intl";

export default defineMessages({
  appointments: {
    defaultMessage: "Appointments",
    id: "app.components.PrivateLayout.appointments",
  },
  courses: {
    defaultMessage: "Courses",
    id: "app.components.PrivateLayout.courses",
  },
  dropDownLogout: {
    defaultMessage: "Logout",
    id: "app.components.PrivateLayout.dropDownLogout",
  },
  dropDownProfile: {
    defaultMessage: "Profile",
    id: "app.components.PrivateLayout.dropDownProfile",
  },
  header: {
    defaultMessage: "This is the PrivateLayout component !",
    id: "app.components.PrivateLayout.header",
  },
  home: {
    defaultMessage: "Home",
    id: "app.components.PrivateLayout.home",
  },
  impersonateEmail: {
    defaultMessage: "Impersonating {email}",
    id: "app.components.DefaultLayout.impersonateEmail",
  },
  imprintLink: {
    defaultMessage: "Imprint",
    id: "app.components.PrivateLayout.imprintLink",
  },
  liveLesson: {
    defaultMessage: "Live Lesson",
    id: "app.components.PrivateLayout.liveLesson",
  },
  menuClasses: {
    defaultMessage: "Appointments",
    id: "app.components.PrivateLayout.menuClasses",
  },
  menuCourses: {
    defaultMessage: "COURSES",
    id: "app.components.PrivateLayout.menuCourses",
  },
  menuHome: {
    defaultMessage: "HOME",
    id: "app.components.PrivateLayout.menuHome",
  },
  menuLiveLesson: {
    defaultMessage: "Live Lesson",
    id: "app.components.PrivateLayout.menuLiveLesson",
  },
  menuLogout: {
    defaultMessage: "Logout",
    id: "app.components.PrivateLayout.menuLogout",
  },
  menuProfile: {
    defaultMessage: "Profile",
    id: "app.components.PrivateLayout.menuProfile",
  },
  menuToMms: {
    defaultMessage: "mymusicschool.com",
    id: "app.components.PrivateLayout.menuToMms",
  },
  privacyPolicyLink: {
    defaultMessage: "Privacy Policy",
    id: "app.components.PrivateLayout.menuPrivacy",
  },
  profile: {
    defaultMessage: "Profile",
    id: "app.components.PrivateLayout.profile",
  },
  withdrawFromContractsLink: {
    defaultMessage: "Withdraw from Contracts",
    id: "app.components.DefaultLayout.withdrawFromContracts",
  },
});
