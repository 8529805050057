// const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line

const enTranslationMessages = require("./translations/en.json");
const deTranslationMessages = require("./translations/de.json");

// const user = JSON.parse(sessionStorage.getItem('user'));
export const DEFAULT_LOCALE = "en";

// prettier-ignore
export const appLocales = [
  'en',
  'de',
];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  de: formatTranslationMessages("de", deTranslationMessages),
};
