import { defineMessages } from "react-intl";

export default defineMessages({
  calendarId: {
    defaultMessage: "Calendar ID",
    id: "app.components.ProfileForm.calendarId",
  },
  // TODO: translate this text
  deleteAccount: {
    defaultMessage: "Delete account",
    id: "app.components.ProfileForm.deleteAccount",
  },

  deleteBtn: {
    defaultMessage: "Delete your account",
    id: "app.components.ProfileForm.deleteBtn",
  },

  email: {
    defaultMessage: "Email",
    id: "app.components.ProfileForm.email",
  },

  emailFormat: {
    defaultMessage: "Please enter a valid email address (e.g. me@gmail.com)",
    id: "app.components.ProfileForm.emailFormat",
  },

  emailImmutable: {
    defaultMessage:
      "Please contact an administrator, if you want to change your email address",
    id: "app.components.ProfileForm.emailImmutable",
  },

  emailRequired: {
    defaultMessage: "This field is required.",
    id: "app.components.ProfileForm.emailRequired",
  },

  firstName: {
    defaultMessage: "First name",
    id: "app.components.ProfileForm.firstName",
  },

  header: {
    defaultMessage: "This is the ProfileForm component !",
    id: "app.components.ProfileForm.header",
  },

  invalidEmail: {
    defaultMessage: "Invalid email",
    id: "app.components.ForgotPasswordForm.invalidEmail",
  },

  language: {
    defaultMessage: "Language",
    id: "app.components.ProfileForm.language",
  },

  lastName: {
    defaultMessage: "Last name",
    id: "app.components.ProfileForm.lastName",
  },

  location: {
    defaultMessage: "Place",
    id: "app.components.ProfileForm.location",
  },

  phone: {
    defaultMessage: "Phone number",
    id: "app.components.ProfileForm.phone",
  },

  phoneNumberFormat: {
    defaultMessage:
      "Please enter a valid phone number with country code (i.e. +49123123456789)",
    id: "app.components.ProfileForm.phoneNumberFormat",
  },

  phoneNumberFormatExample: {
    defaultMessage: "e.g. +49151111222333",
    id: "app.components.ProfileForm.phoneNumberFormatExample",
  },

  saveBtn: {
    defaultMessage: "Save",
    id: "app.components.ProfileForm.saveBtn",
  },

  sendEmail: {
    defaultMessage: "Email notifications",
    id: "app.components.ProfileForm.sendEmail",
  },

  sendPushMsgs: {
    defaultMessage: "Push messages",
    id: "app.components.ProfileForm.sendPushMsgs",
  },
});
