import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    defaultMessage: "Courses",
    id: "app.components.CoursesOverview.header",
  },
  subscriptionAndPackages: {
    defaultMessage: "My Subscriptions and Packages",
    id: "app.components.CoursesOverview.subscriptionAndPackages",
  },
});
