import React, { useEffect, useRef } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import clamp from "clamp-js";
import { ReactComponent as FallbackImage } from "components/svg/mymusicschool-logo-no-shape.svg";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
  },
  card: {
    outline: "1px solid #eee",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(32),
    },
    width: "100%",
  },
  cardContent: {
    height: 140,
    padding: theme.spacing(2),
  },
  fallbackImageWrapper: {
    alignItems: "center",
    background: "lightGray",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  heading: { marginBottom: theme.spacing(1) },
  media: {
    color: "gray",
    height: 0,
    paddingTop: "66%",
  },
}));

function CoursePreview({ course }) {
  const descriptionRef = useRef();
  const classes = useStyles();
  const img = course.image.url;
  const name = course.name;
  const subject = course.subject.data != null ? course.subject.data.name : "";
  const description = course.description[0]?.text || "";

  useEffect(() => {
    if (descriptionRef.current) {
      clamp(descriptionRef.current, { clamp: 2 });
    }
  }, []);

  return (
    <Card className={classes.card} elevation={0}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia className={classes.media} image={img}>
          {!img && (
            <Box className={classes.fallbackImageWrapper}>
              <FallbackImage />
            </Box>
          )}
        </CardMedia>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.subject}
            color="textSecondary"
            gutterBottom
          >
            {subject}
          </Typography>
          <Typography className={classes.heading} variant="h6" component="h2">
            {name}
          </Typography>
          <Typography
            ref={descriptionRef}
            className={classes.description}
            color="textSecondary"
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default CoursePreview;
