import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";
import SignupForm from "components/SignupForm";
import PropTypes from "prop-types";

import messages from "./messages";

const styles = (theme) => ({
  actionsContainer: {
    paddingTop: "25px",
    textAlign: "center",
    width: "100%",
  },
  emailSentImage: {
    display: "block",
    marginBottom: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    width: 100,
  },
  header: {
    marginBottom: "30px",
  },
  signupForm: {
    margin: "120px auto",
    maxWidth: "500px",
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  signUpLink: {
    color: theme.palette.primary.main,
    marginLeft: "5px",
    textDecoration: "none",
  },
  signupText: {
    marginTop: "25px",
    opacity: "0.75",
    textAlign: "center",
  },
});

/* eslint-disable react/prefer-stateless-function */
const SignupPage = ({ classes }) => (
  <Paper elevation={0} className={classes.signupForm}>
    <Typography
      variant="h6"
      component="h3"
      align="center"
      className={classes.header}
    >
      <FormattedMessage {...messages.header} />
    </Typography>
    <SignupForm />
    <Typography className={classes.signupText}>
      <FormattedMessage {...messages.login} />
      <Link to="/login" href="/login" className={classes.signUpLink}>
        <span>
          <FormattedMessage {...messages.loginHere} />
        </span>
      </Link>
    </Typography>
    <Typography className={classes.signupText}>
      <FormattedMessage {...messages.forgotPassword} />
      <Link
        to="/forgotPassword"
        href="/forgotPassword"
        className={classes.signUpLink}
      >
        <span>
          <FormattedMessage {...messages.resetPassword} />
        </span>
      </Link>
    </Typography>
  </Paper>
);

SignupPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignupPage);
