import { Link, Redirect, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FormattedMessage from "components/FormattedMessage";
import { BookedAppointment } from "containers/BookingConfirmationPage/BookedAppointment";
import { useStyles } from "containers/BookingConfirmationPage/styles";
import BookingHeader from "containers/BookingPage/Header";

import messages from "./messages";

const BookingConfirmationPage = () => {
  const location = useLocation();
  const classes = useStyles();

  if (!location.state.appointments) {
    return <Redirect to="/home" />;
  }

  const bookedAppointments = location.state.appointments;

  return (
    <div className={classes.root}>
      <Paper className={classes.rootPaper} elevation={0} square>
        <div className={classes.rootWrapper}>
          <BookingHeader />
        </div>
      </Paper>
      <div className={classes.confirmationMessage}>
        <span>
          <FormattedMessage {...messages.thanks} />
        </span>
      </div>
      <Paper className={classes.bookingWrapper} elevation={0} square>
        <div className={classes.rootWrapper}>
          {bookedAppointments.map((appointment) => (
            <BookedAppointment key={appointment.id} appointment={appointment} />
          ))}
          <Link
            to="/appointments/book"
            href="/appointments/book"
            className={classes.newClassLink}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <FormattedMessage {...messages.bookFurtherAppointment} />
            </Button>
          </Link>
        </div>
      </Paper>
    </div>
  );
};

export default BookingConfirmationPage;
