import { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Counter from "components/Counter";
import DatePicker from "components/DatePicker";
import Drawer from "components/Drawer";
import FormattedMessage from "components/FormattedMessage";
import PersonDisplay from "components/PersonDisplay";
import { ReactComponent as CalendarAddIcon } from "components/svg/calendar_plus.svg";
import { ReactComponent as SpeechBubbleIcon } from "components/svg/speech-bubble.svg";
import messages from "components/UpcomingClass/messages";
import { useStyles } from "components/UpcomingClass/styles";
import SelectItem from "containers/BookingPage/SelectItem";
import { useCurrentLanguage } from "containers/LanguageProvider";
import moment from "moment/moment";
import * as mmsService from "services/mms";
import { useAvailableDates, useAvailableTimes } from "services/mms";
import { useCurrentUser } from "services/user";

export const TeacherContentBox = ({
  data,
  showCounter = false,
  footer = null,
  isDrawerOpen,
  setIsDrawerOpen,
  classes: classesProps,
}) => {
  const classes = useStyles({ classes: classesProps });
  const isTeacher = data && data.role === "Student";
  const { data: user } = useCurrentUser();
  const { language } = useCurrentLanguage();
  const { teacherId: teacher, duration } = data;

  const [date, setDate] = useState(data.datetime);
  const [time, setTime] = useState(
    moment(data.datetime).format(
      language === "en" ? "hh:mm A" : "HH:mm",
      data.datetime
    )
  );
  const [hasCancelledAppointment, setHasCancelledAppointment] = useState(false);
  const [hasRescheduledAppointment, setHasRescheduledAppointment] =
    useState(false);

  const { data: availableDates } = useAvailableDates({
    minutes: duration,
    teacherId: teacher,
  });

  const { data: availableTimes, isLoading: availableTimesAreLoading } =
    useAvailableTimes({
      date,
      minutes: duration,
      teacherId: teacher,
    });

  useEffect(() => {
    setHasRescheduledAppointment(false);
    setHasCancelledAppointment(false);
  }, [date, time, isDrawerOpen]);

  useEffect(() => {
    if (availableTimes) {
      const containsSelectedTime =
        availableTimes.find((timeItem) => time && timeItem.display && time) ||
        [];

      if (containsSelectedTime.length === 0) {
        setTime(
          availableTimes.length > 0 ? availableTimes[0].display : undefined
        );
      }
    }
    // eslint-disable-next-line
  }, [availableTimes]);

  const filterAvailableDates = (date) => {
    const isAvailable = availableDates?.find(
      (dateItem) =>
        new Date(dateItem.date).toDateString() === date.toDateString()
    );
    return !isAvailable;
  };
  const {
    mutate: cancelAppointment,
    isLoading: isCancelling,
    isError: cancellationError,
  } = mmsService.useCancelAppointment({
    onSuccess: () => {
      setHasCancelledAppointment(true);
      setTimeout(() => setIsDrawerOpen(false), 1500);
    },
  });

  const {
    mutate: rescheduleAppointment,
    isLoading: isSubmitting,
    isError: submittingError,
  } = mmsService.useRescheduleAppointment({
    onSuccess: () => {
      setHasRescheduledAppointment(true);
      setTimeout(() => setIsDrawerOpen(false), 1500);
    },
  });

  const onSubmit = useCallback(() => {
    const appointmentDate = new Date(date);

    const hoursAndMinutes = time.split(":");
    appointmentDate.setHours(hoursAndMinutes[0]);
    appointmentDate.setMinutes(hoursAndMinutes[1]);

    console.log("BLA", { appointmentDate, date, hoursAndMinutes, time });

    rescheduleAppointment({
      appointmentId: data.id,
      datetime: appointmentDate.toISOString(),
      user,
    });
  }, [time, date, user, rescheduleAppointment]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid
        container
        style={{ flexGrow: 1, width: "auto" }}
        justifyContent="space-between"
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          className={classes.contentWrapper}
        >
          <Grid item>
            <Typography variant="h6" component="h2">
              {data.subject}
            </Typography>
          </Grid>
          <Grid item>
            <PersonDisplay
              src={data.teacherAvatarUri}
              alt={data.subject}
              infoMessage={
                isTeacher ? (
                  <FormattedMessage {...messages.teacher} />
                ) : (
                  <FormattedMessage {...messages.student} />
                )
              }
              name={
                isTeacher
                  ? data.teacherName
                  : `${data.studentFirstName} ${data.studentLastName}`
              }
            />
          </Grid>
        </Grid>
        {showCounter && (
          <Grid
            item
            className={classes.classTimeWrapper}
            style={{ flex: "none" }}
          >
            <Grid item>
              <Counter datetime={data.datetime} />
            </Grid>
          </Grid>
        )}
        {footer}
      </Grid>
      <Drawer
        isOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        title={<FormattedMessage {...messages.appointmentDrawerTitle} />}
        headerTopRightContent={<Counter datetime={data.datetime} />}
        onClose={() => {
          setDate(data.datetime);
          setTime(
            moment(data.datetime).format(
              language === "en" ? "hh:mm A" : "HH:mm",
              data.datetime
            )
          );
        }}
      >
        <div className={classes.avatarWrapper}>
          <div className={classes.avatarBorder}></div>
          <img
            src={
              data.teacherAvatarUri ||
              "//cdn-s.acuityscheduling.com/calendar-4771142.jpg?1607164060"
            }
            className={classes.teacherAvatar}
            alt=""
          />
          <div className={classes.subjectAvatarWrapper}>
            <div className={classes.subjectAvatarBorder}></div>
            <img
              src={data.subjectIconUri}
              className={classes.subjectAvatar}
              alt={data.subject}
            />
          </div>
        </div>
        <h2 className={classes.instrumentTitle}>{data.subject}</h2>
        <p className={classes.teacherName}>{data.teacherName}</p>
        <p className={classes.teacherField}>
          <FormattedMessage {...messages.teacher} />
        </p>
        <div className={classes.actionButtons}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.actionButton}
            onClick={() => {
              window.open("http://www.google.de", "_blank");
            }}
          >
            <CalendarAddIcon
              width={16}
              height={16}
              fill="#D23737"
              className={classes.calendarIcon}
            />
            <FormattedMessage {...messages.addToCalendar} />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.actionButton}
            onClick={() => {
              window.open(data.meetingLink);
            }}
          >
            <SpeechBubbleIcon
              width={16}
              height={16}
              fill="#D23737"
              className={classes.calendarIcon}
            />
            <FormattedMessage {...messages.goToLesson} />
          </Button>
          <FormControl className={classes.datePickerField}>
            <DatePicker
              value={date}
              onChange={setDate}
              shouldDisableDate={filterAvailableDates}
              label={<FormattedMessage {...messages.date} value={date} />}
            />
          </FormControl>
          <FormControl className={classes.timeField}>
            <Select
              value={time || <FormattedMessage {...messages.chooseTime} />}
              onChange={(e) => setTime(e.target.value)}
              classes={{ select: classes.select }}
              disabled={!date}
              renderValue={(selectValue) => {
                const displayValue = availableTimes?.find(
                  (timeItem) => timeItem.display === selectValue
                );
                return availableTimesAreLoading ? (
                  <CircularProgress
                    className={classes.spinner}
                    color="primary"
                  />
                ) : (
                  <SelectItem
                    classes={classes}
                    value={
                      (displayValue && displayValue.display) ||
                      selectValue || (
                        <FormattedMessage {...messages.chooseTime} />
                      )
                    }
                    icon="clock"
                    disabled={!date}
                    title={<FormattedMessage {...messages.time} />}
                  />
                );
              }}
            >
              {date &&
                availableTimes?.length &&
                availableTimes?.map((time) => (
                  <MenuItem key={time.display} value={time.display}>
                    {time.display}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div className={classes.submitActions}>
            <Button
              variant="outlined"
              component="span"
              disabled={hasCancelledAppointment}
              className={classes.actionBtn}
              onClick={() =>
                cancelAppointment({ appointmentId: data.id, user })
              }
            >
              {isCancelling ? (
                <CircularProgress
                  className={classes.spinner}
                  size={20}
                  color="white"
                />
              ) : (
                <>
                  {data.studentCanCancel ? (
                    <FormattedMessage {...messages.cancelAppointment} />
                  ) : (
                    <FormattedMessage {...messages.nonCancellableAppointment} />
                  )}
                </>
              )}
            </Button>
            <Button
              variant="contained"
              component="span"
              color="primary"
              className={classes.actionBtn}
              disabled={availableTimesAreLoading}
              onClick={onSubmit}
            >
              {isSubmitting ? (
                <CircularProgress
                  className={classes.spinner}
                  size={20}
                  color="white"
                />
              ) : (
                <>
                  {data.studentCanReschedule ? (
                    <FormattedMessage {...messages.save} />
                  ) : (
                    <FormattedMessage
                      {...messages.nonReschedulableAppointment}
                    />
                  )}
                </>
              )}
            </Button>
          </div>
        </div>
        {(hasRescheduledAppointment ||
          hasCancelledAppointment ||
          cancellationError ||
          submittingError) && (
          <div className={classes.confirmationMessage}>
            <span>
              {hasRescheduledAppointment && (
                <FormattedMessage {...messages.appointmentRescheduledSuccess} />
              )}
              {hasCancelledAppointment && (
                <FormattedMessage {...messages.appointmentCancelledSuccess} />
              )}
              {(cancellationError || submittingError) && (
                <FormattedMessage {...messages.appointmentEditError} />
              )}
            </span>
          </div>
        )}
      </Drawer>
      <img
        src={data.subjectIconUri}
        className={classes.instrumentImage}
        alt=""
      />
    </>
  );
};
