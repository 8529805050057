import { defineMessages } from "react-intl";

export default defineMessages({
  aboStatusDate: {
    defaultMessage: "Date",
    id: "app.components.ProfilePage.aboStatusDate",
  },
  aboStatusLink: {
    defaultMessage: "Abo Status",
    id: "app.components.ProfilePage.aboStatusLink",
  },
  aboStatusName: {
    defaultMessage: "Name",
    id: "app.components.ProfilePage.aboStatusName",
  },
  admin: {
    defaultMessage: "Admin",
    id: "app.containers.ProfilePage.admin",
  },
  contactInfo: {
    defaultMessage: "Contact Info",
    id: "app.containers.ProfilePage.contactInfo",
  },
  header: {
    defaultMessage: "This is ProfilePage container!",
    id: "app.containers.ProfilePage.header",
  },
  profileUpdated: {
    defaultMessage: "Profile updated",
    id: "app.containers.ProfilePage.profileUpdated",
  },
  student: {
    defaultMessage: "Student",
    id: "app.containers.ProfilePage.student",
  },
  teacher: {
    defaultMessage: "Teacher",
    id: "app.containers.ProfilePage.teacherId",
  },
  uploadPicture: {
    defaultMessage: "Upload new picture",
    id: "app.containers.ProfilePage.uploadPicture",
  },
});
