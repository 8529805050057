export const oauth = {
  // Domain name
  domain: process.env.REACT_APP_COGNITO_DOMAIN,

  // Authorized scopes
  scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],

  redirectSignIn: `${process.env.REACT_APP_BASE_URL}/home`,
  redirectSignOut: `${process.env.REACT_APP_BASE_URL}/login`,

  responseType: "code",

  options: {
    AdvancedSecurityDataCollectionFlag: true,
  },
};

const Storage = {
  bucket: process.env.REACT_APP_STORAGE_BUCKET_NAME,
  region: "eu-central-1",
};

export const awsExports = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  authentication_flow_type: "USER_PASSWORD_AUTH",
  Storage,
};
