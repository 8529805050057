import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import DatePicker from "components/DatePicker";
import FormattedMessage from "components/FormattedMessage";
import { useCurrentLanguage } from "containers/LanguageProvider";
import {
  useAvailableDates,
  useAvailableTimes,
  useSubjects,
  useTeachers,
} from "services/mms";
import { useCurrentUser } from "services/user";
import settings from "settings";

import messages from "./messages";
import SelectItem from "./SelectItem";
import useStyles from "./styles";
import TeacherSelect from "./TeacherSelect";

const Classes = ({
  allowedDurations,
  duration,
  setDuration,
  date,
  time,
  setTime,
  setDate,
  teacherId,
  subjectId,
  setTeacherId,
  setSubjectId,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { data: user } = useCurrentUser();
  const isNewTeacher =
    user && teacherId && user.preferredTeacherIds?.indexOf(teacherId) === -1;

  const { data: teachers } = useTeachers();
  const { language } = useCurrentLanguage();
  const { data: subjects } = useSubjects(language);

  const {
    data: availableDates,
    isError: availableDatesError,
    isLoading: isLoadingAvailableDates,
  } = useAvailableDates({
    minutes: duration,
    teacherId: teacherId,
  });

  const {
    data: availableTimes,
    isError: availableTimesError,
    isFetching: isLoadingAvailableTimes,
  } = useAvailableTimes({
    date,
    minutes: duration,
    teacherId: teacherId,
  });

  useEffect(() => {
    if (!subjectId && subjects?.defaultId) {
      setSubjectId(subjects.defaultId);
    }
    // eslint-disable-next-line
  }, [subjects, subjectId]);

  useEffect(() => {
    if (availableDates && availableDates.length) {
      setDate(availableDates[0].date);
    } else {
      setDate(null);
    }
    // eslint-disable-next-line
  }, [availableDates]);

  useEffect(() => {
    if (availableTimes) {
      const containsSelectedTime = availableTimes.some(
        (timeItem) => time && timeItem.display === time
      );

      if (!containsSelectedTime) {
        setTime(
          availableTimes.length > 0 ? availableTimes[0].display : undefined
        );
      }
    }
    // eslint-disable-next-line
  }, [availableTimes]);

  const teacherName = useMemo(() => {
    const name = teachers?.find((teacher) => teacher.id === teacherId)?.name;
    if (name) {
      return name.match(/(.*)\(/)?.[1].trim();
    }
    return name;
  }, [teacherId, teachers]);

  const filterAvailableDates = (date) => {
    const isAvailable = availableDates.find(
      (dateItem) =>
        new Date(dateItem.date).toDateString() === date.toDateString()
    );
    return !isAvailable;
  };

  const disableTimeSelect =
    !date || !!availableTimesError || !availableTimes?.length;

  const showNoAvailableDatesMessage =
    !isLoadingAvailableDates &&
    !isLoadingAvailableTimes &&
    availableDates &&
    !availableDates.length;

  return (
    <Paper elevation={0} square className={classes.rootWrapper}>
      <div className={classes.container}>
        <Typography variant="body1" className={classes.headline}>
          <FormattedMessage {...messages.class} />
        </Typography>
        <div className={classes.classDetails}>
          <div className={classes.classDetailsLeftCol}>
            <FormControl className={classes.textField}>
              <Select
                value={subjectId || -1}
                onChange={(event) => {
                  setSubjectId(parseInt(event.target.value, 10));
                  setTeacherId(undefined);
                }}
                displayEmpty
                classes={{ select: classes.select }}
                renderValue={() => {
                  const value = subjects?.all.find(
                    (sub) => sub.id === subjectId
                  );
                  return subjectId && value ? (
                    <SelectItem
                      classes={classes}
                      variant="square"
                      value={value.name}
                      title={<FormattedMessage {...messages.instrument} />}
                      imageUrl={value.subjectIconUri}
                    />
                  ) : (
                    <CircularProgress
                      className={classes.spinner}
                      size={20}
                      color="primary"
                    />
                  );
                }}
              >
                {subjects?.all.map((subject) => (
                  <MenuItem
                    key={subject.id}
                    value={subject.id}
                    className={classes.teacherMenuItem}
                  >
                    <img
                      src={subject.subjectIconUri}
                      className={classes.subjectImage}
                      alt=""
                    />
                    <span className={classes.teacherName}>{subject.name}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={`${classes.teacherSelect}`}>
              <TeacherSelect
                teacherId={teacherId}
                setTeacherId={setTeacherId}
                subjectId={subjectId}
              />
            </FormControl>
          </div>
          <div className={classes.classDetailsRightCol}>
            {allowedDurations?.map((item) => (
              <DurationSelect
                key={item}
                classes={classes}
                value={item}
                isSelected={item === duration}
                setDuration={setDuration}
              />
            ))}
          </div>
        </div>
        <Typography
          variant="body1"
          className={`${classes.headline} ${classes.dateAndTime}`}
        >
          <FormattedMessage {...messages.dateAndTime} />
        </Typography>
        <div className={classes.dateSelection}>
          <div className={classes.datePickerRow}>
            <FormControl className={classes.datePickerField}>
              {isLoadingAvailableDates && (
                <div className={classes.timePickerSpinner}>
                  <CircularProgress size={20} color="primary" />
                </div>
              )}
              <DatePicker
                disabled={
                  !date || !!availableDatesError || isLoadingAvailableDates
                }
                label={<FormattedMessage {...messages.date} value={date} />}
                maxDate={availableDates?.slice(-1)?.[0]?.date}
                onChange={(value) => setDate(value)}
                shouldDisableDate={filterAvailableDates}
                value={date}
                emptyLabel={intl.formatMessage(messages.dateError)}
              />
            </FormControl>
            <FormControl className={classes.timeField}>
              {isLoadingAvailableDates ||
                (isLoadingAvailableTimes && (
                  <div className={classes.timePickerSpinner}>
                    <CircularProgress size={20} color="primary" />
                  </div>
                ))}
              <Select
                value={time || <FormattedMessage {...messages.chooseTime} />}
                onChange={(e) => setTime(e.target.value)}
                classes={{ select: classes.select }}
                disabled={disableTimeSelect || isLoadingAvailableTimes}
                displayEmpty
                renderValue={(selectValue) => {
                  const displayValue = availableTimes?.find(
                    (timeItem) => timeItem.display === selectValue
                  );

                  const value = displayValue?.display ||
                    (disableTimeSelect && (
                      <FormattedMessage {...messages.dateTimeError} />
                    )) || <FormattedMessage {...messages.chooseTime} />;
                  return (
                    <SelectItem
                      classes={classes}
                      value={value}
                      icon="clock"
                      disabled={!date}
                      title={intl.formatMessage(messages.time)}
                    />
                  );
                }}
              >
                {availableTimes?.map((time) => (
                  <MenuItem key={time.display} value={time.display}>
                    {time.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {!showNoAvailableDatesMessage && isNewTeacher && (
            <div className={classes.noAvailableDatesMessage}>
              <FormattedMessage
                {...messages.newTeacher}
                values={{
                  teacher: teacherName,
                }}
              />
            </div>
          )}
          {showNoAvailableDatesMessage && (
            <div className={classes.noAvailableDatesMessage}>
              <FormattedMessage
                {...messages.noAvailableDatesMessage}
                values={{
                  email: settings.MMS_HELLO_EMAIL,
                  "mail-link": (chunks) => {
                    const href = `mailto:${chunks}`;
                    return <a href={href}>{chunks}</a>;
                  },
                  teacher: teacherName,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
};

const DurationSelect = ({ classes, value, isSelected, setDuration }) => (
  <div
    className={`${classes.box} ${isSelected ? classes.selectedBox : ""}`}
    onClick={() => setDuration(value)}
  >
    <span>
      {value} <FormattedMessage {...messages.minutes} />
    </span>
  </div>
);

export default Classes;
