import { defineMessages } from "react-intl";

export default defineMessages({
  CodeMismatchException: {
    defaultMessage:
      "Invalid verification code provided, please request a code again.",
    id: "CodeMismatchException",
  },
  ExpiredCodeException: {
    defaultMessage: "Invalid code provided, please request a code again.",
    id: "ExpiredCodeException",
  },
  InvalidParameterException: {
    defaultMessage: "Invalid data",
    id: "InvalidParameterException",
  },
  InvalidPasswordException: {
    defaultMessage:
      "Password did not conform with policy: Password must have symbol characters",
    id: "InvalidPasswordException",
  },
  LimitExceededException: {
    defaultMessage: "Attempt limit exceeded, please try after some time.",
    id: "LimitExceededException",
  },
  NotAuthorizedException: {
    defaultMessage: "Invalid email or password",
    id: "NotAuthorizedException",
  },
  TooManyRequestsException: {
    defaultMessage: "Rate exceeded",
    id: "TooManyRequestsException",
  },
  UsernameExistsException: {
    defaultMessage: "An account with the given email already exists",
    id: "UsernameExistsException",
  },
  UserNotConfirmedException: {
    defaultMessage: "Invalid email or password",
    id: "UserNotConfirmedException",
  },
  UserNotFoundException: {
    defaultMessage: "Invalid email or password",
    id: "UserNotFoundException",
  },
});
