const footerLinks = {
  faceBookLink: "https://www.facebook.com/mymusicschoolcom/",
  twitterLink: "https://twitter.com/mymusicschooltw/",
  instagramLink: "https://www.instagram.com/mymusicschoolcom/",
  youtubeLink: "https://www.youtube.com/channel/UCKHoA_IS4YAheM1XWR-CHcg",
};

const settings = {
  footerLinks,
  MMS_HELLO_EMAIL: "hello@mymusicschool.com",
  MMS_BASE_URL: "https://mymusicschool.com/",
  MMS_PRICING_URL() {
    return `${this.MMS_BASE_URL}#pricing`;
  },
  MMS_FAQ_URL(locale) {
    return (
      this.MMS_BASE_URL + (locale === "en" ? "en/faq/" : "haeufige-fragen/")
    );
  },
  MMS_BLOG_URL(locale) {
    return this.MMS_BASE_URL + (locale === "en" ? "en/blog-en" : "blog/");
  },
  MMS_CONTACT_URL(locale) {
    return this.MMS_BASE_URL + (locale === "en" ? "en/contact-us/" : "kontakt");
  },
  MMS_JOBS_URL() {
    return `${this.MMS_BASE_URL}jobs/`;
  },
  MMS_IMPRINT_URL(locale) {
    return this.MMS_BASE_URL + (locale === "en" ? "en/imprint/" : "impressum/");
  },
  MMS_DATA_PROTECTION_URL(locale) {
    return (
      this.MMS_BASE_URL +
      (locale === "en" ? "en/privacy-policy/" : "datenschutzerklaerung/")
    );
  },
  MMS_TERMS_AND_CONDITIONS_URL(locale) {
    return (
      this.MMS_BASE_URL +
      (locale === "en" ? "en/terms-and-conditions/" : "datenschutzerklaerung/")
    );
  },
  MMS_WITHDRAW_FROM_CONTRACTS_URL(locale) {
    return (
      this.MMS_BASE_URL +
      (locale === "en"
        ? "en/cancel-your-subscription/"
        : "vertraege-hier-kuendigen/")
    );
  },
  API: process.env.REACT_APP_API_URL,
  PRISMIC_API: "https://d51ov3ewkl.execute-api.eu-central-1.amazonaws.com/prod",
  DATE_FORMAT: {
    en: "MM/DD/YYYY",
    de: "DD.MM.YYYY",
  },
  SHORT_DATE_FORMAT: {
    en: "MMM D",
    de: "DD. MMM",
  },
  TIME_FORMAT: {
    en: "hh:mm a",
    de: "HH:mm",
  },
  DAY_FORMAT: {
    en: "ddd",
    de: "dd",
  },
  DATE_TIME_FORMAT: {
    en: "MM/DD/YYYY hh:mm a",
    de: "DD.MM.YYYY HH:mm",
  },
  NEW_CLASS_MAPPING: {
    en: "https://mymusicschool.com/en/book-online-music-lessons/",
    de: "https://mymusicschool.com/unterricht-buchen/",
  },
};

export default settings;
