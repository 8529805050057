import { Redirect } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";
import LanguageSwitcher from "components/LanguageSwitcher";
import LoginForm from "components/LoginForm";
import { useCurrentUser } from "services/user";

import messages from "./messages";

const styles = (theme) => ({
  header: {
    marginBottom: "30px",
  },
  LanguageSwitcher: {
    display: "none",

    [theme.breakpoints.down("xs")]: {
      background: "white",
      borderRadius: "8px",
      display: "inline-block",
      position: "absolute",
      right: "10px",
      top: "10px",
    },
  },
  loginBg: {
    marginTop: "-48px",
    width: "100%",
  },
  loginForm: {
    margin: "40px auto",
    maxWidth: "500px",
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      margin: "-97px 0 0 0",
      maxWidth: "100vw",
    },
  },
});

/* eslint-disable react/prefer-stateless-function */
const LoginPage = ({ classes }) => {
  const { data: user } = useCurrentUser();

  if (user) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <Hidden smUp implementation="css">
        <img
          src={`${process.env.PUBLIC_URL}/images/loginBg.png`}
          alt="My Music School"
          className={classes.loginBg}
        />
        <div className={classes.LanguageSwitcher}>
          <LanguageSwitcher />
        </div>
      </Hidden>
      <Paper elevation={0} className={classes.loginForm}>
        <Hidden xsDown implementation="css">
          <Typography
            variant="h4"
            component="h1"
            align="center"
            className={classes.header}
          >
            <FormattedMessage {...messages.header} />
          </Typography>
        </Hidden>
        <LoginForm />
      </Paper>
    </>
  );
};

export default withStyles(styles)(LoginPage);
