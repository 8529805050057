import { defineMessages } from "react-intl";

export default defineMessages({
  accountHint: {
    defaultMessage: "Don't have an account yet? ",
    id: "app.components.LoginForm.accountHint",
  },
  confirmationHeader: {
    defaultMessage: "Please check your email!",
    id: "app.containers.ForgotPasswordPage.confirmationHeader",
  },
  confirmationText: {
    defaultMessage: "Password reset instructions sent to your email address.",
    id: "app.containers.ForgotPasswordPage.confirmationText",
  },
  header: {
    defaultMessage: "Reset Password",
    id: "app.containers.ForgotPasswordPage.header",
  },
  login: {
    defaultMessage: "Do you have an account already?",
    id: "app.containers.LoginForm.login",
  },
  loginHere: {
    defaultMessage: "Login here",
    id: "app.containers.LoginForm.loginHere",
  },
  resendEmailBtn: {
    defaultMessage: "Resend email",
    id: "app.containers.ForgotPasswordPage.resendEmailBtn",
  },
  signupNow: {
    defaultMessage: "Sign up now",
    id: "app.components.LoginForm.signupNow",
  },
});
