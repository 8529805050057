import { defineMessages } from "react-intl";

export default defineMessages({
  accountHint: {
    defaultMessage: "Don't have an account yet? ",
    id: "app.components.LoginForm.accountHint",
  },
  email: {
    defaultMessage: "Email",
    id: "app.components.LoginForm.email",
  },
  emailFormat: {
    defaultMessage: "Please enter a valid email address (e.g. me@gmail.com)",
    id: "app.components.ProfileForm.emailFormat",
  },
  fieldRequired: {
    defaultMessage: "This field is required.",
    id: "app.components.LoginForm.fieldRequired",
  },
  forgotPassword: {
    defaultMessage: "Forgot password?",
    id: "app.containers.LoginForm.forgotPassword",
  },
  header: {
    defaultMessage: "This is the LoginForm component !",
    id: "app.components.LoginForm.header",
  },
  invalidLogin: {
    defaultMessage: "Invalid email or password",
    id: "app.containers.LoginForm.invalidLogin",
  },
  loginBtn: {
    defaultMessage: "Login",
    id: "app.components.LoginForm.loginBtn",
  },
  loginWithAppleText: {
    defaultMessage: "Login with Apple",
    id: "app.components.LoginForm.loginWithAppleText",
  },
  loginWithFacebookText: {
    defaultMessage: "Login with Facebook",
    id: "app.components.LoginForm.loginWithFacebookText",
  },
  loginWithGoogleText: {
    defaultMessage: "Login with Google",
    id: "app.components.LoginForm.loginWithGoogleText",
  },
  or: {
    defaultMessage: "OR",
    id: "app.components.LoginForm.or",
  },
  password: {
    defaultMessage: "Password",
    id: "app.components.LoginForm.password",
  },
  resetPassword: {
    defaultMessage: "Reset password now",
    id: "app.containers.LoginForm.resetPassword",
  },
  signupNow: {
    defaultMessage: "Sign up now",
    id: "app.components.LoginForm.signupNow",
  },
});
