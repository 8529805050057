import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CoursePreview from "components/CoursePreview";
import { useCourses } from "services/courses";

const useStyles = makeStyles((theme) => ({
  coursePreviews: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      width: "inherit",
    },
    width: "100%",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
}));

/* eslint-disable react/prefer-stateless-function */
const CoursesOverview = () => {
  const classes = useStyles();
  const { data: courses } = useCourses();

  return (
    <div className={classes.root}>
      <div className={classes.coursePreviews}>
        {courses?.map((course) => {
          const link = `/courses/${course.id}`;
          return (
            <Link
              to={link}
              href={link}
              className={classes.link}
              key={course.id}
            >
              <CoursePreview course={course.data} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CoursesOverview;
