import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import { faUserNinja as userIcon } from "@fortawesome/free-solid-svg-icons/faUserNinja";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import FormattedMessage from "components/FormattedMessage";
import { useFormik } from "formik";
import { useImpersonateContext } from "services/contexts/impersonate";
import * as Yup from "yup";

import messages from "./messages";
import { useStyles } from "./styles";

const ImpersonateUserForm = () => {
  const { impersonateEmail, setImpersonateEmail } = useImpersonateContext();
  const classes = useStyles();
  const queryClient = useQueryClient();

  const stopImpersonating = () => {
    setImpersonateEmail(undefined);
    queryClient.invalidateQueries(["user"]);
  };

  const formik = useFormik({
    initialErrors: { impersonateEmail: "" },
    initialValues: {
      impersonateEmail: "",
    },
    onSubmit: async (values) => {
      try {
        setImpersonateEmail(values.impersonateEmail);
        queryClient.invalidateQueries(["user"]);
      } catch (error) {
        formik.setFieldError("impersonateEmail", error.message);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().email(),
    }),
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Container maxWidth="md">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <FontAwesomeIcon icon={userIcon} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="impersonateEmail"
              name="impersonateEmail"
              type="email"
              variant="outlined"
              label={<FormattedMessage {...messages.impersonateEmail} />}
              value={formik.values.impersonateEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.impersonateEmail &&
                Boolean(formik.errors.impersonateEmail)
              }
              helperText={
                formik.touched.impersonateEmail &&
                Boolean(formik.errors.impersonateEmail) && (
                  <FormattedMessage id={formik.errors.impersonateEmail} />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
        >
          <Grid item>
            {impersonateEmail && (
              <Button
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting && !formik.isValid}
                onClick={() => stopImpersonating()}
              >
                <FormattedMessage
                  {...messages.stopImpersonatingSubmitButton}
                  values={{ email: impersonateEmail }}
                />
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting && !formik.isValid}
            >
              {formik.isSubmitting && (
                <CircularProgress
                  color="primary"
                  size={12}
                  style={{ marginRight: 4 }}
                />
              )}
              <FormattedMessage {...messages.submitButton} />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default ImpersonateUserForm;
