import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as AppointmentIcon } from "components/svg/calendar.svg";
import { useCurrentLanguage } from "containers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
  datepickerField: {
    margin: "0px",
    minHeight: "50px",
  },
  datePickerInput: {
    "& .MuiOutlinedInput-inputAdornedStart": {
      marginLeft: "6px",
      opacity: 1,
    },
    color: "#303E4E",
    cursor: "pointer",
    letterSpacing: "0px",
    opacity: 1,
    padding: "25px 7px 10px",
  },
  datePickerInputAdornment: {
    "& .MuiButtonBase-root": {
      padding: "3px",
    },
    marginBlock: "auto",
    marginLeft: "8px",
  },
  datePickerKeyboardIcon: {
    height: "16px",
    width: "16px",
  },
  datePickerRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  datePickerTitle: {
    color: "#99A6B7",
    fontSize: "12px",
    left: "36px !important",
    padding: "0px",
    top: "-13px !important",
  },
  pickerRoot: {
    marginLeft: "0px",
    marginTop: "0px",

    [theme.breakpoints.down("md")]: {
      margin: "0px 0px",
    },
  },
  spinner: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      height: "50px",
    },
  },
}));

export interface DatePickerProps extends KeyboardDatePickerProps {}

const DatePicker: FC<DatePickerProps> = ({ disabled, ...props }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const classes = useStyles();
  const { language } = useCurrentLanguage();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        disablePast
        onClick={() => {
          if (!disabled) {
            setIsPickerOpen(true);
          }
        }}
        onClose={() => setIsPickerOpen(false)}
        open={isPickerOpen}
        variant="inline"
        inputVariant="outlined"
        format={language === "en" ? "MM/dd/yyyy" : "dd.MM.yyyy"}
        id="date-picker-inline"
        className={classes.pickerRoot}
        InputProps={{
          className: classes.datePickerInput,
          disabled,
          readOnly: true,
        }}
        InputLabelProps={{
          className: classes.datePickerTitle,
          shrink: false,
        }}
        InputAdornmentProps={{
          className: classes.datePickerInputAdornment,
          position: "start",
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={
          <AppointmentIcon
            width={16}
            height={16}
            fill="#99A6B7"
            className={classes.datePickerKeyboardIcon}
          />
        }
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
