import { defineMessages } from "react-intl";

export default defineMessages({
  password: {
    defaultMessage: "Password",
    id: "app.components.PasswordResetForm.password",
  },
  password2: {
    defaultMessage: "Repeat Password",
    id: "app.components.PasswordResetForm.password2",
  },
  password2Hint: {
    defaultMessage: "Passwords must match.",
    id: "app.components.PasswordResetForm.password2Hint",
  },
  passwordHint: {
    defaultMessage:
      "Password must contain at least one uppercase letter, one lowercase letter, a number and a special character.",
    id: "app.components.PasswordResetForm.passwordHint",
  },
  passwordRequired: {
    defaultMessage: "This field is required.",
    id: "app.components.PasswordResetForm.passwordRequired",
  },
  passwordTooLong: {
    defaultMessage: "Password must be at most 25 characters long.",
    id: "app.components.PasswordResetForm.passwordTooLong",
  },
  passwordTooShort: {
    defaultMessage: "Password must be at least 6 characters long.",
    id: "app.components.PasswordResetForm.passwordTooShort",
  },
  resetPwdBtn: {
    defaultMessage: "Change Password",
    id: "app.components.PasswordResetForm.resetPwdBtn",
  },
});
