import { defineMessages } from "react-intl";

export default defineMessages({
  addToCalendar: {
    defaultMessage: "Add to calendar",
    id: "app.containers.BookingConfirmationPage.addToCalendar",
  },
  appointmentCancelledSuccess: {
    defaultMessage: "The appointment was successfully cancelled",
    id: "app.containers.BookingPage.appointmentCancelledSuccess",
  },
  appointmentDrawerTitle: {
    defaultMessage: "Appointment",
    id: "app.components.UpcomingClass.appointmentDrawerTitle",
  },
  appointmentEditError: {
    defaultMessage: "An error has occured",
    id: "app.containers.BookingPage.appointmentEditError",
  },
  appointmentRescheduledSuccess: {
    defaultMessage: "The appointment was successfully rescheduled",
    id: "app.containers.BookingPage.appointmentRescheduledSuccess",
  },
  Bass: {
    defaultMessage: "Bass",
    id: "app.containers.BookingPage.bass",
  },
  cancelAppointment: {
    defaultMessage: "Cancel Appointment",
    id: "app.containers.BookingPage.cancelAppointment",
  },
  Cello: {
    defaultMessage: "Cello",
    id: "app.containers.BookingPage.cello",
  },
  chooseTime: {
    defaultMessage: "Please choose a time",
    id: "app.containers.BookingPage.chooseTime",
  },
  date: {
    defaultMessage: "date",
    id: "app.containers.BookingPage.date",
  },
  Fr: {
    defaultMessage: "Fri",
    id: "app.components.UpcomingClass.Fr",
  },
  Fri: {
    defaultMessage: "Fri",
    id: "app.components.UpcomingClass.Fri",
  },
  Geige: {
    defaultMessage: "Violin",
    id: "app.containers.BookingPage.violin",
  },
  Gesang: {
    defaultMessage: "Vocals",
    id: "app.containers.BookingPage.vocals",
  },
  Gitarre: {
    defaultMessage: "Guitar",
    id: "app.containers.BookingPage.guitar",
  },
  goToLesson: {
    defaultMessage: "Go to lesson",
    id: "app.containers.BookingConfirmationPage.goToLesson",
  },
  header: {
    defaultMessage: "This is the UpcomingClass component !",
    id: "app.components.UpcomingClass.header",
  },
  Klavier: {
    defaultMessage: "Piano",
    id: "app.containers.BookingPage.piano",
  },
  Mo: {
    defaultMessage: "Mon",
    id: "app.components.UpcomingClass.Mo",
  },
  Mon: {
    defaultMessage: "Mon",
    id: "app.components.UpcomingClass.Mon",
  },
  Mundharmonika: {
    defaultMessage: "Harmonica",
    id: "app.containers.BookingPage.harmonica",
  },
  Musiktheorie: {
    defaultMessage: "Music Theorie",
    id: "app.containers.BookingPage.musicTheorie",
  },
  newClass: {
    defaultMessage: "New Class",
    id: "app.components.UpcomingClass.newClass",
  },
  nonCancellableAppointment: {
    defaultMessage: "Can't be cancelled",
    id: "app.containers.BookingPage.nonCancellableAppointment",
  },
  nonReschedulableAppointment: {
    defaultMessage: "Can't be re-scheduled",
    id: "app.containers.BookingPage.nonReschedulableAppointment",
  },
  notFound: {
    defaultMessage: "Unknown Subject",
    id: "app.containers.BookingPage.notFound",
  },
  Posaune: {
    defaultMessage: "Trombone",
    id: "app.containers.BookingPage.trombone",
  },
  Sa: {
    defaultMessage: "Sat",
    id: "app.components.UpcomingClass.Sa",
  },
  Sat: {
    defaultMessage: "Sat",
    id: "app.components.UpcomingClass.Sat",
  },
  save: {
    defaultMessage: "Save",
    id: "app.containers.BookingPage.save",
  },
  Saxophon: {
    defaultMessage: "Saxophone",
    id: "app.containers.BookingPage.saxophone",
  },
  Songwriting: {
    defaultMessage: "Songwriting",
    id: "app.containers.BookingPage.songwriting",
  },
  student: {
    defaultMessage: "Student",
    id: "app.components.UpcomingClass.student",
  },
  Su: {
    defaultMessage: "Sun",
    id: "app.components.UpcomingClass.Su",
  },
  Sun: {
    defaultMessage: "Sun",
    id: "app.components.UpcomingClass.Sun",
  },
  teacher: {
    defaultMessage: "Teacher",
    id: "app.components.UpcomingClass.teacherId",
  },
  Th: {
    defaultMessage: "Thu",
    id: "app.components.UpcomingClass.Th",
  },
  Thu: {
    defaultMessage: "Thu",
    id: "app.components.UpcomingClass.Thu",
  },
  time: {
    defaultMessage: "time",
    id: "app.containers.BookingPage.time",
  },
  Trompete: {
    defaultMessage: "Trumpet",
    id: "app.containers.BookingPage.trumpet",
  },
  Tu: {
    defaultMessage: "Tue",
    id: "app.components.UpcomingClass.Tu",
  },
  Tue: {
    defaultMessage: "Tue",
    id: "app.components.UpcomingClass.Tue",
  },
  We: {
    defaultMessage: "Wed",
    id: "app.components.UpcomingClass.We",
  },
  Wed: {
    defaultMessage: "Wed",
    id: "app.components.UpcomingClass.Wed",
  },
});
