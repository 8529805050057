import React from "react";
import FormattedMessage from "components/FormattedMessage";

import messages from "./messages";

/* eslint-disable react/prefer-stateless-function */
export default class NotFound extends React.PureComponent {
  render() {
    return (
      <h1>
        <FormattedMessage {...messages.header} />
      </h1>
    );
  }
}
