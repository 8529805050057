import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";
import PasswordResetForm from "components/PasswordResetForm";
import { authService } from "services/user";

import messages from "./messages";

const styles = (theme) => ({
  actionsContainer: {
    paddingTop: "25px",
    textAlign: "center",
    width: "100%",
  },
  button: {
    margin: "0px",
    width: "100%",
  },
  emailSentImage: {
    display: "block",
    marginBottom: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
  },
  form: {
    border: "1px solid #eee",
    margin: "0px auto",
    marginTop: "100px",
    maxWidth: "500px",
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      marginTop: "-48px",
    },
  },
  header: {
    marginBottom: "30px",
  },
});

const LoginLink = (props) => <Link to="/login" {...props} />;

const ForgotPasswordConfirmationPage = ({ classes }) => {
  const [wasSuccessful, setWasSuccessful] = useState();
  const [errors, setErrors] = useState();

  /*
  Note: When this page is navigated to using a link in the email,
  amplify recognizes the code parameter and redirects to the redirect-URL
  without the code in the URL. I'm not really sure, how this case should
  be handled, but for now, just grabbing the search parameters once and saving
  them locally should do the trick.
  */
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const resetPasswordReq = async (data) => {
    try {
      await authService.changePassword(
        params.get("email"),
        params.get("code"),
        data.password
      );
      setWasSuccessful(true);
    } catch (error) {
      setErrors([error.message]);
    }
  };

  const isInit = wasSuccessful === undefined;
  return (
    <Paper elevation={0} className={classes.form}>
      {isInit && (
        <>
          <Typography
            variant="h6"
            component="h3"
            align="center"
            className={classes.header}
          >
            <FormattedMessage {...messages.header} />
          </Typography>
          <PasswordResetForm resetPassword={resetPasswordReq} />
        </>
      )}
      {wasSuccessful && (
        <>
          <Typography variant="h6" component="h3" align="center">
            <FormattedMessage {...messages.confirmationHeader} />
          </Typography>
          <div className={classes.actionsContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component={LoginLink}
            >
              <FormattedMessage {...messages.loginBtn} />
            </Button>
          </div>
        </>
      )}
      {errors &&
        errors.map((error) => (
          <Typography key={error} color="error" align="center">
            {error}
          </Typography>
        ))}
    </Paper>
  );
};

export default withStyles(styles)(ForgotPasswordConfirmationPage);
