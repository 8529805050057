import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";

import messages from "./messages";

const useStyles = makeStyles((theme) => ({
  headerDescription: {
    color: "#6F7B8A",
  },
  headline: {
    color: "#303E4E",
    fontWeight: "700",
  },
  rootWrapper: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1100px",
    width: "70%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.headline}>
        <FormattedMessage {...messages.headline} />
      </Typography>
      <Typography variant="body1" className={classes.headerDescription}>
        <FormattedMessage {...messages.headerDescription} />
      </Typography>
    </>
  );
};

export default Header;
