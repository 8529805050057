import { useQuery } from "@tanstack/react-query";
import { useCurrentLanguage } from "containers/LanguageProvider";
import Prismic from "prismic-javascript";
import settings from "settings";

import { useCurrentUser } from "./user";

export const getCourses = (user, language) => {
  const prismicApiLanguage = language === "de" ? "de-de" : "en-us";

  return Prismic.api(settings.PRISMIC_API, { accessToken: user.idToken }).then(
    (api) =>
      api
        .query(Prismic.Predicates.at("document.type", "online_course"), {
          fetchLinks: [
            "teacherId.name",
            "subject.name",
            "video.video_title",
            "video.video_description",
            "video.youtube_id",
          ],
          lang: prismicApiLanguage,
        })
        .then((response) => response.results)
  );
};

export const useCourses = () => {
  const { data: user } = useCurrentUser();
  const { language } = useCurrentLanguage();
  return useQuery(
    ["user", "courses", language],
    () => getCourses(user, language),
    {
      enabled: !!user && !!language,
    }
  );
};
