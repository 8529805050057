import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useCurrentLanguage } from "containers/LanguageProvider";
import { format } from "date-fns";
import germanLocale from "date-fns/locale/de";
import PropTypes from "prop-types";

const textColor1 = "#99a6b7";

const useStyles = makeStyles((theme) => ({
  archiveRow: {
    alignItems: "center",
    background: "#fff",
    border: "1px solid #E4E4E4",
    borderRadius: "6px",
    display: "flex",
    height: "80px",
    marginBottom: "10px",
    overflow: "hidden",
    padding: "10px 10px",
    position: "relative",
  },
  archiveRowDate: {
    alignSelf: "center",
    color: textColor1,
    fontSize: "15px",
    marginLeft: "auto",
    marginTop: "6px",
  },
  archiveRowImage: {
    maxWidth: "60px",
    minWidth: "60px",
    opacity: "0.1",
    overflow: "hidden",
    width: "60px",
  },
  archiveRowText: {
    marginLeft: "30px",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "10px",
    },
  },
  instrumentImage: {
    display: "inline-block",
    height: "60px",
    position: "absolute",
  },
  teacherTitle: {
    color: textColor1,
    fontSize: "15px",
  },
}));
/* eslint-disable react/prefer-stateless-function */
const ArchivedClass = ({ data, dateFormat }) => {
  const classes = useStyles();
  const { language } = useCurrentLanguage();

  return (
    <div className={classes.archiveRow}>
      <img
        src={data.subjectIconUri}
        className={classes.archiveRowImage}
        alt={data.subject}
        title={data.subject}
      />
      <div className={classes.archiveRowText}>
        <Typography variant="h6" component="h2">
          {data.subject}
        </Typography>
        <div className={classes.teacherTitle}>{data.teacherName}</div>
      </div>
      <div className={classes.archiveRowDate}>
        {format(
          new Date(data.datetime),
          language === "en" ? "MM/dd/yyyy" : "dd.MM.yyyy",
          {
            locale: language === "en" ? "" : germanLocale,
          }
        )}
      </div>
    </div>
  );
};

ArchivedClass.propTypes = {
  data: PropTypes.object,
  dateFormat: PropTypes.string,
};

export default ArchivedClass;
