import { useRef, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grow,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Alert from "@material-ui/lab/Alert";
import FormattedMessage from "components/FormattedMessage";
import { styles } from "components/PrivateLayout/styles";
import ProfilePhoto from "components/ProfilePhoto";
import { ReactComponent as ClassesIcon } from "components/svg/classes.svg";
import { ReactComponent as HomeIcon } from "components/svg/home.svg";
import { ReactComponent as LiveLessonIcon } from "components/svg/live-lesson.svg";
import { ReactComponent as MaterialIcon } from "components/svg/material.svg";
import { ReactComponent as MMSIconLarge } from "components/svg/mymusicschool-logo.svg";
import { ReactComponent as MMSIconSmall } from "components/svg/mymusicschool-logo-circle.svg";
import BookingConfirmationPage from "containers/BookingConfirmationPage";
import BookingPage from "containers/BookingPage";
import ClassesPage from "containers/ClassesPage";
import CoursePage from "containers/CoursePage";
import HomePage from "containers/HomePage";
import { useCurrentLanguage } from "containers/LanguageProvider";
import LiveLessonPage from "containers/LiveLessonPage";
import MaterialsPage from "containers/MaterialsPage";
import ProfilePage from "containers/ProfilePage";
import { useImpersonateContext } from "services/contexts/impersonate";
import { authService, useCurrentUser } from "services/user";
import settings from "settings";

import messages from "./messages";

const allMenuItems = ["home", "appointments", "liveLesson", "materials"];

const UserToolbar = ({ classes }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { data: user } = useCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const anchorEl = useRef();
  const { impersonateEmail } = useImpersonateContext();

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    if (anchorEl.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  const handleProfile = () => {
    setMenuOpen(false);

    const route = "/profile";
    if (route !== location.pathname) {
      history.push(route);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Toolbar>
      <div className={classes.toolbarWrapper}>
        <Hidden xsDown>
          <Link to="/home" href="/home">
            <MMSIconLarge className={classes.logo} />
          </Link>
        </Hidden>
        <Hidden smUp>
          <Link to="/home" href="/home">
            <MMSIconSmall className={classes.logoSmall} />
          </Link>
        </Hidden>

        <Hidden smDown>
          <div className={classes.grow} />
        </Hidden>
        {impersonateEmail && (
          <Alert severity="warning" size="small">
            <FormattedMessage
              {...messages.impersonateEmail}
              values={{ email: impersonateEmail }}
            />
          </Alert>
        )}
        <div className={classes.profileDropdown}>
          <Button
            ref={anchorEl}
            aria-owns={menuOpen ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.dropdown}
            classes={{ label: classes.dropdown }}
            disableRipple
          >
            <span className={classes.profilePhoto}>
              <ProfilePhoto s3Key={user.picture} size="30" />
            </span>
            <span>
              {user.given_name} {user.family_name}
            </span>
            <ArrowDropDownIcon />
          </Button>
          <Popper
            open={menuOpen}
            anchorEl={anchorEl.current}
            transition
            // disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem onClick={handleProfile}>
                        <FormattedMessage {...messages.dropDownProfile} />
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="https://mymusicschool.com/"
                          className={classes.dropdownLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FormattedMessage {...messages.menuToMms} />
                        </a>
                      </MenuItem>
                      <MenuItem onClick={authService.signOut}>
                        <FormattedMessage {...messages.dropDownLogout} />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </Toolbar>
  );
};

const Navigation = ({ classes }) => {
  const theme = useTheme();
  const location = useLocation();

  const isSelected = (path) => {
    const selectedItemIndex =
      allMenuItems.indexOf(location.pathname.split("/")[2]) || 0;
    return allMenuItems.indexOf(path) === selectedItemIndex;
  };

  return (
    <div className={classes.menuWrapper}>
      <List>
        <div className={classes.listItemsWrapper}>
          <ListItem
            component={Link}
            to="/home"
            button
            className={classes.menuItem}
            style={{
              color: isSelected("home")
                ? theme.palette.primary.main
                : theme.palette.grey["500"],
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <HomeIcon className={classes.menuItemIconSvg} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.menuHome} />}
              className={classes.menuText}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/appointments"
            button
            className={classes.menuItem}
            style={{
              color: isSelected("appointments")
                ? theme.palette.primary.main
                : theme.palette.grey["500"],
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <ClassesIcon className={classes.menuItemIconSvg} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.menuClasses} />}
              className={classes.menuText}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/liveLesson"
            button
            className={classes.menuItem}
            style={{
              color: isSelected("liveLesson")
                ? theme.palette.primary.main
                : theme.palette.grey["500"],
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <LiveLessonIcon className={classes.menuItemIconSvg} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.menuLiveLesson} />}
              className={classes.menuText}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/materials"
            button
            className={classes.menuItem}
            style={{
              color: isSelected("materials")
                ? theme.palette.primary.main
                : theme.palette.grey["500"],
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <MaterialIcon className={classes.menuItemIconSvg} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.menuCourses} />}
              className={classes.menuText}
            />
          </ListItem>
        </div>
      </List>
    </div>
  );
};

/* eslint-disable no-underscore-dangle */
const PrivateLayout = ({ classes }) => {
  const { data: user, isLoading } = useCurrentUser();
  const { language } = useCurrentLanguage();

  if (isLoading) {
    return (
      <div className={classes.root}>
        <div className={classes.spinnerWrapper}>
          <CircularProgress className={classes.spinner} color="primary" />
        </div>
      </div>
    );
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} elevation={0} position="sticky">
        <UserToolbar classes={classes} />
      </AppBar>
      <main className={classes.content}>
        <Box className={classes.navigation}>
          <Navigation classes={classes} />
        </Box>
        <Box className={classes.mainContentWrapper}>
          <Switch>
            <Route path="/home" component={HomePage} />
            <Route path="/materials" component={MaterialsPage} />
            <Route path="/courses/:courseId" component={CoursePage} />
            <Route path="/profile" component={ProfilePage} />
            <Route path="/appointments/book" component={BookingPage} />
            <Route
              path="/appointments/confirmation"
              component={BookingConfirmationPage}
            />
            <Route path="/appointments" component={ClassesPage} />
            <Route path="/liveLesson" component={LiveLessonPage} />
          </Switch>
          <div className={classes.requiredLinks}>
            <a href={settings.MMS_IMPRINT_URL(language)}>
              <FormattedMessage {...messages.imprintLink} />
            </a>
            <Divider orientation="vertical" />
            <a href={settings.MMS_DATA_PROTECTION_URL(language)}>
              <FormattedMessage {...messages.privacyPolicyLink} />
            </a>
            <Divider orientation="vertical" />
            <a href={settings.MMS_WITHDRAW_FROM_CONTRACTS_URL(language)}>
              <FormattedMessage {...messages.withdrawFromContractsLink} />
            </a>
          </div>
        </Box>
      </main>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(PrivateLayout);
