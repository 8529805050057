import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCurrentLanguage } from "containers/LanguageProvider";
import moment from "moment";
import settings from "settings";

import { useCurrentUser } from "./user";

export const getAppointments = async (user) => {
  const todayMinusTwoYears = moment().subtract(2, "years");
  const response = await axios.get(
    `${settings.API}/appointments?minDate=${moment(todayMinusTwoYears).format(
      "MM/DD/YYYY"
    )}`,
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );

  return response.data.appointments;
};

export const useAppointments = () => {
  const { data: user } = useCurrentUser();
  const query = useQuery(
    ["user", "appointments"],
    async () => {
      const all = await getAppointments(user);
      const allWithEndTimes = all.map((appointment) => {
        const endTime = moment(appointment.datetime).add(
          parseInt(appointment.duration, 10),
          "minutes"
        );
        return {
          ...appointment,
          endTime,
        };
      });

      const now = moment();
      const past = allWithEndTimes.filter((appointment) =>
        now.isAfter(appointment.endTime)
      );

      const upcoming = allWithEndTimes
        .filter((appointment) => !now.isAfter(appointment.endTime))
        .reverse();

      return {
        all,
        past,
        upcoming,
      };
    },
    {
      enabled: !!user,
    }
  );
  return query;
};

export const useArchivedCategories = () => {
  const { data: appointments } = useAppointments();

  if (!appointments) return [];

  return [
    ...new Set(
      appointments.all
        .filter((item) => moment().isAfter(moment(item.datetime), "hour"))
        .map((item) => item.subject)
    ),
  ];
};

export const getSubjects = async (language) => {
  try {
    const response = await axios.get(
      `${settings.API}/subjects?lang=${language}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useSubjects = () => {
  const { language } = useCurrentLanguage();

  const query = useQuery(["user", "subjects"], async () => {
    const all = await getSubjects(language);

    const defaultSubject = all.find((subject) => subject.default);

    const defaultId = defaultSubject
      ? defaultSubject.id
      : all.length > 0
      ? all[0].id
      : undefined;

    return { all, defaultId };
  });

  return query;
};

export async function getTeachers(user) {
  const response = await axios.get(`${settings.API}/teachers`, {
    headers: {
      Authorization: user.idToken,
    },
  });
  return response.data;
}

export const useTeachers = () => {
  const { data: user } = useCurrentUser();
  const query = useQuery(["user", "teachers"], () => getTeachers(user), {
    enabled: !!user,
  });

  return query;
};

export const getDateAvailability = async ({ user, teacherId, minutes }) => {
  const response = await axios.get(
    `${settings.API}/availability/dates?teacherId=${teacherId}&minutes=${minutes}`,
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );
  return response.data;
};

export const useAvailableDates = ({ teacherId, minutes }) => {
  const { data: user } = useCurrentUser();
  const query = useQuery(
    ["user", "availableDates", teacherId, minutes],
    () => getDateAvailability({ minutes, teacherId, user }),
    {
      enabled: !!user && !!teacherId && !!minutes,
    }
  );

  return query;
};

export const usePlans = () => {
  const { data: user } = useCurrentUser();
  return useQuery(
    ["user", "plans"],
    async () => {
      const result = await axios.get(`${settings.API}/plans`, {
        headers: {
          Authorization: user.idToken,
        },
      });
      const all = result.data.map((item) => ({
        id: item.certificate,
        ...item,
      }));
      const defaultId = all.length > 0 ? all[0].id : undefined;
      return { all, defaultId };
    },
    { enabled: !!user }
  );
};

export const getTimeAvailability = async ({
  user,
  teacherId,
  minutes,
  date,
}) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const response = await axios.get(
    `${settings.API}/availability/times?teacherId=${teacherId}&minutes=${minutes}&date=${formattedDate}`,
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );
  return response.data;
};

export const useAvailableTimes = ({ teacherId, minutes, date }) => {
  const { data: user } = useCurrentUser();
  const locale = user?.language;
  const query = useQuery(
    ["user", "availableTimes", teacherId, minutes, date],
    async () => {
      const data = await getTimeAvailability({
        date,
        minutes,
        teacherId,
        user,
      });

      const availableTimes = data.map(({ time: availableTimeItem }) => ({
        display: moment(availableTimeItem).format(
          locale === "en" ? "hh:mm A" : "HH:mm"
        ),
        value: availableTimeItem,
      }));
      return availableTimes;
    },
    { enabled: !!user && !!teacherId && !!minutes && !!date }
  );
  return query;
};

export const checkTimes = async ({
  user,
  teacherId,
  minutes,
  dateTime,
  repetition,
  endsAfter,
}) => {
  const response = await axios.post(
    `${settings.API}/availability/check-times`,
    {
      datetime: dateTime,
      minutes: minutes,
      recurrence: repetition,
      recurrence_end_after: parseInt(endsAfter, 10),
      teacherId: teacherId,
    },
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );
  return response.data;
};

export const bookAppointments = async ({ appointments, user }) => {
  const response = await axios.post(
    `${settings.API}/appointments`,
    appointments,
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );
  return response.data;
};

export const useBookAppointments = (options) => {
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();

  return useMutation(
    async ({ appointments }) =>
      await bookAppointments({
        appointments,
        user,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user", "appointments"]);
        queryClient.invalidateQueries(["user", "plans"]);
        options?.onSuccess(data);
      },
    }
  );
};

export const rescheduleAppointment = async ({
  user,
  appointmentId,
  datetime,
}) => {
  const response = await axios.put(
    `${settings.API}/appointments/${appointmentId}/reschedule`,
    { datetime },
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );
  return response.data;
};

export const useRescheduleAppointment = (options) => {
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();
  return useMutation(
    async ({ appointmentId, datetime }) => {
      await rescheduleAppointment({ appointmentId, datetime, user });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", "appointments"]);
        queryClient.invalidateQueries(["user", "plans"]);
        options?.onSuccess();
      },
    }
  );
};

export const cancelAppointment = async ({ user, appointmentId }) => {
  const response = await axios.put(
    `${settings.API}/appointments/${appointmentId}/cancel`,
    {},
    {
      headers: {
        Authorization: user.idToken,
      },
    }
  );
  return response.data;
};

export const useCancelAppointment = (options) => {
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();
  return useMutation(
    async ({ appointmentId }) => {
      await cancelAppointment({ appointmentId, user });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", "appointments"]);
        queryClient.invalidateQueries(["user", "plans"]);
        options?.onSuccess();
      },
    }
  );
};
