import { forwardRef } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import FormattedMessage from "components/FormattedMessage";
import LanguageSwitcher from "components/LanguageSwitcher";
import { ReactComponent as MMSIconLarge } from "components/svg/mymusicschool-logo.svg";
import { ReactComponent as MMSIconSmall } from "components/svg/mymusicschool-logo-circle.svg";
import ForgotPasswordConfirmationPage from "containers/ForgotPasswordConfirmationPage";
import ForgotPasswordPage from "containers/ForgotPasswordPage";
import ForgotPasswordSentPage from "containers/ForgotPasswordSentPage";
import LoginPage from "containers/LoginPage";
import SignupPage from "containers/SignupPage";
import SignupSucessPage from "containers/SignupSuccessPage";
import SignupVerificationPage from "containers/SignupVerificationPage";
import SocialAuthConfirmPage from "containers/SocialAuthConfirmPage";

import settings from "../../settings";

import messages from "./messages";

const styles = (theme) => ({
  appBar: {
    backgroundColor: "#fff",
  },
  button: {
    fontWeight: "normal",
    marginLeft: "5px",
  },
  footer: {
    backgroundColor: "#fff",
    bottom: 0,
    color: "#99A6B7",
    padding: "0px 20px 0px 10px",
    position: "fixed",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
      position: "relative",
    },
  },
  footerForgotPassword: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  footerLeft: {
    "& div": {
      padding: "0 10px 20px 10px",
    },
    color: theme.palette.primary.main,
    display: "flex",
    padding: "0px !important",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  footerRight: {
    display: "flex",
    justifyContent: "end",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: "10px",
    },
  },
  footerRow: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 0px 10px",
  },
  footerRow1: {
    "& div": {
      padding: "10px 10px 10px 5px",
    },
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      "& div": {
        padding: "0px 10px 10px 5px",
      },
      flexDirection: "column",

      padding: "2px",
    },
  },
  footerRow1Sec: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  footerRow2: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    color: "#99A6B7",
    textDecoration: "none",
  },
  logo: {
    height: 36,
  },
  logoLarge: {
    width: "250px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logoSmall: {
    display: "none",
    height: "32px",
    width: "32px",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
    },
  },
  main: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
    },
  },
  menuItem: {
    flexFlow: "column",
    height: "84px",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
});

const SignUpLink = forwardRef((props, ref) => (
  <Link ref={ref} to="/signup" {...props} />
));
const LoginLink = forwardRef((props, ref) => (
  <Link ref={ref} to="/login" {...props} />
));

/* eslint-disable react/prefer-stateless-function */
const DefaultLayout = ({ classes, locale }) => {
  const { pathname } = useLocation();
  let { isLoginPage } = false;
  let { isSignupPage } = false;
  if (pathname === "/login") {
    isLoginPage = true;
    isSignupPage = false;
  }

  if (
    pathname === "/signup" ||
    pathname === "/forgotPassword" ||
    pathname === "/resetPassword"
  ) {
    isLoginPage = false;
    isSignupPage = true;
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        className={classNames(
          pathname === "/login" ? classes.sectionDesktop : null,
          classes.appBar
        )}
        elevation={0}
      >
        <Toolbar>
          <Link href="/" to="/">
            <MMSIconSmall className={classes.logoSmall} />
            <MMSIconLarge className={classes.logoLarge} />
          </Link>
          <div className={classes.grow} />

          <LanguageSwitcher />
          <div
            className={pathname === "/login" ? classes.sectionDesktop : null}
          >
            {isLoginPage && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={SignUpLink}
              >
                <FormattedMessage {...messages.signUp} />
              </Button>
            )}
            {isSignupPage && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={LoginLink}
              >
                <FormattedMessage {...messages.login} />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <Switch>
          <Route path="/signup/success" component={SignupSucessPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/verifySignup" component={SignupVerificationPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/socialAuthConfirm" component={SocialAuthConfirmPage} />
          <Route
            path="/forgotPassword/sent"
            component={ForgotPasswordSentPage}
          />
          <Route path="/forgotPassword" component={ForgotPasswordPage} />
          <Route
            path="/resetPassword"
            component={ForgotPasswordConfirmationPage}
          />
        </Switch>
      </div>
      <AppBar
        component="footer"
        position="static"
        color="default"
        className={classNames(
          classes.footer,
          pathname === "/forgotPassword" ? classes.footerForgotPassword : null
        )}
      >
        <div className={classes.footerRow}>
          <div className={classes.footerRow1}>
            <div className={classes.footerRow1Sec}>
              <div>
                <a className={classes.link} href={settings.MMS_FAQ_URL(locale)}>
                  {<FormattedMessage {...messages.faqLink} />}
                </a>
              </div>
              <div>
                <a
                  className={classes.link}
                  href={settings.MMS_BLOG_URL(locale)}
                >
                  {<FormattedMessage {...messages.blogLink} />}
                </a>
              </div>
              <div>
                <a className={classes.link} href={settings.MMS_CONTACT_URL()}>
                  {<FormattedMessage {...messages.contactLink} />}
                </a>
              </div>
              <div>
                <a className={classes.link} href={settings.MMS_JOBS_URL()}>
                  {<FormattedMessage {...messages.jobsLink} />}
                </a>
              </div>
              <div>
                <a
                  className={classes.link}
                  href={settings.MMS_IMPRINT_URL(locale)}
                >
                  {<FormattedMessage {...messages.imprintLink} />}
                </a>
              </div>
              <div>
                <a
                  className={classes.link}
                  href={settings.MMS_DATA_PROTECTION_URL(locale)}
                >
                  {<FormattedMessage {...messages.dataProtectionLink} />}
                </a>
              </div>
              <div>
                <a
                  className={classes.link}
                  href={settings.MMS_TERMS_AND_CONDITIONS_URL(locale)}
                >
                  {<FormattedMessage {...messages.termsAndConditionsLink} />}
                </a>
              </div>
              <div>
                <a
                  className={classes.link}
                  href={settings.MMS_WITHDRAW_FROM_CONTRACTS_URL(locale)}
                >
                  {<FormattedMessage {...messages.withdrawFromContractsLink} />}
                </a>
              </div>
            </div>
          </div>
          <div className={classNames(classes.footerRow2)}>
            <div className={classes.footerLeft}>
              <div>
                <a href={settings.footerLinks.faceBookLink} target="_new">
                  <FontAwesomeIcon
                    color="#303E4E"
                    size="lg"
                    icon={faFacebook}
                  />
                </a>
              </div>
              <div>
                <a href={settings.footerLinks.twitterLink} target="_new">
                  <FontAwesomeIcon color="#303E4E" size="lg" icon={faTwitter} />
                </a>
              </div>
              <div>
                <a href={settings.footerLinks.instagramLink} target="_new">
                  <FontAwesomeIcon
                    color="#303E4E"
                    size="lg"
                    icon={faInstagram}
                  />
                </a>
              </div>
              <div>
                <a href={settings.footerLinks.youtubeLink} target="_new">
                  <FontAwesomeIcon color="#303E4E" size="lg" icon={faYoutube} />
                </a>
              </div>
            </div>
            <div className={classes.footerRight}>
              &copy; MYMUSICSCHOOL.COM {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </AppBar>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DefaultLayout);
