/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from "react-intl";

export default defineMessages({
  amountRepetitions: {
    defaultMessage: "Amount of repetitions",
    id: "app.containers.BookingPage.amountRepetitions",
  },
  appointmentConflict: {
    defaultMessage:
      "This conflicts with another appointment or is outside business hours.",
    id: "app.containers.BookingPage.appointmentConflict",
  },
  Bass: {
    defaultMessage: "Bass",
    id: "app.containers.BookingPage.bass",
  },
  bookAppointment: {
    defaultMessage: "Book Appointment",
    id: "app.containers.BookingPage.book",
  },
  Cello: {
    defaultMessage: "Cello",
    id: "app.containers.BookingPage.cello",
  },
  checkAvailability: {
    defaultMessage: "Check Availability",
    id: "app.containers.BookingPage.checkAvailability",
  },
  chooseTeacher: {
    defaultMessage: "Please choose a teacherId",
    id: "app.containers.BookingPage.chooseTeacher",
  },
  chooseTime: {
    defaultMessage: "Please choose a time",
    id: "app.containers.BookingPage.chooseTime",
  },
  class: {
    defaultMessage: "Class",
    id: "app.containers.BookingPage.class",
  },
  daily: {
    defaultMessage: "daily",
    id: "app.containers.BookingPage.daily",
  },
  date: {
    defaultMessage: "date",
    id: "app.containers.BookingPage.date",
  },

  dateAndTime: {
    defaultMessage: "Date & Time",
    id: "app.containers.BookingPage.dateAndTime",
  },
  dateError: {
    defaultMessage: "No dates available.",
    id: "app.containers.BookingPage.dateError",
  },
  dateTimeError: {
    defaultMessage: "No timeslots available.",
    id: "app.containers.BookingPage.dateTimeError",
  },
  Fagott: {
    defaultMessage: "Fagott",
    id: "app.containers.BookingPage.fagott",
  },
  fourWeekly: {
    defaultMessage: "4-weekly",
    id: "app.containers.BookingPage.fourWeekly",
  },
  Geige: {
    defaultMessage: "Violin",
    id: "app.containers.BookingPage.violin",
  },
  Gesang: {
    defaultMessage: "Vocals",
    id: "app.containers.BookingPage.vocals",
  },
  getAbonnenment: {
    defaultMessage: "Get Abonnenment",
    id: "app.containers.BookingPage.getAbonnenment",
  },
  Gitarre: {
    defaultMessage: "Guitar",
    id: "app.containers.BookingPage.guitar",
  },
  headerDescription: {
    defaultMessage: "New Appointment",
    id: "app.containers.BookingPage.headerDescription",
  },
  headline: {
    defaultMessage: "New Appointment",
    id: "app.containers.BookingPage.headline",
  },
  instrument: {
    defaultMessage: "Instrument",
    id: "app.containers.BookingPage.instrument",
  },
  Klavier: {
    defaultMessage: "Piano",
    id: "app.containers.BookingPage.piano",
  },
  minutes: {
    defaultMessage: "minutes",
    id: "app.containers.BookingPage.minutes",
  },
  monthly: {
    defaultMessage: "monthly",
    id: "app.containers.BookingPage.monthly",
  },
  monthlySubscriptionItemTitle: {
    defaultMessage: "Choose Package/Subscription to use",
    id: "app.containers.BookingPage.monthlySubscriptionItemTitle",
  },
  Mundharmonika: {
    defaultMessage: "Harmonica",
    id: "app.containers.BookingPage.harmonica",
  },
  Musikproduktion: {
    defaultMessage: "Musicproduction",
    id: "app.containers.BookingPage.musicproduction",
  },
  Musiktheorie: {
    defaultMessage: "Music Theorie",
    id: "app.containers.BookingPage.musicTheorie",
  },
  outOfCredits: {
    defaultMessage:
      "You don't have credits left. Please book a new abonnement first.",
    id: "app.containers.BookingPage.outOfCredits",
  },
  Posaune: {
    defaultMessage: "Trombone",
    id: "app.containers.BookingPage.trombone",
  },
  regularAppointmentRepetion: {
    defaultMessage: "Repeat",
    id: "app.containers.BookingPage.regularAppointmentRepetion",
  },
  regularAppointmentTitle: {
    defaultMessage: "Regular Appointment",
    id: "app.containers.BookingPage.regularAppointmentTitle",
  },
  remaining: {
    defaultMessage: "{minutes} minutes remaining",
    id: "app.containers.BookingPage.remaining",
  },
  newTeacher: {
    defaultMessage:
      "You haven't had a lesson with {teacher} yet. It might be possible, that we'll contact you, if {teacher} does not have time.",
    id: "app.containers.Classes.newTeacher",
  },
  noAvailableDatesMessage: {
    defaultMessage:
      "{teacher} currently doesn't have any available appointments. But don't panic, just contact us at <mail-link>{email}</mail-link> - we'll find a solution together!",
    id: "app.containers.Classes.noAvailableDatesMessage",
  },
  remainingTime: {
    defaultMessage: "min remaining",
    id: "app.containers.BookingPage.remainingTime",
  },
  removeTimeslot: {
    defaultMessage: "remove",
    id: "app.containers.BookingPage.removeTimeslot",
  },
  Saxophon: {
    defaultMessage: "Saxophone",
    id: "app.containers.BookingPage.saxophone",
  },
  Songwriting: {
    defaultMessage: "Songwriting",
    id: "app.containers.BookingPage.songwriting",
  },
  subscriptionTitle: {
    defaultMessage: "Choose Package/Subscription to use",
    id: "app.containers.BookingPage.subscriptionTitle",
  },
  teacher: {
    defaultMessage: "Teacher",
    id: "app.containers.BookingPage.teacherId",
  },
  threeWeekly: {
    defaultMessage: "3-weekly",
    id: "app.containers.BookingPage.threeWeekly",
  },
  time: {
    defaultMessage: "time",
    id: "app.containers.BookingPage.time",
  },
  Trompete: {
    defaultMessage: "Trumpet",
    id: "app.containers.BookingPage.trumpet",
  },
  twoWeekly: {
    defaultMessage: "2-weekly",
    id: "app.containers.BookingPage.twoWeekly",
  },
  weekly: {
    defaultMessage: "Weekly",
    id: "app.containers.BookingPage.weekly",
  },
  yearlySubscriptionItemTitle: {
    defaultMessage: "Monthly Subscription (60min)",
    id: "app.containers.BookingPage.yearlySubscriptionItemTitle",
  },
});
