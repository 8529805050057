import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { PrismicRichText } from "@prismicio/react";

import Lesson from "./Lesson";

const styles = (theme) => ({
  coursePreviewsWrapper: {
    display: "inline-block",
    width: "100%",
  },
  description: { fontSize: "1.2rem" },
  heading: { marginBottom: theme.spacing(1) },
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

/* eslint-disable react/prefer-stateless-function */
const CourseDetail = ({ course, classes }) => {
  const name = course.data.name;
  const subject =
    course.data.subject.data != null ? course.data.subject.data.name : "";
  const lessons = course.data.lessons;

  return (
    <div>
      <Paper className={classes.root} elevation={1} square>
        <Typography
          className={classes.subject}
          color="textSecondary"
          gutterBottom
        >
          {subject}
        </Typography>
        <Typography className={classes.heading} variant="h5" component="h2">
          {name}
        </Typography>
        <PrismicRichText
          field={course.data.description}
          components={{
            heading1: ({ children }) => (
              <Typography variant="h1">{children}</Typography>
            ),
            heading2: ({ children }) => (
              <Typography variant="h2">{children}</Typography>
            ),
            heading3: ({ children }) => (
              <Typography variant="h3">{children}</Typography>
            ),
            heading4: ({ children }) => (
              <Typography variant="h4">{children}</Typography>
            ),
            heading5: ({ children }) => (
              <Typography variant="h5">{children}</Typography>
            ),
            heading6: ({ children }) => (
              <Typography variant="h6">{children}</Typography>
            ),
            paragraph: ({ children }) => (
              <Typography variant="body1">{children}</Typography>
            ),
            strong: ({ children }) => (
              <Typography
                component="span"
                variant="body1"
                style={{ fontWeight: "bold" }}
              >
                {children}
              </Typography>
            ),
          }}
        />
      </Paper>
      {lessons.map((lesson) => (
        <Lesson lesson={lesson} key={lesson.lesson_content.id} />
      ))}
    </div>
  );
};

export default withStyles(styles)(CourseDetail);
