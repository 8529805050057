import { FC } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import FormattedMessage from "components/FormattedMessage";
import PersonDisplay from "components/PersonDisplay";
import { ReactComponent as AppointmentIcon } from "components/svg/calendar.svg";
import { ReactComponent as CalendarAddIcon } from "components/svg/calendar_plus.svg";
import { ReactComponent as ClockIcon } from "components/svg/clock.svg";
import { useCurrentLanguage } from "containers/LanguageProvider";
import { addMinutes, format } from "date-fns";
import germanLocale from "date-fns/locale/de";
import englishLocale from "date-fns/locale/en-US";
import { Appointment } from "types/appointment";

import messages from "./messages";
import { useStyles } from "./styles";

interface BookedAppointmentProps {
  appointment: Appointment;
}

export const BookedAppointment: FC<BookedAppointmentProps> = ({
  appointment,
}) => {
  const classes = useStyles();
  const { language } = useCurrentLanguage();
  const isTeacher = appointment && appointment.role === "Student";

  return (
    <Grid
      container
      className={classes.bookedAppointmentWrapper}
      justifyContent="space-between"
    >
      <Grid item container direction="column" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="h2">
            {appointment.subject}
          </Typography>
        </Grid>
        <Grid item>
          <PersonDisplay
            src={appointment.teacherAvatarUri}
            alt={appointment.subject}
            infoMessage={
              isTeacher ? (
                <FormattedMessage {...messages.teacher} />
              ) : (
                <FormattedMessage {...messages.student} />
              )
            }
            name={
              isTeacher
                ? appointment.teacherName
                : `${appointment.studentFirstName} ${appointment.studentLastName}`
            }
          />
        </Grid>
        <Grid item>
          <div className={classes.footerWrapper}>
            <div className={classes.timeInformationWrapper}>
              <div className={classes.date}>
                <AppointmentIcon
                  width={16}
                  height={16}
                  fill="#99A6B7"
                  className={classes.footerIcon}
                />
                <span>
                  {format(
                    new Date(appointment.datetime),
                    language === "en" ? "MM/dd/yyyy" : "dd.MM.yyyy",
                    {
                      locale: language === "en" ? englishLocale : germanLocale,
                    }
                  )}
                </span>
              </div>
              <div className={classes.time}>
                <ClockIcon
                  width={16}
                  height={16}
                  fill="#99A6B7"
                  className={classes.footerIcon}
                />
                <span>
                  {format(
                    new Date(appointment.datetime),
                    language === "en" ? "h:mm a" : "H:mm"
                  )}
                  {" - "}
                  {format(
                    addMinutes(
                      new Date(appointment.datetime),
                      parseInt(appointment.duration)
                    ),
                    language === "en" ? "h:mm a" : "H:mm"
                  )}
                </span>
              </div>
            </div>
            <Button
              variant="outlined"
              className={classes.addToCalendarButton}
              type="button"
              onClick={() => {
                window.open(appointment.calendarUrl, "_blank");
              }}
            >
              <CalendarAddIcon
                width={16}
                height={16}
                fill="#D23737"
                className={classes.footerIcon}
              />
              <FormattedMessage {...messages.addToCalendar} />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
