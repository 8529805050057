import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    addToCalendarButton: {
      color: theme.palette.primary.main,

      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
        width: "100%",
      },
    },
    bookedAppointmentWrapper: {
      background: "#fff",
      border: "1px solid #eee",
      boxSizing: "border-box",
      flexGrow: 1,
      marginBottom: "10px",
      padding: "10px",
      width: "100%",
    },
    bookingWrapper: {
      alignItems: "center",
      background: "#fafafa",
      display: "flex",
      flexDirection: "column",
      padding: "30px",
      [theme.breakpoints.down("md")]: {
        height: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "30px 0px",
      },
    },
    button: {
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        marginBottom: "10px",
      },
    },
    confirmationMessage: {
      alignItems: "center",
      background: "#46C79F",
      color: "white",
      display: "flex",
      height: "45px",
      justifyContent: "center",
      marginTop: "10px",
    },
    date: {
      alignItems: "center",
      display: "flex",
      marginRight: "16px",
    },
    footerIcon: {
      alignSelf: "center",
      marginRight: "8px",
    },
    footerWrapper: {
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    headerDescription: {
      color: "#6F7B8A",
    },
    headline: {
      color: "#303E4E",
      fontWeight: 700,
    },
    image: {
      color: "white",
      height: "16px",
      width: "16px",
    },
    newClassLink: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      width: "100%",
    },
    personDisplayWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
      },
    },
    root: {
      marginTop: "0px",
      [theme.breakpoints.down("sm")]: {
        overflow: "auto",
      },
    },
    rootPaper: {
      background: "#fafafa",
      display: "flex",
      justifyContent: "center",
      padding: "30px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        paddingBottom: "30px",
        paddingTop: "30px",
      },
    },
    rootWrapper: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      maxWidth: "1100px",
      overflow: "hidden",
      width: "70%",

      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    time: {
      alignItems: "center",
      display: "flex",
    },
    timeInformationWrapper: {
      color: "#99A6B7",
      display: "flex",
      marginTop: "10px",
    },
  })
);
