/*
 * DefaultLayout Messages
 *
 * This contains all the text for the DefaultLayout component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  blogLink: {
    defaultMessage: "Blog",
    id: "app.components.DefaultLayout.blogLink",
  },
  contactLink: {
    defaultMessage: "Contact",
    id: "app.components.DefaultLayout.contactLink",
  },
  dataProtectionLink: {
    defaultMessage: "Privacy Policy",
    id: "app.components.DefaultLayout.dataProtectionLink",
  },
  faqLink: {
    defaultMessage: "FAQ",
    id: "app.components.DefaultLayout.faqLink",
  },
  header: {
    defaultMessage: "This is the DefaultLayout component !",
    id: "app.components.DefaultLayout.header",
  },
  imprintLink: {
    defaultMessage: "Imprint",
    id: "app.components.DefaultLayout.imprintLink",
  },
  jobsLink: {
    defaultMessage: "Jobs",
    id: "app.components.DefaultLayout.jobsLink",
  },
  legalDisclaimerLink: {
    defaultMessage: "Legal disclaimer",
    id: "app.components.DefaultLayout.legalDisclaimerLink",
  },
  login: {
    defaultMessage: "Login",
    id: "app.components.DefaultLayout.login",
  },
  signUp: {
    defaultMessage: "Sign Up",
    id: "app.components.DefaultLayout.signUp",
  },
  termsAndConditionsLink: {
    defaultMessage: "AGB",
    id: "app.components.DefaultLayout.termsAndConditionsLink",
  },
  withdrawFromContractsLink: {
    defaultMessage: "Withdraw from Contracts",
    id: "app.components.DefaultLayout.withdrawFromContracts",
  },
});
