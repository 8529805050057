import React from "react";
import { useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";
import ResendCodePage from "containers/ResendCodePage";

import messages from "./messages";

const styles = (theme) => ({
  actionsContainer: {
    paddingTop: "25px",
    textAlign: "center",
    width: "100%",
  },
  emailSentImage: {
    display: "block",
    marginBottom: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    width: 100,
  },
  header: {
    marginBottom: "30px",
  },
  signupForm: {
    margin: "120px auto",
    maxWidth: "500px",
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  signUpLink: {
    color: theme.palette.primary.main,
    marginLeft: "5px",
    textDecoration: "none",
  },
  signupText: {
    marginTop: "25px",
    opacity: "0.75",
    textAlign: "center",
  },
});

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/* eslint-disable react/prefer-stateless-function */
const SignupSuccessPage = ({ classes }) => {
  const queryParams = useQueryParams();
  const email = queryParams.get("email");

  return (
    <Paper elevation={0} className={classes.signupForm}>
      <Typography variant="h6" component="h3" align="center">
        <FormattedMessage {...messages.confirmationHeader} />
      </Typography>
      <img
        src={`${process.env.PUBLIC_URL}/images/email-sent.svg`}
        alt="Verfication email sent!"
        className={classes.emailSentImage}
      />
      <Typography variant="body2" align="center">
        <FormattedMessage {...messages.confirmationText} />
      </Typography>
      <div className={classes.actionsContainer}>
        <ResendCodePage email={email} />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(SignupSuccessPage);
