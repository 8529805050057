import { defineMessages } from "react-intl";
import FormattedMessage from "components/FormattedMessage";
import { Plan } from "types/plan";

const messages = defineMessages({
  package: {
    defaultMessage:
      "Package {valueUnits, number}x{valueMinutesPerUnit, number} Minutes",
    id: "utils.plan.package",
  },
  subscription: {
    defaultMessage:
      "Abo {valueUnits, number}x{valueMinutesPerUnit, number} Minutes",
    id: "utils.plan.subscription",
  },
});

export const generatePlanLabel = (plan: Plan) =>
  plan.type === "subscription" ? (
    <FormattedMessage
      {...messages.subscription}
      values={{
        valueMinutesPerUnit: plan.valueMinutesPerUnit,
        valueUnits: plan.valueUnits,
      }}
    />
  ) : (
    <FormattedMessage
      {...messages.package}
      values={{
        valueMinutesPerUnit: plan.valueMinutesPerUnit,
        valueUnits: plan.valueUnits,
      }}
    />
  );
