import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actionBtn: {
    minWidth: "90px",

    [theme.breakpoints.down("xs")]: {
      "&:last-child": {
        marginBottom: "10px",
      },
    },
  },
  actionButton: {
    border: "1px solid #E4E4E4",
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  actionButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "space-between",
    marginTop: "20px",
    width: "100%",
  },
  avatarBorder: {
    background: "transparent",
    border: "1px solid #e4e4e4",
    borderRadius: "50%",
    height: "146px",
    position: "absolute",
    top: "-4px",
    width: "146px",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "200px",
  },
  calendarIcon: {
    alignSelf: "center",
    marginRight: "8px",
  },
  classTimeWrapper: {
    marginTop: "auto",
    padding: "10px",
  },
  confirmationMessage: {
    alignItems: "center",
    background: "#46C79F",
    color: "white",
    display: "flex",
    height: "33px",
    justifyContent: "center",
    marginTop: "10px",
    width: "100%",
  },
  contentWrapper: {
    background: "#fff",
    boxSizing: "border-box",
    padding: "10px",
    width: "auto",
  },
  date: {
    display: "flex",
    fontSize: "20px",
    fontWeight: "bold",
  },
  datePickerField: {
    margin: 0,
  },
  dateTimeWrapper: {
    display: "flex",
    marginTop: "20px",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  day: {
    color: "#99A6B7",
    fontSize: "12px",
  },
  endTime: {
    color: "#99A6B7",
    fontSize: "13px",
  },
  instrumentImage: {
    display: "inline-block",
    height: "60px",
    opacity: 0.1,
    position: "absolute",
    right: -7,
    top: 0,
    transform: "rotate(30deg)",

    [theme.breakpoints.down("xs")]: {
      height: "50px",
    },
  },
  instrumentTitle: {
    color: "#303E4E",
    fontSize: "26px",
  },
  root: {
    "&:last-child": {
      marginBottom: "0px",
    },
    background: "#fff",
    border: "1px solid #E4E4E4",
    borderRadius: "0px 6px 6px 0px",
    cursor: "pointer",
    display: "flex",
    marginBottom: "10px",
    overflow: "hidden",

    position: "relative",
  },
  select: {
    padding: "10px 7px 10px",
  },
  spinner: {
    height: "16px !important",
    width: "16px !important",
  },
  startTime: {
    color: "#303E4E",
    fontSize: "18px",
  },
  subjectAvatar: {
    fill: "#99A6B7",
    height: "38px",
    width: "38px",
  },
  subjectAvatarBorder: {
    background: "transparent",
    border: "1px solid #e4e4e4",
    borderRadius: "50%",
    height: "65px",
    position: "absolute",
    right: "-3px",
    top: "-3px",
    width: "65px",
  },
  subjectAvatarWrapper: {
    alignItems: "center",
    background: "#E4E4E4",
    borderRadius: "50%",
    display: "flex",
    height: "61px",
    justifyContent: "center",
    position: "absolute",
    right: "0px",
    top: "80px",
    width: "61px",
  },
  submitActions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  teacherAvatar: {
    borderRadius: "50%",
    height: "140px",
    width: "140px",
  },
  teacherField: {
    color: "#6F7B8A",
    fontSize: "14px",
    margin: 0,
  },
  teacherName: {
    color: "#303E4E",
    fontSize: "16px",
    margin: 0,
  },
  timeBox: {
    borderRight: "1px dotted rgb(226, 226, 226)",
    display: "flex",
    flex: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px",
    width: "90px",
  },
  timeField: {
    margin: "0",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
}));
