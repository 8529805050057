import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    "&:hover,&:focus, &:active": {
      cursor: "pointer",
      outline: `1px solid ${theme.palette.primary.main}`,
    },
    "&:last-child": {
      marginRight: "0px",
    },
    alignItems: "center",
    borderRadius: "6px",
    display: "flex",
    height: "47px",
    justifyContent: "center",
    minHeight: "50px",
    outline: "1px solid #eee",
    textAlign: "center",
    width: "30%",

    [theme.breakpoints.down("sm")]: {
      "&:last-child": {
        marginRight: "0px",
      },
      width: "32.5%",
    },

    [theme.breakpoints.down("xs")]: {
      "&:first-child": {
        marginTop: "0px",
      },
      marginTop: "10px",
      width: "100%",
    },
  },
  classDetails: {
    display: "flex",
    justifyContent: "space-around",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  classDetailsLeftCol: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "flex-start",
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "10px",
      width: "100%",
    },
  },
  classDetailsRightCol: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      paddingLeft: "0px",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  container: {
    border: "1px solid #eee",
    boxSizing: "border-box",
    color: "#303E4E",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    letterSpacing: "0px",
    padding: "20px",
    width: "100%",
  },
  dateAndTime: {
    marginTop: "20px",
  },
  datePickerField: {
    marginLeft: "0px",
    marginRight: "0px",
    minHeight: "50px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px",
      marginRight: "5px",
    },
  },
  datePickerRow: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
    margin: "10px 0px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  datePickerTitle: {
    color: "#99A6B7",
    fontSize: "12px",
    left: "37px !important",
    padding: "0px",
    top: "-13px !important",
  },
  dateSelection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  disabled: {
    color: "#99A6B7",
    cursor: "not-allowed",
  },
  durationSpinnerContainer: {
    alignItems: "center",
    display: "flex",
    height: "50px",
    justifyContent: "center",
    width: "100%",
  },
  errorMessage: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  formControlIcon: {
    alignSelf: "center",
    marginRight: theme.spacing(1),
    opacity: "0.5",
    width: theme.spacing(4),
  },
  formWrapper: {
    border: "1px solid #E4E4E4",
    borderRadius: 6,
    maxWidth: 860,
    padding: theme.spacing(3),
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "0px",
      width: "100%",
    },
  },
  headline: {
    color: "#303E4E",
    letterSpacing: "-0.26px",
    marginBottom: "10px",
  },
  iconWrapper: {
    padding: "0px 16px 0px 10px",
  },
  image: {
    "&.MuiAvatar-root": {
      height: "16px",
      opacity: "0.5",
      width: "16px",
    },
  },
  largeIconWrapper: {
    padding: "2px 9px 0px 2px",
  },
  largeImage: {
    "&.MuiAvatar-root": {
      borderRadius: "50%",
      height: "32px",
      width: "32px",
    },
  },
  numberField: {
    color: "#99A6B7",
  },
  rootWrapper: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    maxWidth: "1100px",
    width: "70%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  row: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  select: {
    padding: "10px 7px 10px",
  },
  selectedBox: {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  selectItemContent: {
    display: "flex",
    flexDirection: "column",
  },
  selectItemWrapper: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  spinner: {
    marginLeft: "15px",
  },
  subjectImage: {
    height: "16px",
    marginRight: "10px",
    minWidth: "16px",
    width: "16px",
  },
  teacherImage: {
    borderRadius: "50%",
    height: "32px",
    marginRight: "10px",
    minWidth: "32px",
    width: "32px",
  },
  teacherMenuItem: {
    display: "flex",
    paddingLeft: "10px",
  },
  teacherName: {
    alignSelf: "center",
  },
  teacherSelect: {
    margin: "0px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textField: {
    margin: "0px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  timeField: {
    marginRight: "0px",
    minHeight: "50px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  timePickerSpinner: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",

    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  title: {
    color: "#99A6B7",
    fontSize: "12px",
    padding: "0px",
  },
  value: {
    color: "#303E4E",
    letterSpacing: "0px",
    opacity: 1,
  },
}));
export default useStyles;
