import { FormattedMessage } from "react-intl";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { useArchivedCategories } from "services/mms";
import { useCurrentUser } from "services/user";

import messages from "./messages";

const styles = (theme) => ({
  archiveFilters: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("xs")]: {
      alignSelf: "auto",
      flexDirection: "column",
    },
  },
  archiveTitle: {
    alignSelf: "center",
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      alignSelf: "flex-start",
    },
  },
  archiveTitleText: {
    color: "#303e4e",
  },
  filter1: {
    padding: "0px 40px 0px 15px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    padding: "20px 0px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "20px 0px 10px 0px",
    },
  },
  select: {
    height: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      width: "100%",
    },
  },
  subjectFilter: {
    margin: "0px 10px",

    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
  },
});

const ArchiveHeader = ({
  classes,
  selectedCategory,
  selectedTimeFilter,
  onChangeCategory,
  onChangeTimeFilter,
}) => {
  const { data: user } = useCurrentUser();
  const { categories } = useArchivedCategories(user);
  const renderCategories = () => {
    const data = [
      <FormattedMessage {...messages.all} key="-1">
        {(message) => <option value="">{message}</option>}
      </FormattedMessage>,
    ];

    if (categories) {
      Array.from(categories).forEach((item) => {
        data.push(
          <option value={item} key={item}>
            {item}
          </option>
        );
      });
    }

    return data;
  };

  const getTimeFilters = () => {
    const data = [
      <FormattedMessage {...messages.timeFilter} values={{ days: 30 }} key="0">
        {(txt) => <option value="30">{txt}</option>}
      </FormattedMessage>,
      <FormattedMessage {...messages.timeFilter} values={{ days: 90 }} key="1">
        {(txt) => <option value="90">{txt}</option>}
      </FormattedMessage>,
      <FormattedMessage {...messages.timeFilterAll} key="2">
        {(txt) => <option value="all">{txt}</option>}
      </FormattedMessage>,
    ];

    return data;
  };

  return (
    <div className={classes.header}>
      <div className={classes.archiveTitle}>
        <Typography variant="h5" className={classes.archiveTitleText}>
          <FormattedMessage {...messages.archive} />
        </Typography>
      </div>
      <div className={classes.archiveFilters}>
        <div className={classes.subjectFilter}>
          <NativeSelect
            value={selectedCategory}
            onChange={onChangeCategory}
            input={
              <Input
                name="type"
                id="categoryType"
                classes={{ input: classes.filter1 }}
              />
            }
            className={classes.select}
          >
            {renderCategories()}
          </NativeSelect>
        </div>
        <div>
          <NativeSelect
            value={selectedTimeFilter}
            onChange={onChangeTimeFilter}
            input={
              <Input
                name="timeFilterName"
                id="timeFilterType"
                classes={{ input: classes.filter1 }}
              />
            }
            className={classes.select}
          >
            {getTimeFilters()}
          </NativeSelect>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ArchiveHeader);
