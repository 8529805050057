import { Link } from "react-router-dom";
import { CircularProgress, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useMutation } from "@tanstack/react-query";
import FormattedMessage from "components/FormattedMessage";
import SocialAuthButton from "components/SocialAuthButton";
import { useFormik } from "formik";
import { authService } from "services/user";
import * as Yup from "yup";

import messages from "./messages";
import { useStyles } from "./styles";

const LoginForm = () => {
  const classes = useStyles();
  const {
    mutate: signIn,
    isError,
    isLoading,
  } = useMutation(
    async ({ email, password }: { email: string; password: string }) =>
      await authService.signIn(email, password)
  );

  const formik = useFormik({
    initialErrors: {
      email: "",
    },
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ email, password }) => {
      signIn({ email, password });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/.+@.+/, messages.emailFormat.id)
        .required(messages.fieldRequired.id),
      password: Yup.string().required(messages.fieldRequired.id),
    }),
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid
          item
          container
          xs={12}
          // Set textFieldWrapper class to increase specificity in CSS
          className={`${classes.textFieldWrapper} textFieldWrapper`}
        >
          <Grid item xs={12}>
            <TextField
              autoComplete="username"
              className={classes.textField}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email &&
                Boolean(formik.errors.email) && (
                  <FormattedMessage id={formik.errors.email} />
                )
              }
              id="email"
              label={<FormattedMessage {...messages.email} />}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="current-password"
              className={classes.textField}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={
                formik.touched.password &&
                Boolean(formik.errors.password) && (
                  <FormattedMessage id={formik.errors.password} />
                )
              }
              id="password"
              label={<FormattedMessage {...messages.password} />}
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
          </Grid>
          {isError && (
            <Typography variant="body1" color="error">
              <FormattedMessage {...messages.invalidLogin} />
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading || !formik.isValid}
            startIcon={isLoading && <CircularProgress size={12} />}
          >
            <FormattedMessage {...messages.loginBtn} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            <FormattedMessage {...messages.or} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SocialAuthButton
            iconName="facebook"
            background="#3B7AE3"
            message={messages.loginWithFacebookText}
          />
        </Grid>
        <Grid item xs={12}>
          <SocialAuthButton
            iconName="google"
            background="#fff"
            color="#000"
            message={messages.loginWithGoogleText}
          />
        </Grid>
        <Grid item xs={12}>
          <SocialAuthButton
            iconName="apple"
            background="#000"
            message={messages.loginWithAppleText}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.actionText}>
            <FormattedMessage {...messages.accountHint} />{" "}
            <Link to="/signup" href="/signup" className={classes.actionLink}>
              <FormattedMessage {...messages.signupNow} />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.actionText}>
            <FormattedMessage {...messages.forgotPassword} />{" "}
            <Link
              to="/forgotPassword"
              href="/forgotPassword"
              className={classes.actionLink}
            >
              <FormattedMessage {...messages.resetPassword} />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
