import { Auth } from "@aws-amplify/auth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import * as authService from "./service";

export interface User {
  accessToken: string;
  cognitoUser: any;
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  groups: string[];
  idToken: string;
  impersonateEmail: string | undefined;
  language: "de" | "en";
  phoneNumber: string;
  phoneNumberVerified: boolean;
  picture: string | undefined;
  preferredDuration: number;
  preferredSubjectIds: number[];
  preferredTeacherIds: number[];
  sendEmail: boolean;
  sendPushMsgs: boolean;
}

export let impersonateEmail = undefined;

export const useCurrentUser = () => {
  const queryClient = useQueryClient();
  const queryKey = ["user", "current"];

  const query = useQuery(
    queryKey,
    async () => {
      const user = await authService.getCurrentUser();

      return {
        accessToken: user.signInUserSession.accessToken.jwtToken,
        cognitoUser: user,
        email: user.attributes.email,
        emailVerified: user.attributes.email_verified,
        familyName: user.attributes.family_name,
        givenName: user.attributes.given_name,
        groups: user.signInUserSession.accessToken.payload["cognito:groups"],
        idToken: user.signInUserSession.idToken.jwtToken,
        language: user.attributes["custom:language"],
        phoneNumber: user.attributes.phone_number,
        phoneNumberVerified: user.attributes.phone_number_verified,
        picture: user.attributes.picture,
        preferredDuration:
          parseInt(user.attributes["custom:preferredDuration"]) || 45,
        preferredSubjectIds:
          user.attributes["custom:preferredSubjectIds"]
            ?.split(",")
            .map((value) => parseInt(value)) || [],
        preferredTeacherIds:
          user.attributes["custom:preferredTeacherIds"]
            ?.split(",")
            .map((value) => parseInt(value)) || [],
        sendEmail: user.attributes["custom:sendEmail"] === "1",
        sendPushMsgs: user.attributes["custom:sendPushMsgs"] === "1",
      } as User;
    },
    {
      onError(err) {
        console.error(err);
        queryClient.setQueryData(queryKey, null);
        queryClient.invalidateQueries({ queryKey });
      },
      staleTime: 60 * 30,
    }
  );

  return query;
};

export const useUpdateUserAttributes = () => {
  const { data: user } = useCurrentUser();
  const queryClient = useQueryClient();

  return useMutation(
    async (attributes: Partial<User>) => {
      const mappedAttribtes = {};

      Object.entries(attributes).forEach(([key, value]) => {
        switch (key) {
          case "language":
            mappedAttribtes["custom:language"] = value;
            break;
          case "sendEmail":
            mappedAttribtes["custom:sendEmail"] = value ? "1" : "0";
            break;
          case "sendPushMsgs":
            mappedAttribtes["custom:sendPushMsgs"] = value ? "1" : "0";
            break;
          case "lastName":
            mappedAttribtes["family_name"] = value;
            break;
          case "firstName":
            mappedAttribtes["given_name"] = value;
            break;
          case "phoneNumber":
            mappedAttribtes["phone_number"] = value;
            break;
          case "preferredDuration":
            mappedAttribtes["custom:preferredDuration"] = value.toString();
            break;
          case "preferredSubjectIds":
            mappedAttribtes["custom:preferredSubjectIds"] = value.join(",");
            break;
          case "preferredTeacherIds":
            mappedAttribtes["custom:preferredTeacherIds"] = value.join(",");
            break;
        }
      });
      return await Auth.updateUserAttributes(
        user?.cognitoUser,
        mappedAttribtes
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", "current"]);
      },
    }
  );
};
