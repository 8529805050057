import { defineMessages } from "react-intl";

export default defineMessages({
  bookNowBtn: {
    defaultMessage: "Book Now",
    id: "app.containers.LiveLessonPage.bookNowBtn",
  },
  connectNowBtn: {
    defaultMessage: "Connect Now",
    id: "app.containers.LiveLessonPage.connectNowBtn",
  },
  futureLessonMsg: {
    defaultMessage:
      "Your next lesson: {subject} with {teacherName} is on {date}",
    id: "app.containers.LiveLessonPage.futureLessonMsg",
  },
  header: {
    defaultMessage: "This is LiveLessonPage container !",
    id: "app.containers.LiveLessonPage.header",
  },
  liveLessonMsg: {
    defaultMessage:
      "Your ongoing lesson: {subject} with {teacherName} is on {date}",
    id: "app.containers.LiveLessonPage.liveLessonMsg",
  },
  noAppointmentsMsg: {
    defaultMessage: "You have no live lessons scheduled",
    id: "app.containers.LiveLessonPage.noAppointmentsMsg",
  },
});
