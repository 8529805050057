import { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import settings from "settings";
import { Plan } from "types/plan";

import messages from "./messages";

import useStyles from "./PlanOrderCard.styles";

interface PlanOrderCardProps {
  plan: Plan;
}

export const PlanOrderCard: FC<PlanOrderCardProps> = () => {
  const classes = useStyles();

  const onClick = () => {
    window.open(settings.MMS_PRICING_URL());
  };

  return (
    <Card elevation={1} className={classes.root} onClick={onClick}>
      <Box className={classes.icon}>
        <AddIcon />
      </Box>
      <CardActionArea className={classes.actionArea}>
        <CardHeader title={<FormattedMessage {...messages.bookNewTitle} />} />

        <CardContent className={classes.content}>
          <Typography variant="body1" className={classes.details}>
            <FormattedMessage {...messages.bookCallToAction} />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
