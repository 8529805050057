import React from "react";
import { FormattedMessage as IntlFormattedMessage } from "react-intl";

import messages from "./messages";

const FormattedMessage = ({ id, ...rest }) => {
  if (!id) {
    // console.info("There is a missing translation");
  }
  return id ? (
    <IntlFormattedMessage id={id} {...rest} />
  ) : (
    <IntlFormattedMessage {...messages.Unknown} />
  );
};

export default FormattedMessage;
