import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";
import RecurringAppointmentSection from "containers/BookingPage/RecurringAppointmentSection";
import * as mmsService from "services/mms";
import { useAvailableTimes, useBookAppointments, usePlans } from "services/mms";
import { useCurrentUser, useUpdateUserAttributes } from "services/user";
import { generatePlanLabel } from "utils/plan";

import Classes from "./Classes";
import Header from "./Header";
import messages from "./messages";
import SubscriptionItem from "./SubscriptionItem";

const styles = (theme) => ({
  bookingWrapper: {
    alignItems: "center",
    background: "#fafafa",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0px",
    },
  },
  button: {
    margin: "0px",
    width: "100%",
  },
  container: {
    background: "white",
    border: "1px solid #eee",
    boxSizing: "border-box",
    color: "#303E4E",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    letterSpacing: "0px",
    padding: "20px",
    width: "100%",
  },
  formControlIcon: {
    alignSelf: "center",
    marginRight: theme.spacing(1),
    opacity: "0.5",
    width: theme.spacing(4),
  },
  getAboButton: {
    marginTop: "20px",
  },
  headerDescription: {
    color: "#6F7B8A",
  },
  lessons: {
    color: theme.palette.primary.main,
  },
  muiSelect: {
    // boxShadow: '0px 3px 6px #00000029',
    "&:hover,&:focus, &:active": {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "3px",
    },

    background: "#FAFAFA 0% 0% no-repeat padding-box",

    height: "70px",

    marginTop: "10px",

    width: "100%",
  },
  newAboWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    textAlign: "center",
  },
  root: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      // overflow: "auto",
    },
  },
  rootPaper: {
    background: "#fafafa",
    display: "flex",
    // height: "100%",
    justifyContent: "center",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      // height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      paddingBottom: "30px",
      paddingTop: "30px",
    },
  },
  rootWrapper: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1100px",
    width: "70%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  select: {
    padding: "10px 7px 10px",
  },
  selectItemWithIconWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0px 0px 10px 10px",
  },
  spinner: {
    marginLeft: "20px",
  },
  submit: {
    margin: "20px 0px",
  },
  submittingError: {
    color: theme.palette.primary.main,
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
  },
  textField: {
    margin: "5px 0px",
    width: "100%",
  },
  title: {
    color: "#303E4E",
    fontWeight: "700",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px 10px 10px",
  },
});

const BookingPage = ({ classes }) => {
  const history = useHistory();
  const { data: user } = useCurrentUser();
  const { data: plans, isLoading: plansAreLoading } = usePlans();

  const [certificateId, setCertificateId] = useState();
  const [allowedDurations, setAllowedDurations] = useState();
  const [repetition, setRepetition] = useState("weekly");
  const [amountRepetitions, setAmountRepetitions] = useState(2);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [availabilityWasChecked, setAvailabilityWasChecked] = useState(false);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [checkAvailabilityIsLoading, setCheckAvailabilityIsLoading] =
    useState(false);

  const [showRecurringAppointmentSection, setShowRecurringAppointmentSection] =
    useState(false);

  const [subjectId, setSubjectId] = useState(user.preferredSubjectIds?.[0]);
  const [teacherId, setTeacherId] = useState(user.preferredTeacherIds?.[0]);
  const [duration, setDuration] = useState(user.preferredDuration);

  const { mutate: updateUserAttributes } = useUpdateUserAttributes();

  const { data: availableTimes } = useAvailableTimes({
    date,
    minutes: duration,
    teacherId: teacherId,
  });

  useEffect(() => {
    // Set first certificate as default certificate, once certificates are available
    setCertificateId(plans?.defaultId);
  }, [plans]);

  useEffect(() => {
    if (allowedDurations?.indexOf(duration) <= 0) {
      setDuration(allowedDurations[0]);
    }
  }, [allowedDurations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Set allowed duration to allowed durations saved in current certificate
    if (certificateId && plans?.all.length > 0) {
      const selectedCertificate = plans.all.find(
        (plan) => plan.id === certificateId
      );
      setAllowedDurations(selectedCertificate.allowedDurations);
    }
  }, [certificateId, plans]);

  useEffect(() => {
    setAvailabilityWasChecked(false);
  }, [
    teacherId,
    subjectId,
    duration,
    date,
    time,
    amountRepetitions,
    repetition,
    setAvailabilityWasChecked,
  ]);

  const checkAvailability = async () => {
    if (
      duration &&
      date &&
      time &&
      teacherId &&
      repetition &&
      amountRepetitions
    ) {
      setCheckAvailabilityIsLoading(true);
      try {
        const dateTime = availableTimes.find(
          (timeItem) => timeItem.display === time
        );

        // TODO fix timezone issue
        const availableTimeSlots = await mmsService.checkTimes({
          dateTime: dateTime.value,
          endsAfter: amountRepetitions,
          minutes: duration,
          repetition,
          teacherId: teacherId,
          user,
        });

        setAvailabilityWasChecked(true);
        setAvailableTimeSlots(availableTimeSlots);
      } catch (error) {
        console.log(error);
      } finally {
        setCheckAvailabilityIsLoading(false);
      }
    }
  };

  const removeAvailableTimeSlot = (datetime) => {
    const items = availableTimeSlots.filter(
      (item) => item.datetime !== datetime
    );
    setAvailableTimeSlots(items);
  };

  const {
    mutate: bookAppointments,
    isSubmitting,
    isError: submittingError,
  } = useBookAppointments({
    onSuccess: (bookingResults) => {
      // update user preferences regarding subject, teacher and duration
      const preferredTeacherIds = [
        ...new Set([teacherId, ...user.preferredTeacherIds]),
      ];
      const preferredSubjectIds = [
        ...new Set([subjectId, ...user.preferredSubjectIds]),
      ];
      const preferredDuration = duration;
      updateUserAttributes({
        preferredDuration,
        preferredSubjectIds,
        preferredTeacherIds,
      });

      history.push("/appointments/confirmation", {
        appointments: bookingResults,
      });
    },
  });

  const onSubmit = () => {
    const firstAppointmentDate = new Date(date);
    const hoursAndMinutes = time.split(":");
    firstAppointmentDate.setHours(
      parseInt(hoursAndMinutes[0]) +
        parseInt(time.indexOf("PM") !== -1 ? 12 : 0)
    );
    firstAppointmentDate.setMinutes(hoursAndMinutes[1].split(" ")[0]);

    const certificateObject = plans.all.find(
      (plan) => plan.id === certificateId
    );

    const appointments =
      availableTimeSlots.length > 0
        ? availableTimeSlots.map((timeslot) => ({
            certificate: certificateObject.certificate,
            datetime: timeslot.datetime,
            minutes: duration,
            teacherId,
          }))
        : [
            {
              certificate: certificateObject.certificate,
              datetime: firstAppointmentDate.toISOString(),
              minutes: duration,
              teacherId,
            },
          ];
    bookAppointments({ appointments });
  };

  const disableSubmit =
    !subjectId ||
    !teacherId ||
    !duration ||
    !time ||
    !date ||
    (showRecurringAppointmentSection
      ? !repetition || !amountRepetitions || !availabilityWasChecked
      : false) ||
    isSubmitting;

  return (
    <div className={classes.root}>
      {!!certificateId && (
        <Paper className={classes.rootPaper} elevation={0} square>
          <div className={classes.rootWrapper}>
            <Header />
          </div>
        </Paper>
      )}
      {plansAreLoading && (
        <Paper className={classes.rootPaper} elevation={0} square>
          <CircularProgress
            className={classes.spinner}
            size={40}
            color="primary"
          />
        </Paper>
      )}
      {plans && certificateId && (
        <Paper className={classes.bookingWrapper} elevation={0} square>
          <div className={classes.rootWrapper}>
            <div className={classes.container}>
              <Typography variant="body1" className={classes.headerDescription}>
                <FormattedMessage {...messages.subscriptionTitle} />
              </Typography>
              <FormControl className={classes.textField}>
                <Select
                  value={certificateId}
                  displayEmpty={true}
                  onChange={(event) => {
                    setCertificateId(event.target.value);
                  }}
                  className={classes.muiSelect}
                  classes={{ select: classes.select }}
                  renderValue={() => {
                    const selectedPlan = plans.all.find(
                      (plan) => plan.id === certificateId
                    );
                    return certificateId ? (
                      <SubscriptionItem
                        title={generatePlanLabel(selectedPlan)}
                        remainingMinutes={selectedPlan.remainingBalanceMinutes}
                      />
                    ) : (
                      <CircularProgress
                        className={classes.spinner}
                        size={20}
                        color="primary"
                      />
                    );
                  }}
                >
                  {plans?.all.map((plan) => (
                    <MenuItem
                      key={plan.id}
                      value={plan.id}
                      classes={{ root: classes.selectItem }}
                    >
                      <span className={classes.subscriptionItem}>
                        {generatePlanLabel(plan)}{" "}
                        <FormattedMessage
                          {...messages.remaining}
                          values={{ minutes: plan.remainingBalanceMinutes }}
                        />
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <Classes
            allowedDurations={allowedDurations}
            setDuration={setDuration}
            duration={duration}
            date={date}
            time={time}
            setDate={setDate}
            setTime={setTime}
            teacherId={teacherId}
            subjectId={subjectId}
            setTeacherId={setTeacherId}
            setSubjectId={setSubjectId}
          />
          <RecurringAppointmentSection
            repetition={repetition}
            setRepetition={setRepetition}
            showRecurringAppointmentSection={showRecurringAppointmentSection}
            setShowRecurringAppointmentSection={
              setShowRecurringAppointmentSection
            }
            amountRepetitions={amountRepetitions}
            setAmountRepetitions={setAmountRepetitions}
            checkAvailability={checkAvailability}
            availableTimeSlots={availableTimeSlots}
            checkAvailabilityIsLoading={checkAvailabilityIsLoading}
            classesFormIsValid={duration && date && time}
            removeAvailableTimeSlot={removeAvailableTimeSlot}
            time={time}
            availabilityWasChecked={availabilityWasChecked}
          />
          <div className={`${classes.rootWrapper} ${classes.submit}`}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              onClick={onSubmit}
              disabled={disableSubmit}
            >
              {isSubmitting ? (
                <CircularProgress
                  className={classes.spinner}
                  size={20}
                  color="white"
                />
              ) : (
                <FormattedMessage {...messages.bookAppointment} />
              )}
            </Button>
            {submittingError && (
              <span className={classes.submittingError}>
                We are sorry, something went wrong.
              </span>
            )}
          </div>
        </Paper>
      )}
      {!plans?.all.length && !plansAreLoading && (
        <Paper className={classes.rootPaper} elevation={0} square>
          <div className={classes.newAboWrapper}>
            <Typography className={classes.text}>
              <FormattedMessage {...messages.outOfCredits} />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.getAboButton}
              onClick={() => {
                window.open(
                  "http://www.mymusicschool.com/unterricht-buchen",
                  "_blank"
                );
              }}
            >
              <FormattedMessage {...messages.getAbonnenment} />
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default withStyles(styles)(BookingPage);
