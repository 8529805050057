import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";
import MouseOverPopover from "components/MouseOverPopover";
import { ReactComponent as AppointmentIcon } from "components/svg/calendar.svg";
import { useCurrentLanguage } from "containers/LanguageProvider";
import { format } from "date-fns";
import germanLocale from "date-fns/locale/de";

import messages from "./messages";
import useStyles from "./styles";

const useCustomStyles = makeStyles((theme) => ({
  checkAvailabilityButton: {
    alignSelf: "flex-start",
    background: "white",
    minWidth: "200px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  checkAvailabilityWrapper: {
    display: "flex",
    marginTop: "10px",

    [theme.breakpoints.down("xs")]: {
      display: "column",
      flexDirection: "column-reverse",
    },
  },
  checkAvailabilityWrapperMultipleElements: {
    justifyContent: "space-between",
  },
  checkAvailabilityWrapperOneElement: {
    justifyContent: "flex-end",
  },
  container: {
    borderBottom: "1px solid #eee",
    borderLeft: "1px solid #eee",
    borderRight: "1px solid #eee",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
  },
  formControl: {
    display: "flex",
    textAlign: "center",
    width: "100%",
  },
  formControlIcon: {
    alignSelf: "center",
    color: "#99A6B7",
    fontSize: "16px",
    marginRight: theme.spacing(1),
    opacity: "0.5",
  },
  formWrapper: {
    border: "1px solid #E4E4E4",
    borderRadius: 6,
    maxWidth: 860,
    padding: theme.spacing(3),
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "0px",
      width: "100%",
    },
  },
  headline: {
    alignSelf: "center",
  },
  headlineWrapper: {
    background: "#F5F6F7 0% 0% no-repeat padding-box",
    border: "1px solid #E4E4E4",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    margin: "0px",
    padding: "10px 20px",
    width: "100%",
  },
  icon: {
    height: "16px",
    width: "16px",
  },
  invalidTimeslotItem: {
    color: theme.palette.primary.main,
  },
  numberField: {
    marginLeft: theme.spacing(1),
    marginRight: "0px",
    width: "200px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  removeTimeslot: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  rootWrapper: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    maxWidth: "1100px",
    width: "70%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  select: {
    padding: "10px 7px 10px",
  },
  selectItemContent: {
    display: "flex",
    flexDirection: "column",
  },
  selectItemWrapper: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  spinner: {
    alignSelf: "center",

    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: "0px",
    width: "100%",
  },
  timeslotItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 5px 5px 0px",
  },
  timeslotItemsWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 10px 0px",
    width: "300px",

    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 0px",
      width: "100%",
    },
  },
  title: {
    color: "#99A6B7",
    fontSize: "12px",
    padding: "0px",
  },
  value: {
    color: "#303E4E",
    letterSpacing: "0px",
  },
}));

const RecurringAppointmentSection = ({
  repetition,
  setRepetition,
  amountRepetitions,
  setAmountRepetitions,
  showRecurringAppointmentSection,
  setShowRecurringAppointmentSection,
  checkAvailability,
  checkAvailabilityIsLoading,
  availableTimeSlots,
  classesFormIsValid,
  removeAvailableTimeSlot,
  time,
}) => {
  const { language } = useCurrentLanguage();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const classes = {
    ...useStyles(),
    ...useCustomStyles(),
  };
  return (
    <Paper elevation={0} square className={classes.rootWrapper}>
      <div className={classes.headlineWrapper}>
        <Typography variant="body1" className={classes.headline}>
          <FormattedMessage {...messages.regularAppointmentTitle} />
        </Typography>
        <Switch
          checked={showRecurringAppointmentSection}
          value="sendEmail"
          onChange={() =>
            setShowRecurringAppointmentSection(!showRecurringAppointmentSection)
          }
        />
      </div>
      {showRecurringAppointmentSection && (
        <div className={classes.container} noValidate autoComplete="off">
          <div className={classes.formControl}>
            <div className={classes.formControlIcon}>
              <AppointmentIcon
                width={16}
                height={16}
                fill="#99A6B7"
                className={classes.icon}
              />
            </div>
            <FormControl className={classes.textField}>
              <Select
                value={repetition}
                onChange={(event) => setRepetition(event.target.value)}
                classes={{ select: classes.select }}
                renderValue={(selectValue) => (
                  <SelectItem
                    classes={classes}
                    value={<FormattedMessage {...messages[selectValue]} />}
                    id="repition"
                    titleKey="regularAppointmentRepetion"
                  />
                )}
              >
                <MenuItem value="weekly">
                  <FormattedMessage {...messages.weekly} />
                </MenuItem>
                <MenuItem value="twoWeekly">
                  <FormattedMessage {...messages.twoWeekly} />
                </MenuItem>
                <MenuItem value="threeWeekly">
                  <FormattedMessage {...messages.threeWeekly} />
                </MenuItem>
                <MenuItem value="fourWeekly">
                  <FormattedMessage {...messages.fourWeekly} />
                </MenuItem>
                <MenuItem value="monthly">
                  <FormattedMessage {...messages.monthly} />
                </MenuItem>
                <MenuItem value="daily">
                  <FormattedMessage {...messages.daily} />
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.formControl}>
            <div className={classes.formControlIcon}>
              <img
                className={classes.icon}
                src="https://s3.eu-central-1.amazonaws.com/mymusicschool-assets/subject-icons/recycle.svg"
                alt="My Music School"
              />
            </div>
            <TextField
              type="number"
              id="amountRepetitions"
              name="amountRepetitions"
              variant="filled"
              label={<FormattedMessage {...messages.amountRepetitions} />}
              value={amountRepetitions}
              onChange={(event) => setAmountRepetitions(event.target.value)}
              className={classes.numberField}
              inputProps={{
                max: 99,
                min: 2,
              }}
            />
          </div>
          <div
            className={`${classes.checkAvailabilityWrapper} ${
              checkAvailabilityIsLoading || availableTimeSlots.length > 0
                ? classes.checkAvailabilityWrapperMultipleElements
                : classes.checkAvailabilityWrapperOneElement
            }`}
          >
            {checkAvailabilityIsLoading ? (
              <CircularProgress
                className={classes.spinner}
                size={20}
                color="primary"
              />
            ) : (
              <div className={classes.timeslotItemsWrapper}>
                {availableTimeSlots.map((item) => (
                  <div className={classes.timeslotItem} key={item.datetime}>
                    <span
                      className={!item.valid ? classes.invalidTimeslotItem : ""}
                      aria-owns={!!anchorEl ? "mouse-over-popover" : undefined}
                      aria-haspopup={!!anchorEl}
                      onMouseEnter={!item.valid ? handlePopoverOpen : () => {}}
                      onMouseLeave={!!anchorEl ? handlePopoverClose : () => {}}
                    >
                      {format(
                        new Date(item.datetime),
                        language === "en"
                          ? "EEEE LLL d yyyy hh:mm a"
                          : "EEEE d. LLL yyyy kk:mm",
                        {
                          locale: language === "en" ? "" : germanLocale,
                        }
                      )}
                    </span>
                    <span
                      className={classes.removeTimeslot}
                      onClick={() => {
                        removeAvailableTimeSlot(item.datetime);
                      }}
                    >
                      <FormattedMessage {...messages.removeTimeslot} />
                    </span>
                    {!item.valid && (
                      <MouseOverPopover
                        anchorEl={anchorEl}
                        handlePopoverClose={handlePopoverClose}
                      >
                        <FormattedMessage {...messages.appointmentConflict} />
                      </MouseOverPopover>
                    )}
                  </div>
                ))}
              </div>
            )}
            <Button
              variant="outlined"
              component="span"
              className={classes.checkAvailabilityButton}
              type="button"
              disabled={!amountRepetitions || !classesFormIsValid || !time}
              onClick={checkAvailability}
            >
              <FormattedMessage {...messages.checkAvailability} />
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

const SelectItem = ({ classes, value, titleKey, id }) => (
  <div className={classes.selectItemWrapper}>
    <div className={classes.selectItemContent}>
      <InputLabel className={classes.title}>
        <FormattedMessage {...messages[titleKey]} />
      </InputLabel>
      <Typography variant="body1" className={classes.value}>
        {value}
      </Typography>
    </div>
  </div>
);

export default RecurringAppointmentSection;
