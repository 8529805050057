import { defineMessages } from "react-intl";

export default defineMessages({
  all: {
    defaultMessage: "All",
    id: "app.containers.ClassesPage.all",
  },
  archive: {
    defaultMessage: "Archive",
    id: "app.containers.ClassesPage.archive",
  },
  bookAppointment: {
    defaultMessage:
      "To book an appointment click on the button below and fill out the form.",
    id: "app.containers.ClassesPage.bookAppointment",
  },
  classes: {
    defaultMessage: "Appointments",
    id: "app.containers.ClassesPage.classes",
  },
  header: {
    defaultMessage: "Upcoming Classes",
    id: "app.containers.ClassesPage.header",
  },
  newClass: {
    defaultMessage: "New Class",
    id: "app.components.UpcomingClass.newClass",
  },
  newClassBtn: {
    defaultMessage: "New Class",
    id: "app.containers.ClassesPage.newClassBtn",
  },
  noAppointmentsAvailable: {
    defaultMessage: "There are no appointments available at this time.",
    id: "app.containers.ClassesPage.noAppointmentsAvailable",
  },
  noAppointmentsScheduled: {
    defaultMessage: "No Scheduled Appointments",
    id: "app.containers.ClassesPage.noAppointmentsScheduled",
  },
  showMore: {
    defaultMessage: "Show More",
    id: "app.containers.ClassesPage.showMore",
  },
  timeFilter: {
    defaultMessage: "Last {days, number} days",
    id: "app.containers.ClassesPage.timeFilter",
  },
  timeFilterAll: {
    defaultMessage: "All times",
    id: "app.containers.ClassesPage.timeFilterAll",
  },
});
