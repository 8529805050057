import { defineMessages } from "react-intl";

export default defineMessages({
  nextBillingOn: {
    defaultMessage:
      "Next billing over <b>{price, number, ::currency/EUR}</b> on <b>{billingDate, date, numeric}</b>",
    id: "app.components.PlanOverviewCard.nextBillingOn",
  },
  purchaseInfo: {
    defaultMessage:
      "Bought on <b>{purchaseDate, date, numeric}</b> for <b>{price, number, ::currency/EUR}</b>",
    id: "app.components.PlanOverviewCard.purchaseInfo",
  },
  recurrenceNumMonths: {
    defaultMessage: "Billing every {numMonths, number} months",
    id: "app.components.PlanOverviewCard.recurrenceNumMonths",
  },
  recurrenceNumWeeks: {
    defaultMessage: "Billing every {numWeeks, number} weeks",
    id: "app.components.PlanOverviewCard.recurrenceNumWeeks",
  },
  timeBudget: {
    defaultMessage:
      "<b>{remainingBalanceMinutes, number}</b> minutes remaining",
    id: "app.components.PlanOverviewCard.timeBudget",
  },
  validUntil: {
    defaultMessage: "Valid until {expirationDate, date, numeric}",
    id: "app.components.PlanOverviewCard.validUntil",
  },
});
