import MUIDrawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as ArrowLeftIcon } from "components/svg/left-arrow.svg";

const useStyles = makeStyles((theme) => ({
  arrowLeftIcon: {
    cursor: "pointer",
    height: "20px",
    marginRight: "10px",
    width: "20px",

    /*[theme.breakpoints.down('xs')]: {
      width: '32px',
      height: '32px',
    },*/
  },
  closeIcon: {
    cursor: "pointer",
    height: "20px",
    position: "absolute",

    right: "10px",
    top: "10px",
    width: "20px",

    /*    [theme.breakpoints.down('xs')]: {
      width: '32px',
      height: '32px',
    },*/
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  drawer: {
    boxSizing: "border-box",
    padding: "20px 10px",
    width: "515px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  drawerRoot: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerLeftColumn: {
    display: "flex",
  },
}));

const Drawer = ({
  setIsDrawerOpen,
  isOpen,
  children,
  title,
  headerTopRightContent,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <MUIDrawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={(e) => {
        setIsDrawerOpen(false);
        e.stopPropagation();
        onClose();
      }}
      classes={{
        paper: classes.drawer,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className={classes.drawerRoot}>
        <div className={classes.header}>
          <div className={classes.headerLeftColumn}>
            <ArrowLeftIcon
              className={classes.arrowLeftIcon}
              onClick={(e) => {
                setIsDrawerOpen(false);
                e.stopPropagation();
                onClose();
              }}
            />
            <div>{title}</div>
          </div>
          {headerTopRightContent}
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </MUIDrawer>
  );
};

export default Drawer;
