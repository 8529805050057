import React from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #eee",
    boxShadow: "none",
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: "none",
  },
}));

const MouseOverPopover = ({ anchorEl, handlePopoverClose, children }) => {
  const classes = useStyles();

  return (
    <>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{children}</Typography>
      </Popover>
    </>
  );
};

export default MouseOverPopover;
