import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { useQueryClient } from "@tanstack/react-query";
import FormattedMessage from "components/FormattedMessage";
import ImpersonateUserForm from "components/ImpersonateUserForm";
import ProfileForm from "components/ProfileForm";
import ProfilePhoto from "components/ProfilePhoto";
import { styles } from "containers/ProfilePage/styles";
import * as storageService from "services/storage";
import { authService, useCurrentUser } from "services/user";

import messages from "./messages";

/* eslint-disable react/prefer-stateless-function */
const ProfilePage = ({ classes }) => {
  const [isUploadingPicture, setIsUploadingPicture] = useState(false);
  const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState();
  const queryClient = useQueryClient();

  const { data: user } = useCurrentUser();
  const isAdmin = !!user?.groups && user.groups.indexOf("Admin") !== -1;

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    try {
      setIsUploadingPicture(true);
      const storedFile = await storageService.put(file);

      await authService.updateProfilePicture({
        picture: storedFile.key,
        user: user.cognitoUser,
      });
      queryClient.invalidateQueries(["user", "current"]);

      setIsUploadingPicture(false);
    } catch (error) {
      setIsUploadingPicture(false);
    }
  };

  useEffect(() => {
    if (wasSavedSuccessfully) {
      setTimeout(() => setWasSavedSuccessfully(false), 3000);
    }
  }, [wasSavedSuccessfully]);

  const getGroupName = (group) => {
    if (group.indexOf("Facebook") > 0 || group.indexOf("Google") > 0) {
      return <FormattedMessage {...messages["student"]} />;
    }

    if (group === "Admin" || group === "Teacher") {
      return <FormattedMessage {...messages[group.toLowerCase()]} />;
    }
    return "";
  };

  return (
    <Box>
      <Paper className={classes.root} elevation={0} square>
        <div className={classes.profileHeader}>
          <div className={classes.profileTitleContainer}>
            <ProfilePhoto s3Key={user.picture} size="80" />
            <div className={classes.profileTitleTextContainer}>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.profileTitle}
              >
                {user.givenName} {user.familyName}
              </Typography>
              {user.groups && (
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.profileSubTitle}
                >
                  {getGroupName(user.groups[0])}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.pictureUpload}>
            {isUploadingPicture ? (
              <CircularProgress className={classes.spinner} color="primary" />
            ) : (
              <>
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={uploadFile}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    className={classes.uploadBtn}
                  >
                    <FormattedMessage {...messages.uploadPicture} />
                  </Button>
                </label>
              </>
            )}
          </div>
        </div>
      </Paper>
      <Paper className={classes.root2} elevation={0} square>
        <div className={classes.profileFormWrapper}>
          {user && <ProfileForm user={user} />}
        </div>
      </Paper>
      {isAdmin && (
        <Paper className={classes.root2}>
          <div className={classes.profileFormWrapper}>
            <ImpersonateUserForm />
          </div>
        </Paper>
      )}
    </Box>
  );
};

export default withStyles(styles)(ProfilePage);
