import { defineMessages } from "react-intl";

export default defineMessages({
  confirmationHeader: {
    defaultMessage: "Thank you for your registration!",
    id: "app.containers.SignupVerificationPage.confirmationHeader",
  },
  confirmationText: {
    defaultMessage: "Please activate your account in order to login.",
    id: "app.containers.SignupVerificationPage.confirmationText",
  },
  header: {
    defaultMessage: "This is SignupVerificationPage container !",
    id: "app.containers.SignupVerificationPage.header",
  },
  login: {
    defaultMessage: "Login",
    id: "app.containers.SignupVerificationPage.login",
  },
  verificationCode: {
    defaultMessage: "Verification code",
    id: "app.containers.SignupVerificationPage.verificationCode",
  },
  verificationSuccessful: {
    defaultMessage:
      "You have successfully verified your account. Proceed to login to your account. ",
    id: "app.containers.SignupVerificationPage.verificationSuccessful",
  },
  verifyCodeBtn: {
    defaultMessage: "Verify code",
    id: "app.containers.SignupVerificationPage.verifyCodeBtn",
  },
});
