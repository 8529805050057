import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { authService } from "services/user";
import * as Yup from "yup";

import messages from "./messages";

const styles = (theme) => ({
  actionsContainer: {
    margin: "0px 8px 0px 8px",
    paddingTop: "25px",
    textAlign: "center",
    width: "100%",
  },
  button: {
    margin: "0px",
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formContainer: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
});

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-underscore-dangle */
const ForgotPasswordForm = ({ classes }) => {
  const history = useHistory();

  const { mutate: resetPassword, isError } = useMutation(
    async ({ email }) => {
      await authService.resetPassword(email);
    },
    {
      onSuccess: () => {
        history.push("forgotPassword/sent");
      },
    }
  );
  const formik = useFormik({
    initialErrors: { email: "" },
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      resetPassword({ email: values.email });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/.+@.+/, messages.emailFormat.id)
        .required(messages.emailRequired.id),
    }),
  });

  return (
    <div className={classes.formContainer}>
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          id="email"
          type="email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={classes.textField}
          label={<FormattedMessage {...messages.email} />}
          error={formik.touched.email && Boolean(formik.errors.email)}
          required
          helperText={
            formik.touched.email &&
            Boolean(formik.errors.email) && (
              <FormattedMessage id={formik.errors.email} />
            )
          }
        />
        <div className={classes.actionsContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            disabled={!formik.isValid}
          >
            <FormattedMessage {...messages.submitBtn} />
          </Button>
        </div>
        {isError && (
          <Typography variant="body1" color="error" align="center">
            <FormattedMessage {...messages.resendError} />
          </Typography>
        )}
      </form>
    </div>
  );
};

export default withStyles(styles)(ForgotPasswordForm);
