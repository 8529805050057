import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import { getTimeText, isToday } from "utils/time";

const useStyles = makeStyles((theme) => ({
  classTime: {
    border: "1px solid #E4E4E4",
    borderRadius: "0px 25px 25px 0px",
    color: "#99A6B7",
    padding: "0px 10px",
    textTransform: "uppercase",
  },
  classTimeRounded: {
    border: "1px solid #E4E4E4",
    borderRadius: "25px 25px 25px 25px",
    color: "#99A6B7",
    padding: "0px 10px",
    textTransform: "uppercase",
  },
  classTimeUrgent: {
    border: "1px solid #E4E4E4",
    borderRadius: "25px 0px 0px 25px",
    borderRight: "none",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: "0px 10px",
    textTransform: "uppercase",
  },
  classTimeWrapper: {
    display: "flex",
    flex: "none",
    fontSize: "12px",
  },
}));

const Counter = ({ datetime, styles = {} }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.classTimeWrapper} ${styles.classTimeWrapper}`}>
      {isToday(datetime) && (
        <div className={classes.classTimeUrgent}>{getTimeText(datetime)}</div>
      )}
      <div
        className={
          isToday(datetime) ? classes.classTime : classes.classTimeRounded
        }
      >
        <Moment fromNow>{datetime}</Moment>
      </div>
    </div>
  );
};

export default Counter;
