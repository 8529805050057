import moment from "moment";

export const isToday = (datetime) => moment().isSame(moment(datetime), "date");
export const isThisWeek = (datetime) =>
  moment().isSame(moment(datetime), "week");
export const isThisMonth = (datetime) =>
  moment().isSame(moment(datetime), "month");
export const isThisYear = (datetime) =>
  moment().isSame(moment(datetime), "year");

export const getTimeText = (datetime) => {
  if (isToday(datetime)) {
    return "Today";
  } else if (isThisWeek(datetime)) {
    return "This Week";
  } else if (isThisMonth(datetime)) {
    return "This Month";
  } else if (isThisYear(datetime)) {
    return "This Year";
  }
  return "Next Year";
};
