import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    defaultMessage: "This is ResendCodePage container !",
    id: "app.containers.ResendCodePage.header",
  },
  resendCodeSuccessful: {
    defaultMessage: "Email sent with new verification code",
    id: "app.containers.SignupVerificationPage.resendCodeSuccessful",
  },
  resendEmailBtn: {
    defaultMessage: "Resend email",
    id: "app.containers.SignupVerificationPage.resendEmailBtn",
  },
});
