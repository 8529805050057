import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  faApple,
  faFacebookF,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import FormattedMessage from "components/FormattedMessage";
import localforage from "localforage";
import { SOCIAL_AUTH_ATTEMPT_KEY } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
  },
  facebookBtn: {
    background: (props) => (props.background ? props.background : "#3B7AE3"),
    border: (props) => (props.color === "#000" ? "1px solid #eee" : "none"),
    color: (props) => (props.color ? props.color : "#fff"),
    marginBottom: "10px",
    width: "100%",
  },
  facebookBtnText: {
    color: (props) => (props.color ? props.color : "#fff"),
  },
  facebookIcon: {
    marginRight: "20px",
  },
}));

const providers = {
  apple: "SignInWithApple",
  facebook: "Facebook",
  google: "Google",
};

const iconStringToIcon = {
  apple: faApple,
  facebook: faFacebookF,
  google: faGoogle,
};

const SocialAuthButton = ({ iconName, message, ...rest }) => {
  // Check if there was a sign-in attempt with social auth that resulted
  // in an error, because we created a "classic" email/password user in cognito in the
  // background.

  const federatedSignIn = async () => {
    try {
      const provider = providers[iconName];
      await localforage.setItem(SOCIAL_AUTH_ATTEMPT_KEY, provider);
      void Auth.federatedSignIn({ provider });
    } catch (error) {
      console.log("ERROR IN FEDERATED SIGN IN", error);
    }
  };

  const classes = useStyles(rest);

  return (
    <Button
      variant="contained"
      className={classes.facebookBtn}
      onClick={federatedSignIn}
      size="large"
    >
      <FontAwesomeIcon
        icon={iconStringToIcon[iconName]}
        className={classes.facebookIcon}
      />
      <Typography variant="body2" className={classes.facebookBtnText}>
        {" "}
        <FormattedMessage {...message} />{" "}
      </Typography>
    </Button>
  );
};

export default SocialAuthButton;
