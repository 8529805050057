import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import PasswordInput from "components/PasswordInput";
import { useStyles } from "components/SignupForm/styles";
import { useFormik } from "formik";
import { useSubjects } from "services/mms";
import { authService } from "services/user";
import * as Yup from "yup";

import messages from "./messages";

const SubjectItem = ({ subject }) => {
  const classes = useStyles();
  return (
    <div className={classes.subjectMenuItem}>
      <img src={subject.subjectIconUri} alt="" />
      <span>{subject.name}</span>
    </div>
  );
};

const SignupForm = () => {
  const classes = useStyles();
  const history = useHistory();

  const { mutate: signUp } = useMutation(async (values) => {
    await authService.signUp(values);
    const params = new URLSearchParams({ email: values.email });
    history.push(`/signup/success?${params}`);
  });

  const formik = useFormik({
    initialErrors: { email: "" },
    initialValues: {
      email: "",
      firstName: "",
      language: navigator.language.toLowerCase().toLowerCase().split("-")[0],
      lastName: "",
      password: "",
      subjectId: "",
      tandc: "",
    },
    onSubmit: (values) => {
      signUp(values);
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^.+@.+[^.]$/gm, messages.invalidEmail.id)
        .required(messages.fieldRequired.id),
      firstName: Yup.string().required(messages.fieldRequired.id),
      language: Yup.string(),
      lastName: Yup.string(),
      password: Yup.string()
        .matches(
          /(?=^.{6,25}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{:;'?/.,])(?!.*\s).*$/,
          messages.passwordHint.id
        )
        .required(messages.fieldRequired.id),
      subjectId: Yup.string(),
      tandc: Yup.mixed().test({
        message: messages.tandcRequired.id,
        name: "tandc",
        test: (value) => value[0] === "on",
      }),
    }),
  });

  const { data: subjects } = useSubjects();

  return (
    <form
      className={classes.formContainer}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <TextField
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email &&
              Boolean(formik.errors.email) && (
                <FormattedMessage id={formik.errors.email} />
              )
            }
            id="email"
            label={<FormattedMessage {...messages.email} />}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            type="email"
            value={formik.values.email}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={
              formik.touched.firstName &&
              Boolean(formik.errors.firstName) && (
                <FormattedMessage id={formik.errors.firstName} />
              )
            }
            id="firstName"
            label={<FormattedMessage {...messages.firstName} />}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            type="text"
            value={formik.values.firstName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={
              formik.touched.lastName &&
              Boolean(formik.errors.lastName) && (
                <FormattedMessage id={formik.errors.lastName} />
              )
            }
            id="lastName"
            label={<FormattedMessage {...messages.lastName} />}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.lastName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            error={formik.touched.subjectId && Boolean(formik.errors.subjectId)}
            helperText={
              formik.touched.subjectId &&
              Boolean(formik.errors.subjectId) && (
                <FormattedMessage id={formik.errors.subjectId} />
              )
            }
            SelectProps={{
              renderValue: (value) => {
                const subject = subjects?.all.find(
                  (subject) => subject.id.toString() === value
                );
                if (subject) {
                  return <SubjectItem subject={subject} />;
                }
                return value;
              },
            }}
            id="subjectId"
            name="subjectId"
            label={<FormattedMessage {...messages.instrument} />}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant="outlined"
            value={formik.values.subjectId}
          >
            {subjects?.all.map((subject) => (
              <MenuItem key={subject.id} value={subject.id.toString()}>
                <SubjectItem subject={subject} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <PasswordInput
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password &&
              Boolean(formik.errors.password) && (
                <FormattedMessage id={formik.errors.password} />
              )
            }
            id="password"
            label={<FormattedMessage {...messages.password} />}
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.password}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl required error={Boolean(formik.errors.tandc)}>
            <FormControlLabel
              control={<Checkbox value="on" />}
              name="tandc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={<FormattedMessage {...messages.tandc} />}
            />
            {formik.touched.tandc && Boolean(formik.errors.tandc) && (
              <FormHelperText>
                <FormattedMessage {...messages.tandcRequired} />
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            disabled={!formik.isValid}
            size="large"
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messages.createBtn} />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignupForm;
