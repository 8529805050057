import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

interface PersonDisplayProps {
  src: string;
  alt: string;
  infoMessage: React.ReactNode;
  name: string;
}

export const PersonDisplay: FC<PersonDisplayProps> = ({
  src,
  alt,
  infoMessage,
  name,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.personDisplayWrapper}>
      <img
        src={src}
        className={classes.personDisplayPhoto}
        alt={alt}
        title={alt}
      />
      <div className={classes.personDisplayNameWrapper}>
        <Typography
          variant="body1"
          className={classes.personDisplayInfoMessage}
        >
          {infoMessage}
        </Typography>
        <Typography variant="body1" className={classes.personDisplayName}>
          {name}
        </Typography>
      </div>
    </div>
  );
};
