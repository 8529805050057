import React, { FC } from "react";
import { Avatar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

interface SelectItemProps {
  value: string;
  title: string;
  imageUrl?: string;
  icon: string;
  alt?: string;
  largeIcon?: boolean;
  disabled: boolean;
  variant?: "square" | "circular";
}

const SelectItem: FC<SelectItemProps> = ({
  value,
  title,
  imageUrl = "",
  icon,
  alt = "",
  largeIcon = false,
  disabled = false,
  variant = "circular",
}) => {
  const classes = useStyles();

  return (
    <div className={classes.selectItemWrapper}>
      <div
        className={largeIcon ? classes.largeIconWrapper : classes.iconWrapper}
      >
        <Avatar
          // Supply key here, so that React re-mounts the Avatar component if the imageUrl changes
          // Otherwise, when changing the imageUrl, the old image will be visible until the new one has loaded.
          key={imageUrl}
          variant={variant}
          src={
            imageUrl ||
            `https://s3.eu-central-1.amazonaws.com/mymusicschool-assets/subject-icons/${icon}.svg`
          }
          className={largeIcon ? classes.largeImage : classes.image}
          alt={alt}
        />
      </div>
      <div className={classes.selectItemContent}>
        <Typography className={classes.title} variant="body2">
          {title}
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.value} ${disabled ? classes.disabled : ""}`}
        >
          {value}
        </Typography>
      </div>
    </div>
  );
};

export default SelectItem;
