import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { authService } from "services/user";

import messages from "./messages";

const styles = () => ({
  authMessages: {
    margin: "30px",
  },
});

/* eslint-disable react/prefer-stateless-function */
const ResendCodePage = ({ classes, email }) => {
  const [successful, setSuccessful] = useState(false);

  const resendCode = async () => {
    try {
      await authService.resendSignUp(email);
      setSuccessful(true);
    } catch {
      setSuccessful(false);
    }
  };

  return (
    <div>
      {successful && (
        <div className={classes.authMessages}>
          <Typography variant="body2" color="primary">
            <FormattedMessage {...messages.resendCodeSuccessful} />
          </Typography>
        </div>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={resendCode}
      >
        <FormattedMessage {...messages.resendEmailBtn} />
      </Button>
    </div>
  );
};

export default withStyles(styles)(ResendCodePage);
