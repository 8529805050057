import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ForgotPasswordForm from "components/ForgotPasswordForm";
import FormattedMessage from "components/FormattedMessage";

import messages from "./messages";

const styles = (theme) => ({
  emailSentImage: {
    display: "block",
    marginBottom: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    width: 100,
  },
  form: {
    margin: "0px auto",
    marginTop: "100px",
    maxWidth: "500px",
    padding: "30px",
  },
  header: {
    marginBottom: "30px",
  },
  signUpLink: {
    color: theme.palette.primary.main,
    marginLeft: "5px",
    textDecoration: "none",
  },
  signupText: {
    marginTop: "25px",
    opacity: "0.75",
    textAlign: "center",
  },
});

/* eslint-disable react/prefer-stateless-function */
const ForgotPasswordPage = ({ classes }) => (
  <Paper elevation={0} className={classes.form}>
    <Typography
      variant="h6"
      component="h3"
      align="center"
      className={classes.header}
    >
      <FormattedMessage {...messages.header} />
    </Typography>

    <ForgotPasswordForm />

    <Typography className={classes.signupText}>
      <FormattedMessage {...messages.login} />
      <Link to="/login" href="/login" className={classes.signUpLink}>
        <span>
          <FormattedMessage {...messages.loginHere} />
        </span>
      </Link>
    </Typography>
    <Typography className={classes.signupText}>
      {" "}
      <FormattedMessage {...messages.accountHint} />
      <Link to="/signup" href="/signup" className={classes.signUpLink}>
        <span>
          {" "}
          <FormattedMessage {...messages.signupNow} />
        </span>
      </Link>
    </Typography>
  </Paper>
);

export default withStyles(styles)(ForgotPasswordPage);
