import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    justifyItems: "space-between",
  },
  details: {
    color: theme.palette.grey[500],
  },
  icon: {
    "& .MuiSvgIcon-root": {
      height: theme.spacing(5),
      width: theme.spacing(5),
      zIndex: -100,
    },
    color: theme.palette.grey[200],
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  root: {
    cursor: "pointer",
    minHeight: "12em",
    overflow: "inherit",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(32),
    },
  },
}));

export default useStyles;
