import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";

import messages from "./messages";

const styles = (theme) => ({
  lessons: {
    color: theme.palette.primary.main,
  },
  title: {
    color: "#303E4E",
    fontWeight: "700",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 0px 10px",
    whiteSpace: "normal",
  },
});

const SubscriptionItem = ({ classes, remainingMinutes, title }) => (
  <div className={classes.wrapper}>
    <Typography variant="subtitle1" className={classes.title}>
      {title}
    </Typography>
    <Typography variant="body1">
      <span className={classes.lessons}>
        {remainingMinutes} <FormattedMessage {...messages.remainingTime} />
      </span>
    </Typography>
  </div>
);

export default withStyles(styles)(SubscriptionItem);
