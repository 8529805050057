import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    "& .MuiButton-label": {
      display: "flex",
      gap: theme.spacing(1),
    },
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
    gap: theme.spacing(2),
    margin: "120px auto",
    maxWidth: "max-content",
    padding: "30px",
  },
}));
