import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    margin: "0px 8px 0px 8px",
    paddingTop: "25px",
    textAlign: "center",
    width: "100%",
  },
  button: {
    margin: "0px",
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formContainer: {
    "& .MuiFormControl-root": {
      marginLeft: 0,
    },
  },
  subjectMenuItem: {
    "& img": {
      height: "16px",
      minWidth: "16px",
      width: "16px",
    },
    display: "flex",
    gap: "10px",
  },
  tandc: {
    marginLeft: "-6px",
    marginTop: "20px",
    opacity: "0.75",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));
