import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CoursesOverview from "components/CoursesOverview";
import FormattedMessage from "components/FormattedMessage";
import { PlanOrderCard } from "components/PlanOrderCard";
import { PlanOverviewCard } from "components/PlanOverviewCard";
import { useAppointments, usePlans } from "services/mms";
import { useCurrentUser } from "services/user";

import messages from "./messages";

const useStyles = makeStyles((theme) => ({
  centerSmall: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(4),
  },
  subscriptionsAndPackages: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
}));

/* eslint-disable react/prefer-stateless-function */
const HomePage = () => {
  const { data: user } = useCurrentUser();
  const classes = useStyles();

  useEffect(() => {
    document.title = "Home";
  }, [user]);

  // Prefetch appointments
  void useAppointments();

  const { data: plans } = usePlans();

  return (
    <div className={classes.root}>
      <Typography className={classes.centerSmall} variant="h5">
        <FormattedMessage {...messages.subscriptionAndPackages} />
      </Typography>
      <div className={classes.subscriptionsAndPackages}>
        {plans?.all &&
          plans.all.map((plan) => (
            <PlanOverviewCard key={plan.certificate} plan={plan} />
          ))}
        <PlanOrderCard />
      </div>
      <Typography className={classes.centerSmall} variant="h5">
        <FormattedMessage {...messages.header} />
      </Typography>
      <CoursesOverview />
    </div>
  );
};

export default HomePage;
