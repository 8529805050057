import { defineMessages } from "react-intl";

export default defineMessages({
  createBtn: {
    defaultMessage: "Create",
    id: "app.components.SignupForm.createBtn",
  },
  email: {
    defaultMessage: "Email",
    id: "app.components.SignupForm.email",
  },
  fieldRequired: {
    defaultMessage: "This field is required.",
    id: "app.components.SignupForm.fieldRequired",
  },
  firstName: {
    defaultMessage: "First Name",
    id: "app.components.SignupForm.firstName",
  },
  firstNameIsRequired: {
    defaultMessage: "Firstname is required",
    id: "app.components.SignupForm.firstNameIsRequired",
  },
  header: {
    defaultMessage: "This is the SignupForm component !",
    id: "app.components.SignupForm.header",
  },
  instrument: {
    defaultMessage: "What do you want learn?",
    id: "app.components.SignupForm.instrument",
  },
  invalidEmail: {
    defaultMessage: "Invalid email",
    id: "app.components.SignupForm.invalidEmail",
  },
  lastName: {
    defaultMessage: "Last Name",
    id: "app.components.SignupForm.lastName",
  },
  lastNameIsRequired: {
    defaultMessage: "Lastname is required",
    id: "app.components.SignupForm.lastNameIsRequired",
  },
  optionalTxt: {
    defaultMessage: "Optional",
    id: "app.components.SignupForm.optionalTxt",
  },
  password: {
    defaultMessage: "Password",
    id: "app.components.SignupForm.password",
  },
  passwordAgain: {
    defaultMessage: "Password Again",
    id: "app.components.SignupForm.passwordAgain",
  },
  passwordAgainIsRequired: {
    defaultMessage: "PasswordAgain is required",
    id: "app.components.SignupForm.passwordAgainIsRequired",
  },
  passwordDontMatch: {
    defaultMessage: "Passwords dont match",
    id: "app.components.SignupForm.passwordDontMatch",
  },
  passwordHint: {
    defaultMessage:
      "Password must be atleast 6 characters with atleast one uppercase letter, one lowercase letter, a number and a special character.",
    id: "app.components.SignupForm.passwordHint",
  },
  tandc: {
    defaultMessage: "By signing up you agree to the",
    id: "app.components.SignupForm.tandc",
  },
  tandcPrivacy: {
    defaultMessage: " privacy policy.",
    id: "app.components.SignupForm.tandcPrivacy",
  },
  tandcRequired: {
    defaultMessage: "You have to accept the terms and conditions to register.",
    id: "app.components.SignupForm.tandcRequired",
  },
  tandcTerms: {
    defaultMessage: " terms and conditions",
    id: "app.components.SignupForm.tandcTerms",
  },
});
