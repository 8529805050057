import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppointments } from "services/mms";

import messages from "./messages";

const headerFontColor = "#303e4e";

const styles = (theme) => ({
  bookingLink: {
    textDecoration: "none",
  },
  button: {
    margin: "0px",
    minWidth: "150px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      width: "100%",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  headerTitleSection: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  icon: {
    margin: "5px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "5px",
    },
  },
  newClassBtn: {
    zIndex: 1,
  },
  upcomingClassesHeader: {
    color: headerFontColor,
    fontSize: "1.5rem",
  },
});

const UpcomingClassesHeader = ({ classes }) => {
  const { isFetching } = useAppointments();
  return (
    <div className={classes.header}>
      <div className={classes.headerTitleSection}>
        <Typography variant="h5" className={classes.upcomingClassesHeader}>
          <FormattedMessage {...messages.header} />
        </Typography>
        {isFetching && <CircularProgress color="primary" size="1em" />}
      </div>
      <div className={classes.newClassBtn}>
        <Link
          className={classes.bookingLink}
          to="/appointments/book"
          href="/appointments/book"
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            <FontAwesomeIcon icon={faPlus} className={classes.icon} />
            <FormattedMessage {...messages.newClassBtn} />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles)(UpcomingClassesHeader);
