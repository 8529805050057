import { defineMessages } from "react-intl";

export default defineMessages({
  email: {
    defaultMessage: "Email",
    id: "app.components.ForgotPasswordForm.email",
  },
  emailFormat: {
    defaultMessage: "Please enter a valid email address (e.g. me@gmail.com)",
    id: "app.components.ProfileForm.emailFormat",
  },
  emailRequired: {
    defaultMessage: "This field is required.",
    id: "app.components.ProfileForm.emailRequired",
  },
  header: {
    defaultMessage: "This is the ForgotPasswordForm component !",
    id: "app.components.ForgotPasswordForm.header",
  },
  invalidEmail: {
    defaultMessage: "Invalid email",
    id: "app.components.ForgotPasswordForm.invalidEmail",
  },
  resendError: {
    defaultMessage: "An unexpected error occurred.",
    id: "app.containers.ForgotPasswordPage.resendError",
  },
  submitBtn: {
    defaultMessage: "Submit",
    id: "app.components.ForgotPasswordForm.submitBtn",
  },
});
