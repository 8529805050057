import React, { useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useCurrentUser } from "services/user";

interface LanguageContextValue {
  language: "de" | "en";
  setLanguage: (language: "de" | "en") => void;
}

const LanguageContext = React.createContext<LanguageContextValue>({
  language: "de",
  setLanguage() {},
});

export const useCurrentLanguage = () => useContext(LanguageContext);

const LanguageProvider = ({ messages, children }) => {
  const { data: user } = useCurrentUser();
  const [language, setLanguage] =
    useState<LanguageContextValue["language"]>("de");

  useEffect(() => {
    if (user?.language) {
      setLanguage(user.language);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IntlProvider
      locale={language}
      key={language}
      defaultRichTextElements={{
        b: (chunks) => <b>{chunks}</b>,
      }}
      // provide en as fallback language
      // (react-intl is actually kind of strange behaved here, see: https://github.com/formatjs/formatjs/issues/2573)
      messages={{ ...messages["en"], ...messages[language] }}
      onError={(error) => console.info(error)}
    >
      <LanguageContext.Provider value={{ language, setLanguage }}>
        {React.Children.only(children)}
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

export default LanguageProvider;
