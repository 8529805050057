import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

interface ImpersonateContextValue {
  impersonateEmail: string | undefined;
  setImpersonateEmail: (value: string | undefined) => void;
}

const ImpersonateContext = createContext<ImpersonateContextValue>({
  impersonateEmail: undefined,
  setImpersonateEmail: () => undefined,
});

export const ImpersonateContextProvider = ({ children }) => {
  const [impersonateEmail, setImpersonateEmail] = useState<string>();

  useEffect(() => {
    if (!impersonateEmail) {
      delete axios.defaults.headers["X-Impersonate-Email"];
    } else {
      axios.defaults.headers = {
        // @ts-ignore
        "X-Impersonate-Email": impersonateEmail,
      };
    }
  }, [impersonateEmail]);

  return (
    <ImpersonateContext.Provider
      value={{
        impersonateEmail,
        setImpersonateEmail,
      }}
    >
      {children}
    </ImpersonateContext.Provider>
  );
};

export const useImpersonateContext = () => useContext(ImpersonateContext);
