import { defineMessages } from "react-intl";

export default defineMessages({
  continueButton: {
    defaultMessage: "Continue to MyMusicSchool",
    id: "app.containers.SocialAuthConfirmPage.continueButton",
  },
  successMessage: {
    defaultMessage: "Your account was created successfully!",
    id: "app.containers.SocialAuthConfirmPage.successMessage",
  },
});
