import { defineMessages } from "react-intl";

export default defineMessages({
  adminEmail: {
    defaultMessage: "Admin-Email",
    id: "app.components.ProfileForm.adminEmail",
  },

  adminPassword: {
    defaultMessage: "Admin-Password",
    id: "app.components.ProfileForm.adminPassword",
  },

  emailFormat: {
    defaultMessage: "Please enter a valid email address (e.g. me@gmail.com)",
    id: "app.components.ImpersonateUserForm.emailFormat",
  },

  emailRequired: {
    defaultMessage: "This field is required.",
    id: "app.components.ImpersonateUserForm.emailRequired",
  },

  impersonateEmail: {
    defaultMessage: "Email",
    id: "app.components.ImpersonateUserForm.impersonateEmail",
  },

  invalidEmail: {
    defaultMessage: "Invalid email",
    id: "app.components.ImpersonateUserForm.invalidEmail",
  },

  stopImpersonatingSubmitButton: {
    defaultMessage: "Stop impersonating {email}",
    id: "app.components.ImpersonateUserForm.stopImpersonatingSubmitButton",
  },
  submitButton: {
    defaultMessage: "Impersonate",
    id: "app.components.ImpersonateUserForm.submitButtom",
  },
});
