import { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormattedMessage from "components/FormattedMessage";
import { useSubjects, useTeachers } from "services/mms";

import messages from "./messages";
import SelectItem from "./SelectItem";
import useStyles from "./styles";

const TeacherSelect = ({ teacherId, setTeacherId, subjectId }) => {
  const classes = useStyles();

  const { data: subjects } = useSubjects();
  const { data: teachers } = useTeachers();
  const [filteredTeachers, setFilteredTeachers] = useState([]);

  useEffect(() => {
    if (subjectId && subjects?.all.length && teachers?.length) {
      const teacherIds = subjects.all.find(
        (sub) => sub.id === subjectId
      ).teacherIds;
      const supportedTeachers = teachers.filter(
        (teacher) => teacherIds.indexOf(teacher.id) > -1
      );
      const supportedDefaultTeacher = supportedTeachers.find(
        (teacher) => teacher.default
      );

      if (!teacherId && supportedTeachers.length) {
        setTeacherId(
          supportedDefaultTeacher
            ? supportedDefaultTeacher.id
            : supportedTeachers[0].id
        );
      }
      setFilteredTeachers([...supportedTeachers]);
    }
  }, [subjectId, subjects, teachers, setTeacherId, teacherId]);

  useEffect(() => {
    // prefetch teacher thumbnails
    filteredTeachers.forEach((teacher) => {
      new Image().src = teacher.thumbnail;
    });
  }, [filteredTeachers]);

  return (
    <Select
      value={teacherId || -1}
      onChange={(event) => {
        setTeacherId(parseInt(event.target.value, 10));
      }}
      displayEmpty={true}
      classes={{ select: classes.select }}
      renderValue={() => {
        const selectedTeacher = filteredTeachers.find(
          (tec) => tec.id === teacherId
        );
        return !teachers ? (
          <CircularProgress
            className={classes.spinner}
            size={20}
            color="primary"
          />
        ) : (
          <SelectItem
            alt={selectedTeacher?.name || ""}
            classes={classes}
            value={
              selectedTeacher ? (
                selectedTeacher.name
              ) : (
                <FormattedMessage {...messages.chooseTeacher} />
              )
            }
            imageUrl={selectedTeacher ? selectedTeacher.thumbnail : null}
            largeIcon={!!selectedTeacher}
            title={<FormattedMessage {...messages.teacher} />}
          />
        );
      }}
    >
      {filteredTeachers.map((teacher) => (
        <MenuItem
          key={teacher.id}
          value={teacher.id}
          className={classes.teacherMenuItem}
        >
          <Avatar
            src={teacher.thumbnail || undefined}
            className={classes.teacherImage}
            alt={teacher.name}
          />
          <span className={classes.teacherName}>{teacher.name}</span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default TeacherSelect;
