import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    formWrapper: {},
    inputIcon: {
      color: "#6F7B8A",
      textAlign: "right",
      width: "3.5rem",
    },
  })
);
