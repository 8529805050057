import { Auth } from "aws-amplify";

const signIn = async (email, password) => Auth.signIn(email, password);

const signOut = async () =>
  Auth.signOut({ global: true }).catch((error) => {
    Auth.signOut();
    throw error;
  });

const signUp = async ({ email, password, firstName, lastName, subjectId }) =>
  Auth.signUp({
    attributes: {
      "custom:language": navigator.language.toLowerCase().split("-")[0],
      "custom:preferredDuration": "45",
      "custom:preferredSubjectIds": subjectId || "",
      "custom:preferredTeacherIds": "",
      "custom:role": "2",
      email,
      family_name: lastName,
      given_name: firstName,
    },
    password,
    username: email,
    validationData: [],
  })
    .then((json) => json)
    .catch((error) => {
      throw error;
    });

const resendSignUp = (username) =>
  Auth.resendSignUp(username)
    .then((json) => json)
    .catch((error) => {
      throw error;
    });

export const deleteAccount = (user) => {
  try {
    /* let params = {
      UserPoolId: user.pool.userPoolId,
      Username: user.pool.clientId,
    };

    let cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider(
      ...awsExports
    );

    cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) => {
      if (err) {
        throw err;
      }
      Auth.signOut({ global: true });
    });
    /*user.deleteAccount((error, data) => {
      if (error) {
        throw error;
      }

      Auth.signOut({ global: true });
    });*/
  } catch (error) {
    throw error;
  }
};

export const resetPassword = (email) =>
  Auth.forgotPassword(email)
    .then((json) => json)
    .catch((error) => {
      throw error;
    });

export const verifyCode = ({ username, code }) =>
  Auth.confirmSignUp(username, code, {
    forceAliasCreation: true,
  })
    .then((json) => json)
    .catch((error) => {
      throw error;
    });

export const resendCode = (username) =>
  Auth.resendSignUp(username)
    .then((json) => json)
    .catch((error) => {
      throw error;
    });

export const changePassword = (email, code, password) =>
  Auth.forgotPasswordSubmit(email, code, password)
    .then((json) => json)
    .catch((error) => {
      throw error;
    });

const extractUserData = (response) => {
  const attributes = {};
  response.forEach((item) => {
    const arr = item.Name.split(":");
    attributes[arr.length > 1 ? arr[1] : arr[0]] = item.Value;
  });

  return attributes;
};

const userAttributes = async (user) =>
  Auth.userAttributes(user)
    .then((resp) => extractUserData(resp))
    .catch((err) => {
      throw err;
    });

export const getCurrentUser = () =>
  Auth.currentAuthenticatedUser()
    .then((user) => user)
    .catch((err) => {
      throw err;
    });

export const saveUserAttributes = (
  user,
  { phoneNumber, language, sendPushMsgs, sendEmail, firstName, lastName }
) =>
  Auth.updateUserAttributes(user, {
    "custom:language": language,
    "custom:sendEmail": sendEmail ? "1" : "0",
    "custom:sendPushMsgs": sendPushMsgs ? "1" : "0",
    // email: email,  E-Mail cannot be changed
    family_name: lastName,
    given_name: firstName,
    phone_number: phoneNumber ? phoneNumber : "",
  });

export const updateProfilePicture = ({ user, picture }) =>
  Auth.updateUserAttributes(user, {
    picture,
  })
    .then((resp) => resp)
    .catch((err) => {
      throw err;
    });

export { signIn, signOut, userAttributes, signUp, resendSignUp };
