import { defineMessages } from "react-intl";

export default defineMessages({
  confirmationHeader: {
    defaultMessage: "Thank you for your registration!",
    id: "app.containers.SignupPage.confirmationHeader",
  },
  confirmationText: {
    defaultMessage: "Please activate your account in order to login.",
    id: "app.containers.SignupPage.confirmationText",
  },
  forgotPassword: {
    defaultMessage: "Forgot password?",
    id: "app.containers.LoginForm.forgotPassword",
  },
  header: {
    defaultMessage: "Create New Account",
    id: "app.containers.SignupPage.header",
  },
  login: {
    defaultMessage: "Do you have an account already?",
    id: "app.containers.LoginForm.login",
  },
  loginHere: {
    defaultMessage: "Login here",
    id: "app.containers.LoginForm.loginHere",
  },
  resendEmailBtn: {
    defaultMessage: "Resend email",
    id: "app.containers.SignupPage.resendEmailBtn",
  },
  resetPassword: {
    defaultMessage: "Reset password now",
    id: "app.containers.LoginForm.resetPassword",
  },
});
