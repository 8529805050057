import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    defaultMessage: "Login",
    id: "app.containers.LoginPage.header",
  },
  NotAuthorizedException: {
    defaultMessage: "Invalid email or password",
    id: "NotAuthorizedException",
  },
});
