import { useEffect } from "react";
import CourseDetail from "components/CourseDetail";
import { useCourses } from "services/courses";

/* eslint-disable react/prefer-stateless-function */
const CoursePage = ({ match }) => {
  const { data: courses } = useCourses();

  useEffect(() => {
    document.title = "Home";
  }, []);

  const courseId = match.params.courseId;

  if (!courses) return null;

  const courseData = courses.find((course) => course.id === courseId);
  if (!courseData) {
    return "Course not found";
  }

  return <CourseDetail course={courseData} />;
};

export default CoursePage;
