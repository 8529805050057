import { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TeacherContentBox } from "components/UpcomingClass/TeacherContentBox";
import { useCurrentLanguage } from "containers/LanguageProvider";
import { format } from "date-fns";
import germanLocale from "date-fns/locale/de";
import moment from "moment";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

import "moment/locale/de";

const UpcomingClass = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { language } = useCurrentLanguage();

  useEffect(() => {
    moment.locale(language);
    moment.relativeTimeThreshold("s", 59);
    moment.relativeTimeThreshold("m", 60);
    moment.relativeTimeThreshold("h", 23);
    moment.relativeTimeThreshold("d", 28);
    moment.relativeTimeThreshold("M", 12);
  }, [language]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const endTime = moment(data.datetime).add(data.duration, "m");

  return (
    <div
      className={classes.root}
      onClick={() => {
        setIsDrawerOpen(true);
      }}
    >
      <div className={classes.timeBox}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.date}>
            <Typography
              variant="h5"
              style={{
                color: theme.palette.primary.main,
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {`${moment(data.datetime).format("DD")}`}
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: "#99A6B7",
                fontSize: "20px",
                fontWeight: "normal",
                marginLeft: "5px",
              }}
            >
              {format(new Date(data.datetime), "LLL", {
                locale: language === "en" ? "" : germanLocale,
              })}
            </Typography>
          </div>
          <div className={classes.day}>
            {moment(data.datetime).format("dddd")}
          </div>
        </div>
        <div style={{ minHeight: "1em" }} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.startTime}>
            {moment(data.datetime).format("H")}:
            {moment(data.datetime).format("mm")}
          </div>
          <div className={classes.endTime}>
            {moment(endTime).format("H")}:{moment(endTime).format("mm")}
          </div>
        </div>
      </div>
      <TeacherContentBox
        data={data}
        showCounter
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </div>
  );
};

UpcomingClass.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  dateFormat: PropTypes.string,
  dayFormat: PropTypes.string,
  shortDateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
};

export default UpcomingClass;
