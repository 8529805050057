import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import FormattedMessage from "components/FormattedMessage";
import localforage from "localforage";
import { SOCIAL_AUTH_ATTEMPT_KEY } from "utils/constants";

import messages from "./messages";
import { useStyles } from "./styles";

const SocialAuthConfirmPage = () => {
  const classes = useStyles();

  const onClick = async () => {
    const provider = await localforage.getItem(SOCIAL_AUTH_ATTEMPT_KEY);
    if (provider) {
      // @ts-ignore
      void Auth.federatedSignIn({ provider });
    }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="h6">
        <FormattedMessage {...messages.successMessage} />
      </Typography>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={onClick}
      >
        <FormattedMessage {...messages.continueButton} />
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </Paper>
  );
};

export default SocialAuthConfirmPage;
