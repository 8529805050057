import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import localforage from "localforage";

import App from "./containers/App";
import { awsExports, oauth } from "./aws-exports";
import reportWebVitals from "./reportWebVitals";

import "./global-styles.css";

import "@fontsource/roboto";
import "@fontsource/open-sans";

Amplify.configure(awsExports);
Auth.configure({ ...awsExports, oauth });
localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: "mms",
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { theme } from "theme/theme";
