import { defineMessages } from "react-intl";

export default defineMessages({
  addToCalendar: {
    defaultMessage: "Add to calendar",
    id: "app.containers.BookingConfirmationPage.addToCalendar",
  },
  bookFurtherAppointment: {
    defaultMessage: "Book more appointments",
    id: "app.containers.BookingConfirmationPage.bookFurtherAppointment",
  },
  student: {
    defaultMessage: "Student",
    id: "app.components.UpcomingClass.student",
  },
  teacher: {
    defaultMessage: "Teacher",
    id: "app.components.UpcomingClass.teacherId",
  },
  thanks: {
    defaultMessage: "Thank you for your booking",
    id: "app.containers.BookingConfirmationPage.thanks",
  },
});
