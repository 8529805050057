import { FC } from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import { useCurrentLanguage } from "containers/LanguageProvider";

const styles = (theme) =>
  createStyles({
    dropdown: {
      height: "17px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    language: {
      paddingLeft: "0px",
    },
    LanguageSwitcher: {
      fontSize: "12px",
      fontWeight: "normal",
      height: "38px",
      marginTop: "0px",
      padding: "0px",
    },
    localeSelect: {
      padding: "4px 25px 0px 10px",
    },
  });

const languages = [
  { code: "de", label: "DE" },
  { code: "en", label: "EN" },
];

interface LanguageSwitcherProps extends WithStyles<typeof styles> {}

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ classes }) => {
  const { language, setLanguage } = useCurrentLanguage();

  return (
    <Select
      value={language}
      onChange={(event: any) => setLanguage(event.target.value)}
      className={classes.LanguageSwitcher}
      classes={{ select: classes.localeSelect }}
    >
      {languages.map(({ code, label }) => (
        <MenuItem key={code} value={code}>
          <span className={classes.language}>{label}</span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default withStyles(styles)(LanguageSwitcher);
