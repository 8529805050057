const appBarHeightPx = 64;
const bottomNavHeightPx = 65;

const drawerWidth = 124;

export const styles = (theme) => ({
  appBar: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #eee",
    color: "black",
    height: `${appBarHeightPx}px`,
  },
  avatar: {
    margin: 10,
  },
  bottomNavigation: {
    width: "100%",
  },
  content: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: 1,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  dropdown: {
    height: "33px",
    textTransform: "none",

    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  dropdownLink: {
    color: "#000",
    textDecoration: "none",
  },
  grow: {
    flexGrow: 1,
  },
  listItemsWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  logo: {
    height: 36,
  },
  logoSmall: {
    height: 30,
    marginRight: 10,
    width: 30,
  },
  mainContentWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
  },
  menuItem: {
    borderBottom: "1px solid #eee",
    flexFlow: "column",
    height: "84px",
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  menuItemIcon: {
    color: "inherit",
    marginRight: "0px",
    minWidth: "0px",
  },
  menuItemIconSvg: {
    width: 22,
  },
  menuText: {
    flex: "none",
    fontSize: "12px",
    marginTop: "5px",
    textAlign: "center",
    textTransform: "uppercase",
  },
  menuWrapper: {
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    height: "93vh",
    justifyContent: "space-between",
  },
  navigation: {
    background: "#fff",
    borderRight: "1px solid #eee",
    width: `${drawerWidth}px`,
    [theme.breakpoints.down("sm")]: {
      height: `${bottomNavHeightPx}px`,
      width: "100%",
    },
  },
  profileDropdown: {
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
    },
  },
  profilePhoto: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginRight: "10px",
  },
  requiredLinks: {
    "& a:active": {
      color: "#99a6b7",
      textDecoration: "none",
    },
    "& a:hover": {
      color: "#99a6b7",
      textDecoration: "none",
    },
    "& a:link": {
      color: "#99a6b7",
      textDecoration: "none",
    },
    "& a:visited": {
      color: "#99a6b7",
      textDecoration: "none",
    },
    color: "#99a6b7",
    display: "flex",
    fontSize: "0.8rem",
    gap: "1em",
    justifyContent: "center",
    marginTop: "auto",
    padding: "0.5em",
  },
  root: {
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
  },
  selectedMenuText: {
    color: theme.palette.primary.main,
    flex: "none",
    fontSize: "12px",
    marginTop: "5px",
  },
  spinnerWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  toolbarWrapper: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
});
