import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    actionLink: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    actionText: {
      opacity: "0.75",
      textAlign: "center",
    },
    textField: {
      marginLeft: 0,
      marginRight: 0,
    },
    textFieldWrapper: {
      [theme.breakpoints.down("xs")]: {
        // Hack to increase specificity
        "&.textFieldWrapper": {
          marginBottom: theme.spacing(1),
          padding: theme.spacing(1),
        },
        background: "#fff",
        border: "1px solid #dcdcdc",
      },
    },
  })
);
