import { unstable_createMuiStrictModeTheme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

const primaryColor = "#D23737";
// Mui4 triggers a warning when using the standard createTheme function during
// development due to strict-mode.
// So instead, use a workaround during development to not trigger as many warnings.
const createThemeFn =
  process.env["NODE_ENV"] === "production"
    ? createTheme
    : unstable_createMuiStrictModeTheme;

export const theme = createThemeFn({
  overrides: {
    MuiBottomNavigation: {
      root: {
        justifyContent: "space-around",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        "&$selected": {
          color: primaryColor,
        },
        maxWidth: "80px",
        padding: "0px",
        textTransform: "uppercase",
      },
    },
    MuiButton: {
      label: {
        letterSpacing: 0,
      },
      outlined: {
        border: "1px solid #E4E4E4",
      },
      root: {
        boxShadow: "none !important",
        fontFamily: "Open Sans, sans-serif !important",
        fontWeight: "400 !important",
        textTransform: "none",
      },
    },
    MuiFilledInput: {
      input: {
        height: "20px",
      },
      root: {
        "&:hover,&:focus, &:active": {
          backgroundColor: "inherit",
        },
        backgroundColor: "inherit",
        border: "1px solid #ebebeb",
        borderRadius: "6px",
        fontSize: "14px",
        fontWeight: "normal",
      },
      underline: {
        "&:after": {
          borderBottom: "none !important",
        },
        "&:before": {
          borderBottom: "none !important",
        },
        "&:hover": {
          "&:before": {
            borderBottom: "none !important",
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        margin: "5px 0px",
        marginLeft: "8px",
        width: "100%",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "inherit !important",
        },
      },
    },
    MuiInput: {
      formControl: {
        marginTop: "0px !important",
      },
      root: {
        "&:hover,&:focus, &:active": {
          backgroundColor: "inherit",
        },
        backgroundColor: "inherit",
        border: "1px solid #ebebeb",
        borderRadius: "6px",
        fontSize: "14px",
        fontWeight: "nglobalormal",
      },
      underline: {
        "&:after": {
          borderBottom: "none !important",
        },
        "&:before": {
          borderBottom: "none !important",
        },
        "&:hover": {
          "&:before": {
            borderBottom: "none !important",
          },
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        fontSize: "0.80rem",
        fontWeight: "normal",
        marginTop: "0px !important",
      },
    },
    MuiInputBase: {
      fullWidth: {
        border: "none !important",
      },
      input: {
        color: "#303e4e !important",
        opacity: 0.7,
      },
      root: {
        "&.Mui-disabled": { background: "#eee !important", opacity: 0.5 },
        fontFamily: "Open Sans, sans-serif !important",
        height: "50px",
      },
    },
    MuiInputLabel: {
      shrink: {
        color: "#303e4e",
      },
    },
    MuiList: {
      root: {
        paddingBottom: "0px !important",
        paddingTop: "0px !important",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          color: primaryColor,
        },
      },
    },
    MuiNativeSelect: {
      select: {
        "&:focus": {
          background: "initial",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#e4e4e4",
        },
        "&$focused $notchedOutline": {
          borderColor: "#e4e4e4",
        },
        "&:hover $notchedOutline": {
          borderColor: "#e4e4e4",
        },
      },
    },
    MuiSelect: {
      icon: {
        right: 10,
        top: "calc(50% - 8px)",
      },
      root: {
        opacity: "1 !important",
        textAlign: "left",
      },
      select: {
        "&:focus": {
          background: "initial",
        },
        opacity: "1 !important",
      },
    },
    MuiSvgIcon: {
      root: {
        height: ".75em",
        width: ".75em",
      },
    },
    MuiSwitch: {
      root: {
        "&$checked": {
          color: `${primaryColor} !important`,
        },
      },
    },
    MuiTypography: {
      root: {
        fontFamily: "Open Sans, sans-serif !important",
      },
    },
  },
  palette: {
    primary: { main: primaryColor },
  },
  typography: {
    useNextVariants: true,
  },
});
