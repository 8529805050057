import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CoursesOverview from "components/CoursesOverview";
import FormattedMessage from "components/FormattedMessage";
import { useAppointments } from "services/mms";

import messages from "./messages";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(4),
  },
}));

/* eslint-disable react/prefer-stateless-function */
const MaterialsPage = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Home";
  }, []);

  // Prefetch appointments
  void useAppointments();

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h5">
        <FormattedMessage {...messages.header} />
      </Typography>
      <CoursesOverview />
    </div>
  );
};

export default MaterialsPage;
