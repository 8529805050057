import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  details: {
    color: theme.palette.grey[500],
  },
  icon: {
    "& .MuiSvgIcon-root": {
      height: theme.spacing(5),
      width: theme.spacing(5),
      zIndex: -100,
    },
    color: theme.palette.grey[200],
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  label: {
    // marginBottom: theme.spacing(1),
  },
  placeEnd: {
    marginTop: "auto",
  },
  root: {
    overflow: "inherit",
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(32),
    },
  },
  timeBudgetOverview: {
    fontSize: "24px",
  },
}));

export default useStyles;
