import { useEffect } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import FormattedMessage from "components/FormattedMessage";
import { useCurrentLanguage } from "containers/LanguageProvider";
import moment from "moment";
import { useAppointments } from "services/mms";

import settings from "../../settings";

import messages from "./messages";

const styles = (theme) => ({
  button: {
    margin: "30px",
  },
  datetime: {
    paddingLeft: "5px",
  },
  lightBox: {
    border: "1px solid #eee",
    borderRadius: 4,
    display: "flex",
    height: "240px",
    justifyContent: "center",
    margin: "0 auto",
    marginTop: "10%",
    padding: "30px",
    textAlign: "center",
    width: "65%",
  },
  lightBoxWrapper: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
  newClassLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  root: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "65px",
    },
  },
});

/* eslint-disable react/prefer-stateless-function */
const LiveLessonPage = ({ classes }) => {
  const { language } = useCurrentLanguage();

  // Prefetch appointments
  const { data: appointments, isLoading: appointmentsAreLoading } =
    useAppointments();

  const dateTimeFormat = `${settings.DATE_TIME_FORMAT[language]}`;

  useEffect(() => {
    document.title = "Live Lessons";
  }, []);

  const upcomingAppointment = appointments?.upcoming?.[0];
  const upcomingAppointmentEnd =
    upcomingAppointment &&
    moment(upcomingAppointment).add(
      parseInt(upcomingAppointment.duration, 10),
      "minutes"
    );

  const now = moment();
  const lessonIsLive = upcomingAppointmentEnd?.isAfter(now);

  const gotoVideo = () => {
    window.open(upcomingAppointment.meetingLink);
  };

  const disableGotoVideo = !upcomingAppointment.meetingLink;

  return (
    <div className={classes.root}>
      <Paper className={classes.lightBox} elevation={0} square>
        <div className={classes.lightBoxWrapper}>
          {appointmentsAreLoading ? (
            <CircularProgress className={classes.spinner} color="primary" />
          ) : (
            <>
              {upcomingAppointment && (
                <>
                  {!lessonIsLive && (
                    <FormattedMessage
                      {...messages.futureLessonMsg}
                      values={{
                        date: (
                          <span className={classes.datetime}>
                            <Moment format={dateTimeFormat}>
                              {upcomingAppointment.datetime}
                            </Moment>
                          </span>
                        ),
                        subject: upcomingAppointment.subject,
                        teacherName: upcomingAppointment.teacherName,
                      }}
                    />
                  )}
                  {lessonIsLive && (
                    <FormattedMessage
                      {...messages.liveLessonMsg}
                      values={{
                        date: (
                          <span className={classes.datetime}>
                            <Moment format={dateTimeFormat}>
                              {upcomingAppointment.datetime}
                            </Moment>
                          </span>
                        ),
                        subject: upcomingAppointment.subject,
                        teacherName: upcomingAppointment.teacherName,
                      }}
                    />
                  )}
                </>
              )}
              {upcomingAppointment && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={gotoVideo}
                  disabled={disableGotoVideo}
                >
                  <FormattedMessage {...messages.connectNowBtn} />
                </Button>
              )}
              {!upcomingAppointment && (
                <>
                  <FormattedMessage {...messages.noAppointmentsMsg} />
                  <Link
                    to="/appointments/book"
                    href="/appointments/book"
                    className={classes.newClassLink}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      <FormattedMessage {...messages.bookNowBtn} />
                    </Button>
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(LiveLessonPage);
