export const styles = (theme) => ({
  aboStatus: {
    display: "flex",
    padding: "20px 0px",
  },
  aboStatusColumn: {
    display: "flex",
    flexDirection: "column",
    minWidth: "118px",

    [theme.breakpoints.down("xs")]: {
      minWidth: "60px",
    },
  },
  aboStatusTitle: {
    padding: "5px 0px",
  },
  avatar: {
    height: "81px",
    width: "81px",
  },
  messages: {
    alignItems: "baseline",
    alignSelf: "center",
    display: "flex",
    marginRight: "10px",
  },
  pictureUpload: {
    height: "33px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      width: "100%",
    },
  },
  profileFormWrapper: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
  },
  profileHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: 860,
    paddingLeft: "24px",
    paddingRight: "24px",
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "100%",
    },
  },
  profileSubTitle: {
    color: "#6F7B8A",
    fontSize: "16px",
  },
  profileTitle: {
    fontSize: "26px",
    fontWeight: 600,
    marginBottom: 0,
  },
  profileTitleContainer: {
    display: "flex",
  },
  profileTitleTextContainer: {
    alignSelf: "center",
    color: "#303E4E",
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  },
  root: {
    borderBottom: "1px solid #e4e4e4",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      display: "flex",
      padding: "16px",
    },
  },
  root2: {
    background: "white",
    marginTop: "10px",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  sectionHeader: {
    background: "#99A6B7",
    color: "#fff",
    margin: "0px",
    padding: "5px",
    textTransform: "uppercase",
  },
  uploadBtn: {
    alignSelf: "center",
    fontSize: "12px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  wrapper: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      // marginBottom: '80px',
    },
  },
});
