import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import SubscriptionIcon from "@material-ui/icons/Autorenew";
import PackageIcon from "@material-ui/icons/Redeem";
import { Plan } from "types/plan";
import { generatePlanLabel } from "utils/plan";

import messages from "./messages";

import useStyles from "./PlanOverviewCard.styles";

interface PlanOverviewCardProps {
  plan: Plan;
}

export const PlanOverviewCard: FC<PlanOverviewCardProps> = ({ plan }) => {
  const intl = useIntl();
  const classes = useStyles();

  const recurrenceString = useMemo(() => {
    if (plan.type === "package") return "";

    const period = plan.period?.toString() || "0M";
    const count = parseInt(period);

    return {
      M: intl.formatMessage(messages.recurrenceNumMonths, {
        numMonths: count,
        valueMinutesPerUnit: plan.valueMinutesPerUnit,
        valueUnits: plan.valueUnits,
      }),
      W: intl.formatMessage(messages.recurrenceNumWeeks, {
        numWeeks: count,
        valueMinutesPerUnit: plan.valueMinutesPerUnit,
        valueUnits: plan.valueUnits,
      }),
    }[period.slice(-1)];
  }, [plan, intl]);

  const label = generatePlanLabel(plan);

  return (
    <Card elevation={1} className={classes.root}>
      <CardHeader title={label} />
      <Box className={classes.icon}>
        {plan.type === "subscription" && <SubscriptionIcon />}
        {plan.type === "package" && <PackageIcon />}
      </Box>
      <CardContent className={classes.content}>
        {!!recurrenceString && (
          <Typography variant="body1" className={classes.details}>
            {recurrenceString}
          </Typography>
        )}
        {plan.nextBillingDate && (
          <Typography color="textPrimary">
            <FormattedMessage
              {...messages.nextBillingOn}
              values={{
                billingDate: new Date(plan.nextBillingDate),
                price: plan.price.value,
              }}
            />
          </Typography>
        )}
        {plan.expirationDate && (
          <Typography color="textPrimary" className={classes.details}>
            <FormattedMessage
              {...messages.validUntil}
              values={{ expirationDate: new Date(plan.expirationDate) }}
            />
          </Typography>
        )}
        {plan.type === "package" && (
          <Typography color="textPrimary">
            <FormattedMessage
              {...messages.purchaseInfo}
              values={{
                price: plan.price.value,
                purchaseDate: new Date(plan.purchaseDate),
              }}
            />
          </Typography>
        )}
        <Typography color="textPrimary" className={classes.placeEnd}>
          <FormattedMessage
            {...messages.timeBudget}
            values={{
              remainingBalanceMinutes: plan.remainingBalanceMinutes,
            }}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};
