import Typography from "@material-ui/core/Typography";
import FormattedMessage from "components/FormattedMessage";

import messages from "./messages";

const Errors = ({ errors, isCustomMessage }) => (
  <div className="errorMessages">
    {errors.map((error) => (
      <Typography key={error.time} variant="body2" color="primary">
        {isCustomMessage ? (
          <span>{error}</span>
        ) : (
          <FormattedMessage {...messages[error]} />
        )}
      </Typography>
    ))}
  </div>
);

export default Errors;
