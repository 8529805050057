import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    personDisplayInfoMessage: {
      color: "#99A6B7",
      fontSize: "0.9rem",
    },
    personDisplayName: {
      color: "#303E4E",
      fontWeight: "bold",
    },
    personDisplayNameWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      marginLeft: "10px",
    },
    personDisplayPhoto: {
      borderRadius: "50%",
      height: "44px",
      width: "44px",
    },
    personDisplayWrapper: {
      display: "flex",
      marginTop: "15px",
    },
  });

export const useStyles = makeStyles(styles);
