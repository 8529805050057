import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  container: {
    height: 0,
    paddingBottom: "56.25%" /* 16:9 */,
    position: "relative",
  },
  iframe: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
  root: {
    width: "100%",
  },
});

function YouTube({ videoId, classes }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {!isLoaded && (
          <Skeleton
            className={classes.iframe}
            variant="rect"
            height="100%"
            width="100%"
          />
        )}
        <iframe
          title="Video"
          className={classes.iframe}
          onLoad={onLoad}
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(YouTube);
