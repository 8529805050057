import { defineMessages } from "react-intl";

export default defineMessages({
  confirmationHeader: {
    defaultMessage: "Password changed successfully.",
    id: "app.containers.ForgotPasswordConfirmationPage.confirmationHeader",
  },
  header: {
    defaultMessage: "Change Password",
    id: "app.containers.ForgotPasswordConfirmationPage.header",
  },
  loginBtn: {
    defaultMessage: "Login",
    id: "app.containers.ForgotPasswordConfirmationPage.loginBtn",
  },
});
