import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { useMutation } from "@tanstack/react-query";
import Errors from "components/Error";
import FormattedMessage from "components/FormattedMessage";
import ResendCodePage from "containers/ResendCodePage";
import { authService } from "services/user";

import messages from "./messages";

const styles = (theme) => ({
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "25px",
    textAlign: "center",
    width: "100%",
  },
  authMessages: {
    textAlign: "center",
  },
  button: {
    margin: "10px",
    textTransform: "None",
    width: "100%",
  },
  emailSentImage: {
    display: "block",
    marginBottom: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
  },
  loginLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  signupForm: {
    border: "1px solid #eee",
    margin: "0px auto",
    marginTop: "100px",
    maxWidth: "500px",
    padding: "30px",
  },
  successMessage: {
    margin: "30px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "15px",
    width: "100%",
  },
});

/* eslint-disable react/prefer-stateless-function */
const SignupVerificationPage = ({ classes }) => {
  const [errors, setErrors] = useState([]);
  const [hasSuccessfullyVerified, setHasSuccessfullyVerified] = useState(false);
  const params = new URLSearchParams(window.location.search);

  const { mutate: verifyCode } = useMutation(
    async ({ code, username }) =>
      await authService.verifyCode({ code, username }),
    {
      onError: (error) => {
        setHasSuccessfullyVerified(false);
        setErrors([error.code]);
      },
      onSuccess: () => {
        setHasSuccessfullyVerified(true);
      },
    }
  );

  useEffect(() => {
    const verifyAccount = async () => {
      verifyCode({
        code: params.get("code"),
        username: params.get("email"),
      });
    };

    void verifyAccount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resendCodeExecuted = () => {
    setErrors([]);
  };

  return (
    <Paper elevation={0} className={classes.signupForm}>
      {errors.length > 0 && (
        <div>
          <div className={classes.authMessages}>
            <Errors errors={errors} />
          </div>
          <div className={classes.actionsContainer}>
            <ResendCodePage
              email={params.get("email")}
              onMount={resendCodeExecuted}
            />
          </div>
        </div>
      )}
      {hasSuccessfullyVerified && (
        <div>
          <Typography
            variant="body1"
            align="center"
            className={classes.successMessage}
          >
            <FormattedMessage {...messages.verificationSuccessful} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            href="/login"
          >
            <FormattedMessage {...messages.login} />
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default withStyles(styles)(SignupVerificationPage);
