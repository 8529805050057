import withStyles from "@material-ui/core/styles/withStyles";
import { useQuery } from "@tanstack/react-query";
import { Storage } from "aws-amplify";

const styles = (theme) => ({
  profilePhoto: {
    borderRadius: "50%",
  },
});

const ProfilePhoto = ({ classes, s3Key, size }) => {
  const { data: url } = useQuery(
    ["user", "profile", s3Key],
    () => Storage.vault.get(s3Key),
    {
      enabled: !!s3Key,
    }
  );

  const defaultUrl =
    "https://assets.mymusicschool.com/app/profile-picture-default.svg";

  return (
    <img
      src={url || defaultUrl}
      alt="User"
      className={classes.profilePhoto}
      width={`${size}px`}
      height={`${size}px`}
    />
  );
};
export default withStyles(styles)(ProfilePhoto);
