import React, { FC, useState } from "react";
import { TextFieldProps } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = () => ({});

type PasswordInputProps = TextFieldProps & { classes: any };

const PasswordInput: FC<PasswordInputProps> = ({ classes, ...rest }) => {
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  return (
    <TextField
      id="password"
      type={passwordIsMasked ? "password" : "text"}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {passwordIsMasked ? (
              <VisibilityOff
                className={classes.root}
                onClick={togglePasswordMask}
              />
            ) : (
              <Visibility
                className={classes.root}
                onClick={togglePasswordMask}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default withStyles(styles)(PasswordInput);
