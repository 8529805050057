import { FC } from "react";
import { useIntl } from "react-intl";
import { Container, Grid, InputAdornment, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FormattedMessage from "components/FormattedMessage";
import { useStyles } from "components/ProfileForm/styles";
import { ReactComponent as Checkmark } from "components/svg/checkmark.svg";
import { useFormik } from "formik";
import { authService, User } from "services/user";
import * as Yup from "yup";

import messages from "./messages";

interface ProfileFormProps {
  user: User;
}

const ProfileForm: FC<ProfileFormProps> = ({ user }) => {
  const classes = useStyles();
  const intl = useIntl();
  const queryClient = useQueryClient();

  const {
    mutate: updateProfile,
    isError,
    isSuccess,
    isLoading,
  } = useMutation(
    async (event: any) => {
      const { sendEmail, language, phoneNumber, firstName, lastName } = event;

      await authService.saveUserAttributes(user.cognitoUser, {
        firstName,
        language,
        lastName,
        phoneNumber,
        sendEmail,
        sendPushMsgs: undefined,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", "current"]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: user.email || "",
      firstName: user.givenName || "",
      language: user.language || navigator.language.toLowerCase().split("-")[0],
      lastName: user.familyName || "",
      phoneNumber: user.phoneNumber || "",
      sendEmail: user.sendEmail || false,
    },
    onSubmit: (values) => {
      updateProfile({
        ...values,
        phoneNumber: values.phoneNumber.replaceAll(/[ -]/g, ""),
        sendEmail: Number(values.sendEmail),
      });
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().matches(
        /\+[0-9 -]+/,
        messages.phoneNumberFormat.id
      ),
    }),
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Container maxWidth="md">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <FontAwesomeIcon icon={faUser} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="firstName"
              name="firstName"
              variant="outlined"
              label={<FormattedMessage {...messages.firstName} />}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName &&
                Boolean(formik.errors.firstName) && (
                  <FormattedMessage id={formik.errors.firstName} />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <FontAwesomeIcon icon={faUser} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="lastName"
              name="lastName"
              variant="outlined"
              label={<FormattedMessage {...messages.lastName} />}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={
                formik.touched.lastName &&
                Boolean(formik.errors.lastName) && (
                  <FormattedMessage id={formik.errors.lastName} />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <FontAwesomeIcon icon={faEnvelope} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              type="email"
              label={<FormattedMessage {...messages.email} />}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled
              error={formik.touched.email && Boolean(formik.errors.email)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {user.emailVerified && (
                      <Checkmark width={16} height={16} fill="#46C79F" />
                    )}
                  </InputAdornment>
                ),
              }}
              helperText={<FormattedMessage {...messages.emailImmutable} />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <FontAwesomeIcon icon={faPhone} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              variant="outlined"
              label={<FormattedMessage {...messages.phone} />}
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={intl.formatMessage(
                messages.phoneNumberFormatExample
              )}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber &&
                Boolean(formik.errors.phoneNumber) && (
                  <FormattedMessage id={formik.errors.phoneNumber} />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <FontAwesomeIcon icon={faLanguage} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="language"
              name="language"
              variant="outlined"
              value={formik.values.language}
              label={<FormattedMessage {...messages.language} />}
              select
              onChange={formik.handleChange}
            >
              <MenuItem value="de">Deutsch</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item className={classes.inputIcon}>
            <Switch
              id="sendEmail"
              name="sendEmail"
              checked={formik.values.sendEmail}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item style={{ flexGrow: 1, padding: 15 }}>
            <FormattedMessage {...messages.sendEmail} />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
        >
          <Grid item>
            <div>
              {isError && "Fehler beim Speichern"}
              {isSuccess && "Erfolgreich gespeichert"}
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && (
                <CircularProgress
                  color="primary"
                  size={12}
                  style={{ marginRight: 4 }}
                />
              )}
              <FormattedMessage {...messages.saveBtn} />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default ProfileForm;
