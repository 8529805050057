import { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Hub } from "aws-amplify";
import DefaultLayout from "components/DefaultLayout";
import PrivateLayout from "components/PrivateLayout";
import LanguageProvider from "containers/LanguageProvider";
import NotFoundPage from "containers/NotFoundPage";
import { translationMessages } from "i18n";
import { ImpersonateContextProvider } from "services/contexts/impersonate";
import { theme } from "theme/theme";

import "moment-timezone";

library.add(
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faEnvelope,
  faLanguage,
  faPhone
);

const queryClient = new QueryClient();

const privateRoutes = [
  "/home",
  "/materials",
  "/courses",
  "/profile",
  "/appointments",
  "/liveLesson",
];

const App = () => {
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          queryClient.invalidateQueries({ queryKey: ["user", "current"] });
          break;
        case "signOut":
          queryClient.setQueryData(["user", "current"], null);
          queryClient.invalidateQueries({ queryKey: ["user", "current"] });
          break;
        case "cognitoHostedUI":
          queryClient.invalidateQueries({ queryKey: ["user", "current"] });
          break;
        case "customOAuthState":
          this.setState({ customState: data });
          break;
        default:
          break;
      }
    });

    return () => {
      Hub.remove("auth");
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ImpersonateContextProvider>
          <LanguageProvider messages={translationMessages}>
            <>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <Route path="/login" component={DefaultLayout} />
                <Route path="/socialAuthConfirm" component={DefaultLayout} />
                <Route path="/signup" component={DefaultLayout} />
                <Route path="/verifySignup" component={DefaultLayout} />
                <Route path="/logout" component={DefaultLayout} />
                <Route path="/forgotPassword" component={DefaultLayout} />
                <Route path="/resetPassword" component={DefaultLayout} />
                <Route path={privateRoutes} component={PrivateLayout} />
                <Route component={NotFoundPage} />
              </Switch>
              <ReactQueryDevtools initialIsOpen={false} />
            </>
          </LanguageProvider>
        </ImpersonateContextProvider>
      </QueryClientProvider>
    </MuiThemeProvider>
  );
};

export default withRouter(App);
