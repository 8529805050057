import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import YouTube from "./Youtube";

const styles = (theme) => ({
  description: {
    fontSize: "1.2rem",
    marginBottom: theme.spacing(3),
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  root: {
    margin: theme.spacing(2),
    // maxWidth: "800px",
    padding: theme.spacing(2),
  },
});

function Lesson({ lesson, classes }) {
  const name =
    lesson.lesson_title ||
    (lesson.lesson_content.data ? lesson.lesson_content.data.video_title : "");
  /* eslint-disable no-nested-ternary */
  const description = lesson.lesson_description?.length
    ? lesson.lesson_description?.[0]?.text
    : lesson.lesson_content.data
    ? lesson.lesson_content.data.video_description?.[0]?.text
    : "";
  const videoId = lesson.lesson_content.data
    ? lesson.lesson_content.data.youtube_id
    : "";

  return (
    <Paper className={classes.root} elevation={1} square>
      <Typography className={classes.heading} variant="h5">
        {name}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
      <YouTube videoId={videoId} />
    </Paper>
  );
}

export default withStyles(styles)(Lesson);
