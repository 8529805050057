import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { createStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import PasswordInput from "components/PasswordInput";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "./messages";

const styles = (theme: Theme) =>
  createStyles({
    actionsContainer: {
      margin: "0px 8px 0px 8px",
      paddingTop: "25px",
      textAlign: "center",
      width: "100%",
    },
    button: {
      margin: "0px",
      width: "100%",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    tandc: {
      marginLeft: "-6px",
      marginTop: "20px",
      opacity: "0.75",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
    },
  });

const PasswordResetForm: FC<any> = ({ resetPassword, classes }) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },
    onSubmit: (values) => {
      resetPassword(values);
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, messages.passwordTooShort.id)
        .max(25, messages.passwordTooLong.id)
        .matches(
          /(?=^.{6,25}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{:'?/.,])(?!.*\s).*$/,
          messages.passwordHint.id
        )
        .required(messages.passwordRequired.id),
      password2: Yup.string()
        .required(messages.passwordRequired.id)
        .oneOf([Yup.ref("password"), null], messages.password2Hint.id),
    }),
  });

  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <PasswordInput
        label={<FormattedMessage {...messages.password} />}
        name="password"
        id="password"
        variant="outlined"
        required
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={
          formik.touched.password &&
          formik.errors.password && (
            <FormattedMessage id={formik.errors.password} />
          )
        }
      />

      <PasswordInput
        label={<FormattedMessage {...messages.password2} />}
        name="password2"
        id="password2"
        variant="outlined"
        required
        value={formik.values.password2}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password2 && Boolean(formik.errors.password2)}
        helperText={
          formik.touched.password2 &&
          formik.errors.password2 && (
            <FormattedMessage id={formik.errors.password2} />
          )
        }
      />
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={!(formik.dirty && formik.isValid)}
        >
          <FormattedMessage {...messages.resetPwdBtn} />
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(PasswordResetForm);
